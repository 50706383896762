import { combineReducers } from 'redux'
import MailTemplateTypes from 'store/action_types/mailTemplate'
import { createHandleRequest } from './helpers'

const getMailTemplates = createHandleRequest(
  MailTemplateTypes.GET_MAIL_TEMPLATES_REQUEST,
  MailTemplateTypes.GET_MAIL_TEMPLATES_SUCCESS,
  MailTemplateTypes.GET_MAIL_TEMPLATES_FAILED
)

const getMailTemplateDetail = createHandleRequest(
  MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_REQUEST,
  MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_SUCCESS,
  MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_FAILED
)

const addMailTemplate = createHandleRequest(
  MailTemplateTypes.ADD_MAIL_TEMPLATE_REQUEST,
  MailTemplateTypes.ADD_MAIL_TEMPLATE_SUCCESS,
  MailTemplateTypes.ADD_MAIL_TEMPLATE_FAILED
)

const updateMailTemplate = createHandleRequest(
  MailTemplateTypes.UPDATE_MAIL_TEMPLATE_REQUEST,
  MailTemplateTypes.UPDATE_MAIL_TEMPLATE_SUCCESS,
  MailTemplateTypes.UPDATE_MAIL_TEMPLATE_FAILED
)

const updateMailTemplateTranslation = createHandleRequest(
  MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_REQUEST,
  MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_SUCCESS,
  MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_FAILED
)

export default combineReducers({
  getMailTemplates,
  getMailTemplateDetail,
  updateMailTemplate,
  updateMailTemplateTranslation,
  addMailTemplate
})
