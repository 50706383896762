import { DEFAULT_FAQ_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import faqTypes from 'store/action_types/faq'

import UserTypes from 'store/action_types/user'

const initFAQ = {
  list: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_FAQ_FILTER,
  filterLanguage: '',
  components: {
    list: [],
    pagination: DEFAULT_PAGINATION
  }
}

const faq = (state = initFAQ, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case faqTypes.GET_FAQS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case faqTypes.GET_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }
    case faqTypes.UPDATE_FILTER_FAQ_SUCCESS:
      return { ...state, filter: data }
    case faqTypes.UPDATE_FAQ_LANGUAGE_FILTER_SUCCESS:
      return { ...state, filterLanguage: data }
    case UserTypes.LOGOUT_SUCCESS:
      return initFAQ
    default:
      return state
  }
}

export default faq
