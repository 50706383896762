import {
  DEFAULT_CONDITIONAL_FEATURE_FILTER,
  DEFAULT_PAGINATION
} from 'constants/common'
import ConditionalFeatureType from 'store/action_types/conditionalFeature'

const initConditionalFeature = {
  list: [],
  pagination: DEFAULT_PAGINATION,
  filer: DEFAULT_CONDITIONAL_FEATURE_FILTER
}

const conditionalFeature = (state = initConditionalFeature, action) => {
  switch (action.type) {
    case ConditionalFeatureType.GET_CONDITIONAL_FEATURE_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    default:
      return state
  }
}

export default conditionalFeature
