import { getUniqueItem } from 'helpers/Utils'
import ActivityTypes from 'store/action_types/activity'
import UserTypes from 'store/action_types/user'

const initElectronicActivity = {
  list: [],
  current: {},
  activeList: null,
  default: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  }
}

const electronicActivity = (state = initElectronicActivity, action) => {
  switch (action.type) {
    case ActivityTypes.GET_ELECTRONIC_KEY_ACTIVITIES_SUCCESS:
      if (action.payload.meta.currentPage === 1) {
        return {
          ...state,
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }
      return {
        ...state,
        list: getUniqueItem([...action.payload?.items, ...state.list]),
        pagination: action.payload?.meta
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initElectronicActivity
    default:
      return state
  }
}

export default electronicActivity
