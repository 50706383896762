import { combineReducers } from 'redux'
import LogMailTypes from 'store/action_types/logMail'
import { createHandleRequest } from './helpers'

const getLogMails = createHandleRequest(
  LogMailTypes.GET_LOG_MAILS_REQUEST,
  LogMailTypes.GET_LOG_MAILS_SUCCESS,
  LogMailTypes.GET_LOG_MAILS_FAILED
)

export default combineReducers({
  getLogMails
})
