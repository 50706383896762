import keyMirror from 'keymirror'

export default keyMirror({
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  FETCH_PROFILE_SUCCESS: null,
  FETCH_PROFILE_FAILURE: null,

  GET_USERS_REQUEST: null,
  GET_USERS_SUCCESS: null,
  GET_USERS_FAILED: null,

  GET_USER_DETAIL_REQUEST: null,
  GET_USER_DETAIL_SUCCESS: null,
  GET_USER_DETAIL_FAILED: null,

  DELETE_USER_REQUEST: null,
  DELETE_USER_SUCCESS: null,
  DELETE_USER_FAILED: null,

  ADD_USER_REQUEST: null,
  ADD_USER_SUCCESS: null,
  ADD_USER_FAILED: null,

  UPDATE_USER_REQUEST: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_FAILED: null,

  APPLY_USER_REQUEST: null,
  APPLY_USER_SUCCESS: null,
  APPLY_USER_FAILED: null,

  // DELETE_MULTIPLE_USER_REQUEST: null,
  // DELETE_MULTIPLE_USER_SUCCESS: null,
  // DELETE_MULTIPLE_USER_FAILED: null,

  UPDATE_DEFAULT_LANGUAGE: null,

  UPDATE_STATUS_USER_REQUEST: null,
  UPDATE_STATUS_USER_SUCCESS: null,
  UPDATE_STATUS_USER_FAILED: null,

  UPDATE_PASSWORD_USER_REQUEST: null,
  UPDATE_PASSWORD_USER_SUCCESS: null,
  UPDATE_PASSWORD_USER_FAILED: null,

  UPDATE_FILTER_USER_SUCCESS: null,
  CLEAR_FILTER_USER_SUCCESS: null
})
