import { combineReducers } from 'redux'
import RolesConfigurationTypes from 'store/action_types/rolesConfiguration'
import { createHandleRequest } from './helpers'

const getRolesConfiguration = createHandleRequest(
  RolesConfigurationTypes.GET_ROLES_CONFIGURATION_REQUEST,
  RolesConfigurationTypes.GET_ROLES_CONFIGURATION_SUCCESS,
  RolesConfigurationTypes.GET_ROLES_CONFIGURATION_FAILED
)

export default combineReducers({
  getRolesConfiguration
})
