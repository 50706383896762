import BaseApi from './base'

class Vehicle extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.vehicleURL}/backoffice`
  }

  getVehicles = (query) => this.client.get(`vehicles?${query}`)

  getVehicleDetail = (id) => this.client.get(`vehicles/${id}`)

  addVehicle = (data) => this.client.post(`vehicles`, data)

  updateVehicle = (id, data) => this.client.patch(`vehicles/${id}`, data)

  deleteVehicle = (id) => this.client.delete(`vehicles/${id}`)

  addUserVehicle = (data) => this.client.post(`vehicles/users`, data)

  updateUserVehicle = (id, data) =>
    this.client.patch(`vehicles/users/${id}`, data)

  deleteUserVehicle = (id) => this.client.delete(`vehicles/users/${id}`)

  getVehicleElectronicKeys = (id, query = '') =>
    this.client.get(`vehicle-electronic-key/${id}?${query}`)

  addVehicleElectronicKey = (data) =>
    this.client.post(`vehicle-electronic-key`, data)

  updateVehicleElectronicKey = (id, data) =>
    this.client.patch(`vehicle-electronic-key/${id}`, data)

  deleteVehicleElectronicKey = (id) =>
    this.client.delete(`vehicle-electronic-key/${id}`)
}

export default new Vehicle()
