import keyMirror from 'keymirror'

export default keyMirror({
  GET_COUNTRIES_REQUEST: null,
  GET_COUNTRIES_SUCCESS: null,
  GET_COUNTRIES_FAILED: null,

  ADD_COUNTRY_REQUEST: null,
  ADD_COUNTRY_SUCCESS: null,
  ADD_COUNTRY_FAILED: null,

  GET_COUNTRY_DETAIL_REQUEST: null,
  GET_COUNTRY_DETAIL_SUCCESS: null,
  GET_COUNTRY_DETAIL_FAILED: null,

  UPDATE_COUNTRY_REQUEST: null,
  UPDATE_COUNTRY_SUCCESS: null,
  UPDATE_COUNTRY_FAILED: null,

  DELETE_COUNTRY_REQUEST: null,
  DELETE_COUNTRY_SUCCESS: null,
  DELETE_COUNTRY_FAILED: null,

  ADD_COUNTRY_TRANSLATION_REQUEST: null,
  ADD_COUNTRY_TRANSLATION_SUCCESS: null,
  ADD_COUNTRY_TRANSLATION_FAILED: null,

  UPDATE_COUNTRY_TRANSLATION_REQUEST: null,
  UPDATE_COUNTRY_TRANSLATION_SUCCESS: null,
  UPDATE_COUNTRY_TRANSLATION_FAILED: null,

  DELETE_COUNTRY_TRANSLATION_REQUEST: null,
  DELETE_COUNTRY_TRANSLATION_SUCCESS: null,
  DELETE_COUNTRY_TRANSLATION_FAILED: null,

  GET_ACTIVE_COUNTRIES_REQUEST: null,
  GET_ACTIVE_COUNTRIES_SUCCESS: null,
  GET_ACTIVE_COUNTRIES_FAILED: null,

  UPDATE_FILTER_COUNTRY_SUCCESS: null,
  CLEAR_FILTER_COUNTRY_SUCCESS: null
})
