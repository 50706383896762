import { combineReducers } from 'redux'
import CountryTypes from 'store/action_types/country'
import { createHandleRequest } from './helpers'

const getCountries = createHandleRequest(
  CountryTypes.GET_COUNTRIES_REQUEST,
  CountryTypes.GET_COUNTRIES_SUCCESS,
  CountryTypes.GET_COUNTRIES_FAILED
)

export default combineReducers({
  getCountries
})
