import { combineReducers } from 'redux'
import TextTypes from 'store/action_types/text'
import { createHandleRequest } from './helpers'

const getTexts = createHandleRequest(
  TextTypes.GET_TEXTS_REQUEST,
  TextTypes.GET_TEXTS_SUCCESS,
  TextTypes.GET_TEXTS_FAILED
)

export default combineReducers({
  getTexts
})
