import ElectronicKeyModels from '../action_types/electronicKeyModel'

import { electronicKeyModel as electronicKeyModelApi } from '../api'
import { bindRequestLogic } from './utils'

const getElectronicKeyModels = bindRequestLogic({
  clientApi: electronicKeyModelApi,
  clientFunc: electronicKeyModelApi.getElectronicKeyModels,
  type: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODELS_REQUEST,
  successType: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODELS_SUCCESS,
  failType: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODELS_FAILED,
  isQueryParam: true,
  modelName: 'electronicKeyModel'
})

const addElectronicKeyModel = bindRequestLogic({
  clientApi: electronicKeyModelApi,
  clientFunc: electronicKeyModelApi.addElectronicKeyModel,
  type: ElectronicKeyModels.ADD_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModels.ADD_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModels.ADD_ELECTRONIC_KEY_MODEL_FAILED
})

const getElectronicKeyModelDetail = bindRequestLogic({
  clientApi: electronicKeyModelApi,
  clientFunc: electronicKeyModelApi.getElectronicKeyModelDetail,
  type: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODEL_DETAIL_REQUEST,
  successType: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODEL_DETAIL_SUCCESS,
  failType: ElectronicKeyModels.GET_ELECTRONIC_KEY_MODEL_DETAIL_FAILED,
  modelName: 'electronicKeyModel'
})

const deleteElectronicKeyModel = bindRequestLogic({
  clientApi: electronicKeyModelApi,
  clientFunc: electronicKeyModelApi.deleteElectronicKeyModel,
  type: ElectronicKeyModels.DELETE_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModels.DELETE_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModels.DELETE_ELECTRONIC_KEY_MODEL_FAILED
})

const updateElectronicKeyModel = bindRequestLogic({
  clientApi: electronicKeyModelApi,
  clientFunc: electronicKeyModelApi.updateElectronicKeyModel,
  type: ElectronicKeyModels.UPDATE_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModels.UPDATE_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModels.UPDATE_ELECTRONIC_KEY_MODEL_FAILED
})

export default [
  getElectronicKeyModels,
  addElectronicKeyModel,
  getElectronicKeyModelDetail,
  deleteElectronicKeyModel,
  updateElectronicKeyModel
]
