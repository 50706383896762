import React from 'react'
// eslint-disable-next-line import/no-cycle
import SystemMessages from 'helpers/SystemMessages'
// eslint-disable-next-line import/no-cycle
import { getLastMonth, toDateString } from 'helpers/Utils'
import IntlMessages from 'helpers/IntlMessages'

export const REQUEST_TIMEOUT = 30000

export const DEFAULT_LANGUAGE_ISO_CODE = 'eng'

export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,}/

export const DATE_TIME_FORMAT = {
  short: 'dateFormatShort',
  medium: 'dateFormatMedium',
  long: 'dateFormatLong'
}

export const DATE_PICKER_FORMAT = {
  short: 'MM/dd/yyyy'
}

export const STORAGE_KEYS = Object.freeze({
  accessToken: '@accessToken',
  vehicleToken: '@vehicleToken',
  refreshToken: '@refreshToken'
})
export const LEFT_TO_RIGHT = 'left to right'
export const RIGHT_TO_LEFT = 'right to left'
export const DEFAULT_LANGUAGE = 'English'
export const SORT_DESC = 'desc'
export const SORT_ASC = 'asc'
export const NAME_SORT = 'name'
export const CODE_SORT = 'code'

export const LOGIN_ERROR_MESSAGE = {
  err_user_not_found: <SystemMessages id='login.err_user_not_found' />,
  err_email_or_password_incorrect: (
    <SystemMessages id='login.err_email_or_password_incorrect' />
  ),
  err_invalid_account: <SystemMessages id='login.err_invalid_account' />
}

export const MESSAGE_VALIDATION_PASSWORD = {
  schema: <SystemMessages id='password.incorrect' />,
  match: <SystemMessages id='password.not_match' />,
  newPassRequired: <SystemMessages id='password.new_pass_required' />,
  confirmPassRequired: <SystemMessages id='password.confirm_pass_required' />
}

export const SAVE_SUCCESS_MESSAGE = <SystemMessages id='save_success_message' />

export const SAVE_FAILED_MESSAGE = <SystemMessages id='save_failed_message' />

export const CREATE_SUCCESS_MESSAGE = (
  <SystemMessages id='create_success_message' />
)

export const CREATE_FAILED_MESSAGE = (
  <SystemMessages id='create_failed_message' />
)

export const CREATE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='create_translation_success_message' />
)

export const CREATE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='create_translation_failed_message' />
)

export const UPDATE_SUCCESS_MESSAGE = (
  <SystemMessages id='update_success_message' />
)

export const UPDATE_FAILED_MESSAGE = (
  <SystemMessages id='update_failed_message' />
)

export const UPDATE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='update_translation_success_message' />
)

export const UPDATE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='update_translation_failed_message' />
)

export const DELETE_SUCCESS_MESSAGE = (
  <SystemMessages id='delete_success_message' />
)

export const DELETE_FAILED_MESSAGE = (
  <SystemMessages id='delete_failed_message' />
)

export const DELETE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='delete_translation_success_message' />
)
export const DELETE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='delete_translation_failed_message' />
)

export const UPDATE_LABELS_SUCCESS_MESSAGE = (
  <SystemMessages id='update_labels_success_message' />
)

export const UPDATE_LABELS_FAILED_MESSAGE = (
  <SystemMessages id='update_labels_failed_message' />
)

export const UPDATE_MESSAGES_SUCCESS_MESSAGE = (
  <SystemMessages id='update_messages_success_message' />
)

export const UPDATE_MESSAGES_FAILED_MESSAGE = (
  <SystemMessages id='update_messages_failed_message' />
)

export const UPDATE_TEXTS_SUCCESS_MESSAGE = (
  <SystemMessages id='update_texts_success_message' />
)

export const UPDATE_TEXTS_FAILED_MESSAGE = (
  <SystemMessages id='update_texts_failed_message' />
)

export const IMPORT_SUCCESS_MESSAGE = (
  <SystemMessages id='import_success_message' />
)

export const IMPORT_FAILED_MESSAGE = (
  <SystemMessages id='import_failed_message' />
)

export const PREFIX_BASE64 = ';base64'
export const PREFIX_IMAGE_BASE64 = 'data:image/png;base64'
export const LANGUAGE_LIST = [
  {
    label: 'English',
    value: 'eng',
    nativeName: 'English',
    key: 0,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABLAQMAAABnbRrWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABlBMVEX////OESShKa/1AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+YEEw0yASlK+zoAAAAhSURBVDjLY2AAA8b/fxhQwKjAiBD4jwp+jDCB0eQwggUACC5lxyHmcj0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQtMTlUMTM6NTA6MDErMDA6MDBmnE1DAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTE5VDEzOjUwOjAxKzAwOjAwF8H1/wAAAABJRU5ErkJggg=='
  },
  {
    label: 'French',
    value: 'fra',
    nativeName: 'French',
    key: 1,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABTBAMAAABACBVQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAD1BMVEUAJlRUbYz////eYG7OESZmtw3fAAAAAWJLR0QCZgt8ZAAAAAd0SU1FB+YBFQEdGcyrcskAAAAzSURBVFjD7cshAQAgEASwowpfBEH/TBQ4i9v8kmLtYm5x4vu+7/u+7/u+7/u+7/u+/+k/XcG18y207eUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDEtMjFUMDE6Mjk6MjUrMDA6MDDmiE0oAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTAxLTIxVDAxOjI5OjI1KzAwOjAwl9X1lAAAAABJRU5ErkJggg=='
  },
  {
    label: 'German',
    value: 'deu',
    nativeName: 'German',
    key: 2,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABLAgMAAAAgzWAGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAADdAAD/zgD///9i/kqcAAAAAWJLR0QDEQxM8gAAAAd0SU1FB+EICgkYHjnEV5cAAAApSURBVEjHY2AYBaNgFJAOQvEDh1EFowpGFWBRsAo/aBhVMKpgVAGmAgBrTxbnZ9bOpwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNy0wOC0xMFQwOToyNDozMCswMDowMMk6o/kAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTctMDgtMTBUMDk6MjQ6MzArMDA6MDC4ZxtFAAAAAElFTkSuQmCC'
  },
  {
    label: 'Japan',
    value: 'jpa',
    nativeName: 'Japan',
    key: 3,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABTCAMAAACF+PhRAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABOFBMVEX////78fPxy9TrssDop7fkmav55+vkmqzTWXbDHES8AC3UWnflm6356Oz+/f3qrrzPSWm9Ay+9BDDPSmrqr73//v7HKlDHK1Doqbj01t3NQGLNQmP12N/mobK/CjW+CDTikKT++vvVX3vWYn7++/z89ffQS2vORGX9+frUW3jVXnrikaTjlKfyzte+BzPz0NjLOFvKNVnkmKrEH0fEHUXlnK3mnq/JM1fKN1r01dz0197egpjfhZu+CTT22+Hrsb/rs8Dhi5/hjaHeg5nNQWPORWbfhpvKNFjlna7mn7DEHkbLOVzz0dnjk6bkl6nVYX3+/P3RUG/QTGzQTWz99vjWZH/XZ4K/CzbOQ2T12uHpq7rILFHILlPprbu9BTH56e3UXHnUXXr56u788/XyzNXrtMHoqLfyzdZu4LVCAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+YEEw00EWKnTNgAAAGiSURBVGje7ZmHTgJBEIaPJjIiIogFUSwURQUUxS4qFsSOvRcE3/8NjDGSaIDbSWbuEjLfC3zZ3OzMzn+aJgiCIAiCUMNitdkddpvVYri5zdnugl9cHe5O49SeLm83/MXn7/EY4g7Ye6Eeff0GyAeC0IjBELd8aBgaEx5hdY+OQXPGI3zyqB/0iEXZTu4FfeITTMU+CSokeOxToMY0h3wmqWhPphgaXBpUmaVve3Ogjpu83jMI+zx13S8AhiyxfRFlX6KVLwOOFVL7KtK+RmoPIu1x0ooPI+3rlLMuBFg2CO05tH3TpEb3g5PQvoW2bxPa82h7vmXOjv/u7papeXPvu7m9TttB2ndJpwz2yu3R7o6mzndtHyVPE7+sCij7gZlv2iL5LudE2Av0u8yhmbuMllLd48JHHGvksaL9hGeFTijJT5myi7NzBXmMLbmJlnTlF5d8sVHkSkd+zZhZfed1N83mqoM7LgzdNh6rdwZkpfcPdd2PT8Zk1IFcyfdP/VyyBYwLyV8Kr8WaOvOWfTf8D0H5o1J1VCufZU0QBEEQBKHGF/28ahI3KDeEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA0LTE5VDEzOjUyOjE3KzAwOjAwzROo2gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNC0xOVQxMzo1MjoxNyswMDowMLxOEGYAAAAASUVORK5CYII='
  }
]

export const UNAUTHORIZED = 401

export const USER_STATUS = Object.freeze({
  block: 'block',
  active: 'active'
})

export const USER_STATUS_LIST = [
  { label: 'All', value: '' },
  { label: 'Active', value: USER_STATUS.active },
  { label: 'Block', value: USER_STATUS.block }
]

export const DEFAULT_USER_STATUS_LIST = { label: 'All', value: '' }
export const BLOCK_SELECTED_USER = 'Block selected user(s)'
export const DELETE_SELECTED_USER = 'Delete selected user(s)'
export const UNBLOCK_SELECTED_USER = 'Unblock selected user(s)'

export const USER_ACTIONS = [BLOCK_SELECTED_USER, UNBLOCK_SELECTED_USER]

export const SEARCH_IN = [
  {
    value: 'translated',
    label: 'Only translated strings'
  },
  {
    value: 'not_translated',
    label: 'Only untranslated strings'
  },
  {
    value: 'both',
    label: 'Both translated and untranslated strings'
  }
]

export const DEFAULT_GENDER = { label: 'Male', value: 1 }

export const GENDER_LIST = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 }
]

export const IGNORE_LIST = [':id', 'edit']

export const DEFAULT_GROUP = {
  id: 0,
  label: 'All',
  value: 0
}

export const BACK_OFFICE = 'back_office'
export const FLEET_MANAGER = 'fleet_manager_member'

export const SYSTEM_CONFIG_CODE = {
  boSiteName: 'BO_site_name',
  fmpSiteName: 'FMP_site_name',
  mailSender: 'mail_sender',
  mailingList: 'mailing_list',
  durationResendInvitationEmail: 'duration_resend_invitation_email',
  organizationMemberResendInvitationEmailPerDays:
    'organization_member_resend_invitation_email_per_days',
  recipientForContactUs: 'recipient_for_contact_us',
  recipientForRequestBrochure: 'recipient_for_request_brochure',
  maximumFiles: 'maximum_files',
  maximumFileSize: 'maximum_file_size',
  userMaxWrongLoginAttempts: 'user_max_wrong_login_attempts',
  userDurationWrongAttempts: 'user_duration_wrong_attempts',
  userMaxWrongAttemptsToDeactivatedAccount:
    'user_max_wrong_attempts_to_deactivated_account',
  durationOfActivationCode: 'duration_of_activation_code',
  mailingLanguage: 'mailing_language',
  interfaceLanguageForCfm: 'interface_language_for_cfm',
  interfaceLanguageForShowcaseWebsite:
    'interface_language_for_showcase_website',
  interfaceLanguageForMobileApp: 'interface_language_for_mobile_app',
  passwordSwitchPlatform: 'password_switch_platform',
  defaultAvatarUserBO: 'default_avatar_user_BO',
  defaultAvatarUserApp: 'default_avatar_user_app',
  defaultAvatarCompany: 'default_avatar_company',
  defaultAvatarVehicle: 'default_avatar_vehicle',
  defaultAvatarBike: 'default_avatar_bike',
  defaultAvatarElectronicKey: 'default_avatar_electronic_key',
  defaultImageCompanySelection: 'default_image_company_selection',
  defaultCountry: 'default_country',
  defaultTimezone: 'default_timezone',
  removeArchivedVisitorContacts: 'remove_archived_visitor_contacts'
}

export const SYSTEM_PARAMETERS_KEY = [
  'system_parameters.site_name_of_the_platforms',
  SYSTEM_CONFIG_CODE.boSiteName,
  SYSTEM_CONFIG_CODE.fmpSiteName,
  'system_parameters.mailing',
  SYSTEM_CONFIG_CODE.mailSender,
  SYSTEM_CONFIG_CODE.mailingList,
  SYSTEM_CONFIG_CODE.durationResendInvitationEmail,
  SYSTEM_CONFIG_CODE.organizationMemberResendInvitationEmailPerDays,
  SYSTEM_CONFIG_CODE.recipientForContactUs,
  SYSTEM_CONFIG_CODE.recipientForRequestBrochure,
  'system_parameters.upload',
  SYSTEM_CONFIG_CODE.maximumFiles,
  SYSTEM_CONFIG_CODE.maximumFileSize,
  'system_parameters.login',
  SYSTEM_CONFIG_CODE.userMaxWrongLoginAttempts,
  SYSTEM_CONFIG_CODE.userDurationWrongAttempts,
  SYSTEM_CONFIG_CODE.userMaxWrongAttemptsToDeactivatedAccount,
  SYSTEM_CONFIG_CODE.durationOfActivationCode,
  'system_parameters.visitor_contact',
  SYSTEM_CONFIG_CODE.removeArchivedVisitorContacts,
  'system_parameters.interface_language',
  SYSTEM_CONFIG_CODE.mailingLanguage,
  SYSTEM_CONFIG_CODE.interfaceLanguageForCfm,
  SYSTEM_CONFIG_CODE.interfaceLanguageForMobileApp,
  SYSTEM_CONFIG_CODE.interfaceLanguageForShowcaseWebsite,
  'system_parameters.password_for_switch_user',
  SYSTEM_CONFIG_CODE.passwordSwitchPlatform,
  'system_parameters.avatars',
  SYSTEM_CONFIG_CODE.defaultAvatarUserBO,
  SYSTEM_CONFIG_CODE.defaultAvatarUserApp,
  SYSTEM_CONFIG_CODE.defaultAvatarCompany,
  SYSTEM_CONFIG_CODE.defaultAvatarVehicle,
  SYSTEM_CONFIG_CODE.defaultAvatarBike,
  SYSTEM_CONFIG_CODE.defaultAvatarElectronicKey,
  SYSTEM_CONFIG_CODE.defaultImageCompanySelection
]

export const SYSTEM_CONFIG_INPUT_TYPE = {
  imageFile: 'image_file',
  number: 'number',
  integer: 'integer',
  string: 'string'
}

export const SEARCH_SCOPE = {
  value: 'both',
  label: 'Both translated and untranslated strings'
}

export const MAPPING_TEXT_FILTER = Object.freeze({
  originalText: <IntlMessages id='texts.string' />,
  keyword: <IntlMessages id='texts.keyword' />,
  selectedModule: <IntlMessages id='texts.module' />,
  selectedLanguage: <IntlMessages id='texts.language' />,
  selectedSearchScope: <IntlMessages id='texts.search_in' />
})

export const MAPPING_LABEL_FILTER = Object.freeze({
  name: <IntlMessages id='labels.string' />,
  keyword: <IntlMessages id='keyword' />,
  selectedModule: <IntlMessages id='module' />,
  selectedLanguage: <IntlMessages id='labels.language' />,
  selectedSearchScope: <IntlMessages id='labels.search_in' />
})

export const SPECIAL_LABEL_FILTER = ['selectedLanguage', 'selectedSearchScope']

export const DEFAULT_ORDER_LIST = [{ label: 'Ascending', value: 1 }]

export const ORDER_LIST = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Decrease', value: 'desc' }
]

export const GROUP_ROLE = {
  backOffice: 'back_office',
  fleetManagerMember: 'fleet_manager_member',
  fleetManagerPortal: 'fleet_manager_portal',
  mobileApp: 'cixi_mobile_app'
}

export const DEFINE_PERMISSIONS = {
  usersPortal: 'usersportal',
  userPortalStatus: 'userportalstatus',
  userPortalAdditionalData: 'userportaladditionaldata',
  userPortalSubscriptions: 'userportalsubscriptions',
  userPortalVehicles: 'userportalvehicles',
  usersPortalVehiclesSharingAS: 'userportalvehiclessharingas',
  userPortalElectronicKeysSharingAS: 'userportalelectronickeyssharingas',
  userPortalVehiclesSharingAB: 'userportalvehiclessharingab',
  userPortalElectronicKeysSharingAB: 'userportalelectronickeyssharingab',
  userPortalPersCalibration: 'userportalperscalibration',
  userAppPortalRoles: 'userappportalroles',
  userPortalActivity: 'userportalactivity',
  userAppSubscriptionsAllowedPilot: 'usersappsubscriptionsallowedpilot',
  companies: 'companies',
  visitorContacts: 'visitorcontacts',
  archivedVisitorContacts: 'archived_visitorcontacts',
  companyStatus: 'companystatus',
  childCompanies: 'childcompanies',
  companyAdditionalData: 'companyadditionaldata',
  companyMembers: 'companymembers',
  companyMembersBo: 'companymembers_bo',
  companyPilots: 'companypilots',
  companySubscriptions: 'companysubscriptions',
  companyVehicles: 'companyvehicles',
  companyVehiclesSharing: 'companyvehiclessharing',
  companyElectronicKeys: 'companyelectronickeys',
  companyElectronicKeysSharing: 'companyelectronickeyssharing',
  companyActivity: 'companyactivity',
  subscriptions: 'subscriptions',
  subscriptionStatus: 'subscriptionstatus',
  subscriptionAllowedPilots: 'subscriptionallowedpilots',
  subscriptionVehicle: 'subscriptionvehicle',
  subscriptionActivity: 'subscriptionactivity',
  vehicles: 'vehicles',
  vehicleStatus: 'vehiclestatus',
  vehiclePublicKeys: 'vehiclepublickeys',
  vehicleElectronicKeys: 'vehicleelectronickeys',
  vehicleUsers: 'vehicleusers',
  vehicleSharings: 'vehiclesharings',
  vehiclesSubscriptions: 'vehiclessubscriptions',
  vehicleActivity: 'vehicleactivity',
  electronicKeys: 'electronickeys',
  electronicKeyStatus: 'electronickeystatus',
  electronicKeyVehicles: 'electronickeyvehicles',
  electronicKeysSharing: 'electronickeyssharing',
  electronicKeyActivity: 'electronickeyactivity',
  electronicKeyModels: 'electronickeymodels',
  modelPERS: 'modelpers',
  vehicleModels: 'vehiclemodels',
  vehicleBrands: 'vehiclebrands',
  vehicleBrandMessage: 'vehiclebrandmessage',
  fromShowcaseWebsite: 'fromshowcasewebsite',
  logsContactUs: 'logscontactus',
  scwSections: 'scwsections',
  scwSectionComponent: 'scwsectioncomponent',
  scwSectionContent: 'scwsectioncontent',
  scwStories: 'scwstories',
  scwPrivacyPolicy: 'scwprivacypolicy',
  faq: 'faq',
  topic: 'topics',
  scwMessages: 'scwmessages',
  scwMailTemplates: 'scwmailtemplates',
  scwDocumentation: 'scwdocumentation',
  apaInterfaceLabels: 'apainterfacelabels',
  apaInterfaceTexts: 'apainterfacetexts',
  apaInterfaceFormattedTexts: 'apainterfaceformattedtexts',
  apaMessages: 'apamessages',
  apaMailTemplates: 'apamailtemplates',
  appVersioning: 'appversioning',
  messagesAfterConnection: 'messagesafterconnection',
  apaDocumentation: 'apadocumentation',
  fmpInterfaceLabels: 'fmpinterfacelabels',
  fmpInterfaceTexts: 'fmpinterfacetexts',
  fmpInterfaceFormattedTexts: 'fmpinterfaceformattedtexts',
  fmpMessages: 'fmpmessages',
  fmpMailTemplates: 'fmpmailtemplates',
  fmpDocumentation: 'fmpdocumentation',
  interfaceLabels: 'interfacelabels',
  interfaceTexts: 'interfacetexts',
  interfaceFormattedTexts: 'interfaceformattedtexts',
  messages: 'messages',
  languages: 'languages',
  countries: 'countries',
  timezones: 'timezones',
  currencies: 'currencies',
  tagContactTypes: 'tagcontacttypes',
  pilotTypes: 'pilottypes',
  cixiReferents: 'cixireferents',
  usersBO: 'usersbo',
  roles: 'roles',
  rolesConfiguration: 'rolesconfiguration',
  platformTech: 'platformtech',
  generalSettings: 'generalsettings',
  regionalSettings: 'regionalsettings',
  datetimeFormats: 'datetimeformats',
  conditionalFeatures: 'conditionalfeatures',
  mailTemplates: 'mailtemplates',
  documentation: 'documentation',
  adminDocumentation: 'admindocumentation',
  apisCIXIDocumentation: 'apiscixidocumentation',
  apisPartnersDocumentation: 'apispartnersdocumentation',
  getAuthVehicles: 'getauthvehicles',
  getWhatToDo: 'getwhattodo',
  getNewPinBLEVehicle: 'getnewpinblevehicle',
  getNewHardToken: 'getnewhardtoken',
  getVehicleUsersList: 'getvehicleuserslist',
  getNewAuthVehicles: 'getnewauthvehicles',
  logMails: 'logmails',
  logRoutines: 'logroutines',
  simulatorVehicleAPIs: 'simulatorvehicleapis'
}

export const OTHER_PLATFORM_PERMISSIONS = {
  // Pilot Platform
  switchTo_PilotApp: 'switchto_pilotapp',
  anotherUser_PilotApp: 'anotheruser_pilotapp',
  connectPilotPlatform: 'connectpilotplatform',

  // Fleet Manager Portal
  switchTo_CFM: 'switchto_cfm',
  anotherUser_CFM: 'anotheruser_cfm',
  connectCFMPlatform: 'connectcfmplatform',

  // Fleet Manager Member
  companyProfile: 'companyprofile',
  companyProfile_Information: 'companyprofile_information',
  companyProfile_Members: 'companyprofile_members',
  companyMembers: 'companymembers',
  companyProfile_ChildCompanies: 'companyprofile_childcompanies',
  companyProfile_GeneralSettings: 'companyprofile_generalsettings',
  company_GeneralSettings: 'company_generalsettings',
  fleetsAndSubscriptions: 'fleetsandsubscriptions',
  fleetsAndSubscriptions_Fleets: 'fleetsandsubscriptions_fleets',
  fleetsAndSubscriptions_Orders: 'fleetsandsubscriptions_orders',
  fleetManagement: 'fleetmanagement',
  fleetManagement_Teams: 'fleetmanagement_teams',
  fleetManagement_Vehicles: 'fleetmanagement_vehicles',
  sharingManagement: 'sharingmanagement',
  sharingManagement_CurrentVehiclesUse: 'sharingmanagement_currentvehiclesuse',
  sharingManagement_UpcomingVehiclesUse:
    'sharingmanagement_upcomingvehiclesuse',
  sharingManagement_PastVehiclesUsed: 'sharingmanagement_pastvehiclesused',
  statistics: 'statistics',
  statistics_Usage: 'statistics_usage',
  statistics_Financial: 'statistics_financial',
  statistics_Users: 'statistics_users',
  statistics_Vehicles: 'statistics_vehicles',
  help: 'help',
  help_FAQ: 'help_faq',
  help_Glossary: 'help_glossary',
  help_Documents: 'help_documents',
  allVehicles: 'allvehicles'
}

export const MAP_PERMISSION_TO_CATEGORY = {
  [DEFINE_PERMISSIONS.usersPortal]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalStatus]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalAdditionalData]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalSubscriptions]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalVehicles]: 'Customers',
  [DEFINE_PERMISSIONS.usersPortalVehiclesSharingAS]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalElectronicKeysSharingAS]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalVehiclesSharingAB]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalElectronicKeysSharingAB]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalPersCalibration]: 'Customers',
  [DEFINE_PERMISSIONS.userAppPortalRoles]: 'Customers',
  [DEFINE_PERMISSIONS.userPortalActivity]: 'Customers',
  [DEFINE_PERMISSIONS.companies]: 'Customers',
  [DEFINE_PERMISSIONS.companyStatus]: 'Customers',
  [DEFINE_PERMISSIONS.childCompanies]: 'Customers',
  [DEFINE_PERMISSIONS.companyAdditionalData]: 'Customers',
  [DEFINE_PERMISSIONS.companyMembers]: 'Customers',
  [DEFINE_PERMISSIONS.companyMembersBo]: 'Customers',
  [DEFINE_PERMISSIONS.companyPilots]: 'Customers',
  [DEFINE_PERMISSIONS.companySubscriptions]: 'Customers',
  [DEFINE_PERMISSIONS.companyVehicles]: 'Customers',
  [DEFINE_PERMISSIONS.companyVehiclesSharing]: 'Customers',
  [DEFINE_PERMISSIONS.companyElectronicKeys]: 'Customers',
  [DEFINE_PERMISSIONS.companyElectronicKeysSharing]: 'Customers',
  [DEFINE_PERMISSIONS.companyActivity]: 'Customers',
  [DEFINE_PERMISSIONS.subscriptions]: 'Customers',
  [DEFINE_PERMISSIONS.subscriptionStatus]: 'Customers',
  [DEFINE_PERMISSIONS.subscriptionAllowedPilots]: 'Customers',
  [DEFINE_PERMISSIONS.subscriptionVehicle]: 'Customers',
  [DEFINE_PERMISSIONS.subscriptionActivity]: 'Customers',
  [DEFINE_PERMISSIONS.userAppSubscriptionsAllowedPilot]: 'Customers',
  [DEFINE_PERMISSIONS.vehicles]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleStatus]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehiclePublicKeys]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleElectronicKeys]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleUsers]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleSharings]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehiclesSubscriptions]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleActivity]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeys]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeyStatus]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeyVehicles]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeysSharing]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeyActivity]: 'Vehicles',
  [DEFINE_PERMISSIONS.electronicKeyModels]: 'Vehicles',
  [DEFINE_PERMISSIONS.modelPERS]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleModels]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleBrands]: 'Vehicles',
  [DEFINE_PERMISSIONS.vehicleBrandMessage]: 'Vehicles',
  [DEFINE_PERMISSIONS.fromShowcaseWebsite]: "Visitor's Contacts",
  [DEFINE_PERMISSIONS.archivedVisitorContacts]: "Visitor's Contacts",
  [DEFINE_PERMISSIONS.logsContactUs]: "Visitor's Contacts",
  [DEFINE_PERMISSIONS.visitorContacts]: "Visitor's Contacts",
  [DEFINE_PERMISSIONS.scwSections]: 'Content Management',
  [DEFINE_PERMISSIONS.scwSectionComponent]: 'Content Management',
  [DEFINE_PERMISSIONS.scwSectionContent]: 'Content Management',
  [DEFINE_PERMISSIONS.scwStories]: 'Content Management',
  [DEFINE_PERMISSIONS.scwPrivacyPolicy]: 'Content Management',
  [DEFINE_PERMISSIONS.faq]: 'Content Management',
  [DEFINE_PERMISSIONS.topic]: 'Topic Management',
  [DEFINE_PERMISSIONS.scwMessages]: 'Content Management',
  [DEFINE_PERMISSIONS.scwMailTemplates]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.scwDocumentation]: 'Documentation',
  [DEFINE_PERMISSIONS.apaInterfaceLabels]: 'Content Management',
  [DEFINE_PERMISSIONS.apaInterfaceTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.apaInterfaceFormattedTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.apaMessages]: 'Content Management',
  [DEFINE_PERMISSIONS.apaMailTemplates]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.appVersioning]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.messagesAfterConnection]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.apaDocumentation]: 'Documentation',
  [DEFINE_PERMISSIONS.fmpInterfaceLabels]: 'Content Management',
  [DEFINE_PERMISSIONS.fmpInterfaceTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.fmpInterfaceFormattedTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.fmpMessages]: 'Content Management',
  [DEFINE_PERMISSIONS.fmpMailTemplates]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.fmpDocumentation]: 'Documentation',
  [DEFINE_PERMISSIONS.interfaceLabels]: 'Content Management',
  [DEFINE_PERMISSIONS.interfaceTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.interfaceFormattedTexts]: 'Content Management',
  [DEFINE_PERMISSIONS.messages]: 'Content Management',
  [DEFINE_PERMISSIONS.languages]: 'General Referentials',
  [DEFINE_PERMISSIONS.countries]: 'General Referentials',
  [DEFINE_PERMISSIONS.timezones]: 'General Referentials',
  [DEFINE_PERMISSIONS.currencies]: 'General Referentials',
  [DEFINE_PERMISSIONS.tagContactTypes]: 'Business Referentials',
  [DEFINE_PERMISSIONS.pilotTypes]: 'Business Referentials',
  [DEFINE_PERMISSIONS.cixiReferents]: 'Business Referentials',
  [DEFINE_PERMISSIONS.usersBO]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.roles]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.rolesConfiguration]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.platformTech]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.generalSettings]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.regionalSettings]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.datetimeFormats]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.conditionalFeatures]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.mailTemplates]: 'Configuration & Settings',
  [DEFINE_PERMISSIONS.documentation]: 'Documentation',
  [DEFINE_PERMISSIONS.adminDocumentation]: 'AdminDocumentation',
  [DEFINE_PERMISSIONS.apisCIXIDocumentation]: 'CIXI',
  [DEFINE_PERMISSIONS.apisPartnersDocumentation]: 'Partners',
  // [DEFINE_PERMISSIONS.getAuthVehicles]: 'Simulator APIs',
  // [DEFINE_PERMISSIONS.getWhatToDo]: 'Simulator APIs',
  // [DEFINE_PERMISSIONS.getNewPinBLEVehicle]: 'Simulator APIs',
  // [DEFINE_PERMISSIONS.getNewHardToken]: 'Simulator APIs',
  // [DEFINE_PERMISSIONS.getVehicleUsersList]: 'Simulator APIs',
  // [DEFINE_PERMISSIONS.getNewAuthVehicles]: 'Simulator APIs',
  [DEFINE_PERMISSIONS.simulatorVehicleAPIs]: 'Simulator Vehicle APIs',
  [DEFINE_PERMISSIONS.logMails]: 'Logs',
  [DEFINE_PERMISSIONS.logRoutines]: 'Logs'
}

export const MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY = Object.freeze({
  [OTHER_PLATFORM_PERMISSIONS.companyProfile]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfile_Information]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfile_Members]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyMembers]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfile_ChildCompanies]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfile_GeneralSettings]:
    'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.company_GeneralSettings]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptions]: 'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptions_Fleets]:
    'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptions_Orders]:
    'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagement]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagement_Teams]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagement_Vehicles]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagement]: 'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagement_CurrentVehiclesUse]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagement_UpcomingVehiclesUse]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagement_PastVehiclesUsed]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.statistics]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statistics_Usage]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statistics_Financial]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statistics_Users]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statistics_Vehicles]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.help]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.help_FAQ]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.help_Glossary]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.help_Documents]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.allVehicles]: 'Vehicle User'
})

// order to show on role management page
export const ROLE_ORDER = [
  'database',
  'showcase_website',
  'active_pilot_app',
  'fleet_manager_platform',
  'cixi_center',
  'apis',
  'logs',
  'simulator'
]

export const CATEGORY_PERMISSION_LABEL = Object.freeze({
  active_pilot_app: 'Active Pilot App',
  apis: 'APIs',
  cixi_center: 'CIXI Center',
  database: 'Database',
  fleet_manager_platform: 'Fleet Management Platform',
  logs: 'Logs',
  showcase_website: 'Showcase Website',
  simulator: 'Simulator'
})

/**
 * Mapping between URL and permission code suffix
 */
export const MAPPING_URL_TO_PERMISSIONS = Object.freeze({
  users: DEFINE_PERMISSIONS.usersBO,
  countries: DEFINE_PERMISSIONS.countries,
  'log-mails': DEFINE_PERMISSIONS.logMails,
  'app-versions': DEFINE_PERMISSIONS.appVersioning,
  'app-users': DEFINE_PERMISSIONS.usersPortal,
  'vehicles-sharing': DEFINE_PERMISSIONS.vehicleSharings,
  languages: DEFINE_PERMISSIONS.languages,
  timezones: DEFINE_PERMISSIONS.timezones,
  'regional-settings': DEFINE_PERMISSIONS.regionalSettings,
  'datetime-formats': DEFINE_PERMISSIONS.datetimeFormats,
  'log-routines': DEFINE_PERMISSIONS.logRoutines,
  roles: DEFINE_PERMISSIONS.roles,
  companies: DEFINE_PERMISSIONS.companies,
  subscriptions: DEFINE_PERMISSIONS.subscriptions,
  'electronic-keys-sharing': DEFINE_PERMISSIONS.electronicKeysSharing,
  vehicles: DEFINE_PERMISSIONS.vehicles,
  'electronic-keys': DEFINE_PERMISSIONS.electronicKeys,
  'model-pers': DEFINE_PERMISSIONS.modelPERS,
  'tags-contacts-type': DEFINE_PERMISSIONS.tagContactTypes,
  'pilot-types': DEFINE_PERMISSIONS.pilotTypes,
  'message-after-connection': DEFINE_PERMISSIONS.messagesAfterConnection,
  labels: DEFINE_PERMISSIONS.interfaceLabels,
  texts: DEFINE_PERMISSIONS.interfaceTexts,
  'formatted-texts': DEFINE_PERMISSIONS.interfaceFormattedTexts,
  messages: DEFINE_PERMISSIONS.messages,
  'mail-templates': DEFINE_PERMISSIONS.mailTemplates,
  'general-settings': DEFINE_PERMISSIONS.generalSettings,
  'conditional-features': DEFINE_PERMISSIONS.conditionalFeatures,
  'auth-vehicle': DEFINE_PERMISSIONS.getAuthVehicles,
  'what-to-do': DEFINE_PERMISSIONS.getWhatToDo,
  'new-pinble-vehicle': DEFINE_PERMISSIONS.getNewPinBLEVehicle,
  'new-hard-token': DEFINE_PERMISSIONS.getNewHardToken,
  'vehicle-list-users': DEFINE_PERMISSIONS.getVehicleUsersList,
  'get-new-auth-vehicle': DEFINE_PERMISSIONS.getNewAuthVehicles,
  'cixi-referent': DEFINE_PERMISSIONS.cixiReferents,
  'vehicle-model': DEFINE_PERMISSIONS.vehicleModels,
  'electronic-key-models': DEFINE_PERMISSIONS.electronicKeyModels,
  'roles-configuration': DEFINE_PERMISSIONS.rolesConfiguration,
  scwsection: DEFINE_PERMISSIONS.scwSections,
  sections: DEFINE_PERMISSIONS.scwSections,
  scwstory: DEFINE_PERMISSIONS.scwStories,
  scwprivacypolicy: DEFINE_PERMISSIONS.scwPrivacyPolicy,
  'admin-documentation': DEFINE_PERMISSIONS.adminDocumentation,
  'privacy-policy': DEFINE_PERMISSIONS.scwPrivacyPolicy,
  stories: DEFINE_PERMISSIONS.scwStories,
  'vehicle-brands': DEFINE_PERMISSIONS.vehicleBrands,
  fromshowcasewebsite: DEFINE_PERMISSIONS.fromShowcaseWebsite,
  'showcase-website': DEFINE_PERMISSIONS.logsContactUs,
  'platform-tech': DEFINE_PERMISSIONS.platformTech,
  'company-pilot': DEFINE_PERMISSIONS.companyPilots,
  'visitor-contacts': DEFINE_PERMISSIONS.fromShowcaseWebsite,
  faq: DEFINE_PERMISSIONS.faq,
  topics: DEFINE_PERMISSIONS.topic,
  currencies: DEFINE_PERMISSIONS.currencies
})

export const USER_FILTER = Object.freeze({
  name: <IntlMessages id='users.name' />,
  email: <IntlMessages id='users.email' />,
  selectedStatus: <IntlMessages id='users.status' />
})

export const MAPPING_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER = Object.freeze({
  nameVehicle: <IntlMessages id='electronicKeys.vehicle_name' />,
  vehicleType: <IntlMessages id='electronicKeys.vehicle_type' />,
  registrationNumber: (
    <IntlMessages id='electronicKeys.vehicle_register_number' />
  ),
  selectedStatus: <IntlMessages id='electronicKeys.status' />,
  isCurrent: <IntlMessages id='electronicKeys.is_current' />
})

export const MAPPING_LOG_MAIL_FILTER = Object.freeze({
  templateCode: <IntlMessages id='logMails.template_code' />,
  selectedModule: <IntlMessages id='logMails.module' />,
  sentFrom: <IntlMessages id='logMails.from' />,
  sentTo: <IntlMessages id='logMails.to' />,
  recipient: <IntlMessages id='logMails.recipient' />,
  sender: <IntlMessages id='logMails.sender' />,
  subject: <IntlMessages id='logMails.subject' />,
  status: <IntlMessages id='logMails.status' />
})

export const LOG_MAIL_STATUS_LABEL = Object.freeze({
  pending: <IntlMessages id='logMails.status_to_send' />,
  done: <IntlMessages id='logMails.status_sent' />,
  fail: <IntlMessages id='logMails.status_failed' />
})

export const DATE_LOG_MAIL_FILTER = ['sentFrom', 'sentTo']

export const MAPPING_LANGUAGE = Object.freeze({
  en: 'gb',
  uk: 'gb',
  ja: 'jp',
  hi: 'in',
  zh: 'cn',
  vi: 'vn'
})

export const MAPPING_LOG_ROUTINE_FILTER = Object.freeze({
  selectedCodeRoutine: <IntlMessages id='logRoutines.code' />,
  sentFrom: <IntlMessages id='logRoutines.date_from' />,
  sentTo: <IntlMessages id='logRoutines.date_to' />,
  selectedModule: <IntlMessages id='logRoutines.module' />
})

export const SELECT_LOG_ROUTINE_FILTER = ['selectedCodeRoutine']

export const DATE_LOG_ROUTINE_FILTER = ['sentFrom', 'sentTo']

export const DEFAULT_VEHICLE_TYPE = {
  label: <IntlMessages id='vehicleModels.vigoz' />,
  value: '1'
}
export const VEHICLE_TYPE_LIST = [
  { label: <IntlMessages id='vehicleModels.vigoz' />, value: '1' },
  { label: <IntlMessages id='vehicleModels.eBike' />, value: '2' }
]

export const VEHICLE_TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='vehicleModels.vigoz' />,
  2: <IntlMessages id='vehicleModels.eBike' />
})

export const MAPPING_VEHICLE_MODEL_FILTER = Object.freeze({
  nameVehicleModel: <IntlMessages id='vehicleModels.name' />,
  idVehicleType: <IntlMessages id='vehicleModels.type' />,
  vehicleBrand: <IntlMessages id='brand' />
})

export const MAPPING_MESSAGE_FILTER = Object.freeze({
  message: <IntlMessages id='messages.string' />,
  keyword: <IntlMessages id='messages.keyword' />,
  selectedModule: <IntlMessages id='messages.module' />,
  selectedLanguage: <IntlMessages id='messages.language' />,
  selectedSearchScope: <IntlMessages id='messages.search_in' />
})

export const MAPPING_MODEL_PER_FILTER = Object.freeze({
  label: <IntlMessages id='modelPers.name' />
})

export const MAPPING_CIXI_REFERENT_FILTER = Object.freeze({
  name: <IntlMessages id='cixiReferents.name' />,
  email: <IntlMessages id='cixiReferents.email' />
})

export const MAPPING_ELECTRONIC_KEY_MODEL_FILTER = Object.freeze({
  nameElectronicKeyModel: <IntlMessages id='electronicKeyModels.name' />,
  idVehicleType: <IntlMessages id='electronicKeyModels.type' />
})

export const SUBSCRIBER_TYPE_OPTIONS = [
  { value: 'COMPANY_BTOC', label: <IntlMessages id='company_btoc' /> },
  { value: 'COMPANY_BTOE', label: <IntlMessages id='company_btoe' /> }
]

export const COMPANY_TYPE_OPTIONS = [
  { value: 'independent', label: <IntlMessages id='independent' /> },
  { value: 'mother', label: <IntlMessages id='mother' /> },
  { value: 'children', label: <IntlMessages id='children' /> }
]

export const SUBSCRIBER_TYPES = Object.freeze({
  COMPANY_BTOC: <IntlMessages id='company_btoc' />,
  COMPANY_BTOE: <IntlMessages id='company_btoe' />
})

export const DEFAULT_COMPANY_TYPE = 'independent'

export const COMPANY_TYPES = Object.freeze({
  independent: <IntlMessages id='independent' />,
  mother: <IntlMessages id='mother' />,
  children: <IntlMessages id='children' />
})

export const COMPANY_TYPE_VALUES = Object.freeze({
  independent: 'independent',
  mother: 'mother',
  children: 'children'
})

export const COMPANY_STATUS_OPTIONS = [
  { value: 'to_approve', label: <IntlMessages id='status.to_approve' /> },
  { value: 'approved', label: <IntlMessages id='status.approved' /> },
  { value: 'refused', label: <IntlMessages id='status.refused' /> },
  { value: 'canceled', label: <IntlMessages id='status.canceled' /> }
]

export const COMPANY_STATUSES = Object.freeze({
  to_approve: <IntlMessages id='status.to_approve' />,
  approved: <IntlMessages id='status.approved' />,
  refused: <IntlMessages id='status.refused' />,
  canceled: <IntlMessages id='status.canceled' />
})

export const PILOT_STATUS_OPTIONS = [
  { value: 'requested', label: <IntlMessages id='status.requested' /> },
  { value: 'approved', label: <IntlMessages id='status.approved' /> },
  { value: 'refused', label: <IntlMessages id='status.refused' /> },
  { value: 'canceled', label: <IntlMessages id='status.canceled' /> }
]

export const PILOT_STATUSES = Object.freeze({
  requested: <IntlMessages id='status.requested' />,
  approved: <IntlMessages id='status.approved' />,
  refused: <IntlMessages id='status.refused' />,
  canceled: <IntlMessages id='status.canceled' />
})

export const MAPPING_COMPANY_FILTER = Object.freeze({
  companyName: 'Name',
  zipCode: 'Zip Code',
  selectedCountry: 'Country',
  selectedCompanyType: 'Company Type',
  selectedSubscriberType: 'SubscriberType',
  selectedStatus: 'Status'
})

export const MAPPING_COMPANY_VEHICLE_FILTER = Object.freeze({
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  vehicleName: <IntlMessages id='vehicles.name' />,
  serialNumber: <IntlMessages id='vehicles.serial_number' />,
  type: <IntlMessages id='vehicles.type' />,
  brandId: <IntlMessages id='vehicles.brand' />,
  status: <IntlMessages id='vehicles.status' />
})

export const MAPPING_COMPANY_SUBSCRIPTIONS_FILTER = Object.freeze({
  subscriber: <IntlMessages id='subscription.subscriber' />,
  subscriptionRef: <IntlMessages id='subscription.subscription' />,
  status: <IntlMessages id='subscription.status' />
})

export const COMPANY_DETAIL_BUTTONS = Object.freeze({
  approve: <IntlMessages id='button.approve' />,
  refuse: <IntlMessages id='button.refuse' />,
  cancel: <IntlMessages id='button.cancel' />
})

export const MAPPING_APP_USER_FILTER = Object.freeze({
  odooId: <IntlMessages id='odoo_id' />,
  name: <IntlMessages id='app_users.name' />,
  email: <IntlMessages id='app_users.email' />,
  selectedCountry: <IntlMessages id='app_users.country' />
})

export const SORT_BY_VEHICLE_OPTIONS = [
  {
    value: 'registrationNumber',
    label: <IntlMessages id='vehicles.registration_number' />
  },
  { value: 'name', label: <IntlMessages id='vehicles.name' /> }
]

export const MAPPING_VEHICLE_FILTER = Object.freeze({
  selectedVehicleType: <IntlMessages id='vehicles.type' />,
  nameVehicle: <IntlMessages id='vehicles.name' />,
  serialNumber: <IntlMessages id='serial_number' />,
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  selectedSortBy: <IntlMessages id='pages.order_by' />,
  vehicleBrand: <IntlMessages id='brand' />
})

export const MAPPING_ELECTRONIC_KEY_FILTER = Object.freeze({
  selectElectronicKeyModel: <IntlMessages id='electronicKeys.model' />,
  nuSerialElectronicKeyNumber: <IntlMessages id='serial_number' />,
  vehicleName: <IntlMessages id='electronicKeys.vehicleName' />,
  vehicleRegisterNumber: (
    <IntlMessages id='electronicKeys.vehicleRegisterNumber' />
  )
})

export const DEFAULT_APP_VERSIONS_FILTER = Object.freeze({
  platformTechId: '',
  isActive: '',
  version: '',
  os: ''
})

export const DEFAULT_MESSAGE_AFTER_CONNECTION_FILTER = Object.freeze({
  portalId: '',
  title: '',
  dtBegin: '',
  dtEnd: ''
})

export const ACTIVITY_TYPES = Object.freeze({
  system_log: 'system_log',
  manual_comment: 'manual_comment'
})

export const MODEL_TYPES = Object.freeze({
  company: 'company',
  vehicle: 'vehicle',
  electronic_key: 'electronic_key',
  user_app: 'user_app',
  subscription: 'subscription'
})

export const ACTIVITY_TYPE_VALUES = Object.freeze({
  manual_comment: <IntlMessages id='activity.manual' />
})

export const MEMBER_STATUSES = Object.freeze({
  requested: 1,
  accepted: 2,
  refused: 3,
  canceled: 4
})

export const MEMBER_STATUS_VALUES = Object.freeze({
  1: <IntlMessages id='status.requested' />,
  2: <IntlMessages id='status.accepted' />,
  3: <IntlMessages id='status.refused' />,
  4: <IntlMessages id='status.canceled' />
})

export const CANCEL_MEMBER_SUCCESS_MESSAGE = (
  <SystemMessages id='CANCEL_MEMBER_SUCCESS_MESSAGE' />
)
export const CANCEL_MEMBER_FAILED_MESSAGE = (
  <SystemMessages id='CANCEL_MEMBER_FAILED_MESSAGE' />
)

export const REQUEST_CONFIRM_MESSAGE =
  'Are you sure you want to request {name}?'
export const REFUSE_CONFIRM_MESSAGE = 'Are you sure you want to refuse {name}?'
export const APPROVE_CONFIRM_MESSAGE =
  'Are you sure you want to approve {name}?'
export const DELETE_CONFIRM_MESSAGE = 'Are you sure you want to delete {name}?'
export const CANCEL_CONFIRM_MESSAGE = 'Are you sure you want to cancel {name}?'
export const DELETE_MEMBER_CONFIRM_MESSAGE =
  'Are you sure you want to delete this member ?{name}'

export const CONVERT_TO_MOTHER_COMPANY_CONFIRM_MESSAGE =
  'After the conversion, this Company can not come back to the Independent Company. Do you confirm ?{name}'
export const REMOVE_CHILDREN_COMPANY_CONFIRM_MESSAGE =
  'This child company will become Individual Company. Do you confirm ?{name}'

export const DELETE_TRANSLATION_MESSAGE =
  'Are you sure you want to delete Translation value for {name} ?'

export const MAPPING_CHILDREN_COMPANY_FILTER = Object.freeze({
  companyName: <IntlMessages id='filter.name' />,
  zipCode: <IntlMessages id='filter.zipCode' />,
  selectedCountry: <IntlMessages id='filter.country' />
})

export const DEFAULT_PAGINATION = {
  currentPage: 1,
  totalPages: 1
}

export const DEFAULT_PAGE_LIMIT = {
  page: 1,
  limit: 10
}

export const DEFAULT_SCROLL_LOAD_PAGE_LIMIT = {
  page: 1,
  limit: 36
}

export const DEFAULT_APP_USER_FILTER = {
  selectedCountry: null,
  odooId: '',
  email: '',
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_APP_USER_SUBSCRIPTIONS_FILTER = {
  subscriptionRef: '',
  status: ''
}

export const DEFAULT_APP_USER_VEHICLES_FILTER = {
  vehicleName: '',
  registrationNumber: '',
  serialNumber: '',
  type: '',
  brandId: '',
  startDate: null,
  endDate: null
}

export const DEFAULT_COMPANY_FILTER = {
  selectedCompanyType: null,
  selectedSubscriberType: null,
  selectedStatus: null,
  selectedCountry: null,
  selectedOrderBy: null,
  zipCode: '',
  companyName: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_PILOTS_FILTER = {
  name: '',
  email: '',
  status: '',
  ...DEFAULT_SCROLL_LOAD_PAGE_LIMIT
}

export const DEFAULT_COMPANY_VEHICLES_FILTER = {
  registrationNumber: '',
  vehicleName: '',
  serialNumber: '',
  type: '',
  brandId: '',
  status: ''
}

export const DEFAULT_COMPANY_SUBSCRIPTIONS_FILTER = {
  subscriber: '',
  subscriptionRef: '',
  status: ''
}

export const DEFAULT_SUBSCRIPTION_FILTER = {
  vehicleName: '',
  vehicleRegistrationNumber: '',
  status: '',
  vehicleType: '',
  referenceSubscription: '',
  subscriber: '',
  company: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_COUNTRY_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_DATE_TIME_FORMAT = {
  selectedLanguage: ''
}

export const DEFAULT_CIXI_REFERENT_FILTER = {
  name: '',
  email: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_TOPIC_FILTER = {
  topicType: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_DOCUMENTATION_FILTER = {
  name: '',
  topicId: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_LABEL_FILTER = {
  name: '',
  keyword: '',
  selectedModule: null,
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_TEXT_FILTER = {
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  selectedModule: null,
  keyword: '',
  originalText: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_LOG_MAIL_FILTER = {
  selectedModule: null,
  templateCode: '',
  sentFrom: toDateString(getLastMonth(3)),
  sentTo: toDateString(new Date()),
  recipient: '',
  sender: '',
  subject: '',
  sortBy: 'dateSent:desc',
  status: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER = Object.freeze({
  nameVehicle: '',
  vehicleType: '',
  registrationNumber: '',
  status: '',
  isCurrent: true,
  ...DEFAULT_PAGE_LIMIT
})

export const DEFAULT_LOG_ROUTINE_FILTER = {
  selectedModule: null,
  selectedCodeRoutine: null,
  sentFrom: toDateString(getLastMonth(3)),
  sentTo: toDateString(new Date()),
  sortBy: 'date:desc',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ROLE_FILTER = {
  name: '',
  selectedGroupCode: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_USER_FILTER = {
  name: '',
  email: '',
  selectedStatus: DEFAULT_USER_STATUS_LIST,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEY_MODEL_FILTER = {
  nameElectronicKeyModel: '',
  idVehicleType: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEY_FILTER = {
  nuSerialElectronicKeyNumber: '',
  selectElectronicKeyModel: null,
  vehicleName: '',
  vehicleRegisterNumber: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_MODEL_PER_FILTER = {
  label: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_MODEL_FILTER = {
  nameVehicleModel: '',
  idVehicleType: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_FILTER = {
  selectedVehicleType: null,
  serialNumber: '',
  registrationNumber: '',
  nameVehicle: '',
  selectedSortBy: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_SUBSCRIPTION_FILTER = {
  companyId: '',
  subscriber: '',
  status: '',
  subscriptionRef: ''
}

export const DEFAULT_VEHICLE_USERS_FILTER = {
  pilot: '',
  dtBegin: null,
  dtEnd: null
}

export const DEFAULT_LANGUAGE_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_CURRENCY_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_CONDITIONAL_FEATURE_FILTER = {
  module: null,
  keyword: '',
  title: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_TIMEZONE_FILTER = {
  name: '',
  sortBy: `name:${SORT_ASC}`,
  ...DEFAULT_PAGE_LIMIT
}

export const MAPPING_ROLE_FILTER = Object.freeze({
  name: <IntlMessages id='roles.title' />,
  selectedGroupCode: <IntlMessages id='roles.type' />
})

export const VEHICLE_ELECTRONIC_KEY_STATUS_VALUES = Object.freeze({
  1: <IntlMessages id='status.new' />,
  2: <IntlMessages id='status.in_progress' />,
  3: <IntlMessages id='status.closed' />
})

export const VEHICLE_ELECTRONIC_KEY_STATUSES = [
  { value: '1', label: <IntlMessages id='status.new' /> },
  { value: '2', label: <IntlMessages id='status.in_progress' /> },
  { value: '3', label: <IntlMessages id='status.closed' /> }
]

export const MAPPING_LOGS = Object.freeze({
  companyName: <IntlMessages id='companies.name' />,
  cityAddress: <IntlMessages id='companies.address' />,
  countryAddressId: <IntlMessages id='companies.country' />,
  vehicleType: <IntlMessages id='type' />,
  serialNumber: <IntlMessages id='serial_number' />,
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  nameVehicle: <IntlMessages id='vehicles.name' />,
  referenceSubscription: (
    <IntlMessages id='subscription.reference_subscription' />
  ),
  nuSerialElectronicKeyNumber: <IntlMessages id='serial_number' />
})

export const STATUS_CODE = {
  UNAUTHORIZE: 401
}

export const MODULE_TYPES = {
  SHOWCASE_WEB: 'showcaseweb',
  MOBILE_APP: 'mobileapp',
  BACK_OFFICE: 'backoffice',
  FLEET_MANAGER: 'fleetmanager'
}

export const MODULES = {
  showcaseweb: <IntlMessages id='module.showcase_web' />,
  mobileapp: <IntlMessages id='module.mobile_app' />,
  backoffice: <IntlMessages id='module.back_office' />,
  fleetmanager: <IntlMessages id='module.fleet_manager' />
}

export const MAP_MODULE_TO_FILTER = {
  [MODULE_TYPES.BACK_OFFICE]: 'filter',
  [MODULE_TYPES.SHOWCASE_WEB]: 'scwFilter',
  [MODULE_TYPES.MOBILE_APP]: 'mobileFilter',
  [MODULE_TYPES.FLEET_MANAGER]: 'fleetFilter'
}

export const TOPIC_TYPES = {
  0: <IntlMessages id='topic.faqTopic' />,
  1: <IntlMessages id='topic.documentationTopic' />
}

export const SUBSCRIPTION_SUBSCRIBER_TYPE = [
  {
    value: 'company',
    label: <IntlMessages id='subscription.subscriber_type_company' />
  },
  {
    value: 'individual',
    label: <IntlMessages id='subscription.subscriber_type_individual' />
  }
]

export const SUBSCRIPTION_STATUSES = [
  {
    label: <IntlMessages id='subscription.requested_by_customer' />,
    value: 'requested_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposed_by_cixi' />,
    value: 'proposed_by_cixi'
  },
  {
    label: <IntlMessages id='subscription.accepted_by_customer' />,
    value: 'accepted_by_customer'
  },
  {
    label: <IntlMessages id='subscription.accepted_by_cixi' />,
    value: 'accepted_by_cixi'
  },
  { label: <IntlMessages id='subscription.delivered' />, value: 'delivered' },
  {
    label: <IntlMessages id='subscription.request_refused_by_cixi' />,
    value: 'request_refused_by_cixi'
  },
  {
    label: <IntlMessages id='subscription.request_canceled_by_customer' />,
    value: 'request_canceled_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposal_refused_by_customer' />,
    value: 'proposal_refused_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposal_canceled_by_cixi' />,
    value: 'proposal_canceled_by_cixi'
  },
  { label: <IntlMessages id='subscription.in_closure' />, value: 'in_closure' },
  { label: <IntlMessages id='subscription.closed' />, value: 'closed' },
  {
    label: <IntlMessages id='subscription.canceled_by_cixi' />,
    value: 'canceled_by_cixi'
  }
]

export const SUBSCRIPTION_STATUS_OBJECT = SUBSCRIPTION_STATUSES.reduce(
  (acc, t) => ({ ...acc, [t.value]: t }),
  {}
)

export const SUBSCRIPTION_VEHICLE_STATUSES = [
  {
    label: <IntlMessages id='subscription.coming' />,
    value: 'coming'
  },
  {
    label: <IntlMessages id='subscription.delivery_in_progress' />,
    value: 'delivery_in_progress'
  },
  {
    label: <IntlMessages id='subscription.in_progress' />,
    value: 'in_progress'
  },
  {
    label: <IntlMessages id='subscription.returned' />,
    value: 'returned'
  }
]

export const SUBSCRIPTION_VEHICLE_STATUS_VALUES = {
  coming: <IntlMessages id='subscription.coming' />,
  delivery_in_progress: <IntlMessages id='subscription.delivery_in_progress' />,
  in_progress: <IntlMessages id='subscription.in_progress' />,
  returned: <IntlMessages id='subscription.returned' />
}

export const SUBSCRIPTION_MAPPING_FILTER = Object.freeze({
  vehicleName: <IntlMessages id='subscription.vehicle_name' />,
  vehicleRegistrationNumber: (
    <IntlMessages id='subscription.vehicle_registration_number' />
  ),
  status: <IntlMessages id='subscription.status' />,
  vehicleType: <IntlMessages id='subscription.vehicle_type' />,
  referenceSubscription: <IntlMessages id='subscription.subscription' />,
  subscriber: <IntlMessages id='subscription.subscriber' />,
  company: <IntlMessages id='subscription.company' />
})

export const DEFAULT_SHOWCASE_SECTION_FILTER = {
  keyword: '',
  label: '',
  technicalDescription: '',
  sectionPage: '',
  ...DEFAULT_PAGE_LIMIT,
  offset: 0
}

export const MAPPING_SHOWCASE_SECTION_FILTER = Object.freeze({
  keyword: <IntlMessages id='keyword' />,
  label: <IntlMessages id='label' />,
  technicalDescription: <IntlMessages id='technical_description' />,
  sectionPage: <IntlMessages id='page' />
})

export const TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='type.string' />,
  2: <IntlMessages id='type.text' />,
  3: <IntlMessages id='type.rich_text' />,
  4: <IntlMessages id='type.icon' />,
  5: <IntlMessages id='type.static_picture' />,
  6: <IntlMessages id='type.animated_picture' />,
  7: <IntlMessages id='type.video' />,
  // 8: <IntlMessages id="type.internal_url" />,
  9: <IntlMessages id='type.external_url' />,
  10: <IntlMessages id='type.file' />
})

export const TYPE_LIST = [
  { label: <IntlMessages id='type.string' />, value: '1' },
  { label: <IntlMessages id='type.text' />, value: '2' },
  { label: <IntlMessages id='type.rich_text' />, value: '3' },
  { label: <IntlMessages id='type.icon' />, value: '4' },
  { label: <IntlMessages id='type.static_picture' />, value: '5' },
  { label: <IntlMessages id='type.animated_picture' />, value: '6' },
  { label: <IntlMessages id='type.video' />, value: '7' },
  // { label: <IntlMessages id="type.internal_url" />, value: '8' },
  { label: <IntlMessages id='type.external_url' />, value: '9' },
  { label: <IntlMessages id='type.file' />, value: '10' }
]

export const DOCUMENT_CONTENT_TYPES = Object.freeze({
  RICH_TEXT: 1,
  PDF: 2,
  EXTERNAL_URL: 3
})

export const DOCUMENT_CONTENT_TYPE_VALUES = Object.freeze({
  [DOCUMENT_CONTENT_TYPES.RICH_TEXT]: <IntlMessages id='type.rich_text' />,
  [DOCUMENT_CONTENT_TYPES.PDF]: <IntlMessages id='type.pdf' />,
  [DOCUMENT_CONTENT_TYPES.EXTERNAL_URL]: <IntlMessages id='type.extenal_url' />
})

export const DEFAULT_SUBSCRIPTION_TYPE = 'company'

export const DELETE_TRANSLATION_CONFIRM_MESSAGE =
  'Are you sure you want to delete Translation value for {name} ?'

export const DEFAULT_MAIL_TEMPLATE_FILTER = Object.freeze({
  name: '',
  keyword: '',
  moduleId: '',
  ...DEFAULT_PAGE_LIMIT
})

export const MAPPING_MAIL_TEMPLATE_FILTER = Object.freeze({
  name: <IntlMessages id='name' />,
  keyword: <IntlMessages id='keyword' />,
  moduleId: <IntlMessages id='module' />
})

export const TYPE_USE_EDITOR = ['3']
export const TYPE_USE_UPLOAD_S3 = ['4', '5', '6', '7']
export const TYPE_NOT_USING_PREVIEW = ['3']
export const TYPE_IMAGE = ['4', '5', '6']
export const TYPE_VIDEO = ['7']

export const DEFAULT_SHOWCASE_STORY_FILTER = {
  name: '',
  status: '',
  author: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_FAQ_FILTER = {
  name: '',
  topic: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_SHOWCASE_CONTACT_LOG_FILTER = {
  email: '',
  content: '',
  ...DEFAULT_PAGE_LIMIT
}

const toArrayOptions = (obj) => {
  if (!obj) {
    return []
  }
  return Object.keys(obj).map((key) => ({ label: obj[key], value: key }))
}

export const STORY_STATUS_VALUE = Object.freeze({
  PUBLISH: 'published',
  UNPUBLISH: 'un_published'
})

export const STORY_STATUS_LABEL = Object.freeze({
  [STORY_STATUS_VALUE.PUBLISH]: <IntlMessages id='status.published' />,
  [STORY_STATUS_VALUE.UNPUBLISH]: <IntlMessages id='status.un_published' />
})

export const STORY_STATUS_LIST = toArrayOptions(STORY_STATUS_LABEL)

export const CAROUSEL_TAG_STORY_VALUE = Object.freeze({
  client: 'CLIENT',
  vision: 'VISION'
})

export const CAROUSEL_TAG_STORY_LABEL = Object.freeze({
  [CAROUSEL_TAG_STORY_VALUE.client]: (
    <IntlMessages id='story.carousel_tag_client' />
  ),
  [CAROUSEL_TAG_STORY_VALUE.vision]: (
    <IntlMessages id='story.carousel_tag_vision' />
  )
})

export const CAROUSEL_TAG_STORY_LIST = toArrayOptions(CAROUSEL_TAG_STORY_LABEL)

export const FAQ_TOPICS = Object.freeze({
  1: <IntlMessages id='faq.pers_technology' />,
  2: <IntlMessages id='faq.vigoz' />,
  3: <IntlMessages id='faq.eBike' />
})

export const FAQ_STATUS = Object.freeze({
  published: 'published',
  unPublished: 'un_published'
})

export const MAPPING_SHOWCASE_STORY_FILTER = Object.freeze({
  name: <IntlMessages id='name' />,
  author: <IntlMessages id='author' />,
  status: <IntlMessages id='status' />,
  carouselTag: <IntlMessages id='carousel_tag' />
})

export const MAPPING_MAIN_PICTURE_FILE_TYPES = Object.freeze({
  ANIMATED_PICTURE: ['gif'],
  STATIC_PICTURE: ['png', 'jpg', 'jpeg', 'webp'],
  VIDEO: ['mp4', 'avi', 'wmv']
})

export const AVATAR_ACCEPT = 'image/png, image/jpg, image/jpeg, image/webp'
export const LIST_MEDIA_ACCEPT =
  'image/gif, image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/avi, video/wmv'

export const PUBLISH_STORY_CONFIRM_MESSAGE =
  'Are you sure you want to publish the story: {name}?'

export const PUBLISH_STORY_UN_CONFIRM_MESSAGE =
  'Are you sure you want to unpublish the story: {name}?'

export const PUBLISH_STORY_SUCCESS = (
  <SystemMessages id='PUBLISH_STORY_SUCCESS' />
)
export const PUBLISH_STORY_FAILED = <SystemMessages id='PUBLISH_STORY_FAILED' />

export const UN_PUBLISH_STORY_SUCCESS = (
  <SystemMessages id='UN_PUBLISH_STORY_SUCCESS' />
)
export const UN_PUBLISH_STORY_FAILED = (
  <SystemMessages id='UN_PUBLISH_STORY_FAILED' />
)

export const SECTION_PAGE_VALUES = Object.freeze({
  1: 'HOMEPAGE',
  2: 'ACTIVE_VEHICLE',
  3: 'ACTIVE_VEHICLE_TECHNOLOGY',
  4: 'ABOUT_US',
  5: 'ABOUT_US_STORIES',
  6: 'VIBES_STORIES_DETAIL',
  7: 'CONTACT_US',
  8: 'STORIES',
  9: 'STORY_DETAIL',
  10: 'PRIVACY_POLICY'
})

export const SECTION_PAGE_LIST = [
  { label: 'HOMEPAGE', value: '1' },
  { label: 'ACTIVE_VEHICLE', value: '2' },
  { label: 'ACTIVE_VEHICLE_TECHNOLOGY', value: '3' },
  { label: 'ABOUT_US', value: '4' },
  { label: 'ABOUT_US_STORIES', value: '5' },
  { label: 'VIBES_STORIES_DETAIL', value: '6' },
  { label: 'CONTACT_US', value: '7' },
  { label: 'STORIES', value: '8' },
  { label: 'STORY_DETAIL', value: '9' },
  { label: 'PRIVACY_POLICY', value: '10' }
]

export const MAPPING_SHOWCASE_CONTACT_LOGS_FILTER = Object.freeze({
  email: <IntlMessages id='email' />,
  content: <IntlMessages id='content' />,
  startDate: <IntlMessages id='startDate' />,
  endDate: <IntlMessages id='endDate' />,
  type: <IntlMessages id='type' />
})

export const CONTACT_LOG_TYPES = Object.freeze({
  notify_me: 'notify_me',
  request_brochure: 'request_brochure',
  contact_us: 'contact_us'
})

export const CONTACT_LOG_LIST = [
  { label: <IntlMessages id='notify_me' />, value: 'notify_me' },
  { label: <IntlMessages id='request_brochure' />, value: 'request_brochure' },
  { label: <IntlMessages id='contact_us' />, value: 'contact_us' }
]

export const CONTACT_LOG_TYPE_VALUES = Object.freeze({
  notify_me: <IntlMessages id='notify_me' />,
  request_brochure: <IntlMessages id='request_brochure' />,
  contact_us: <IntlMessages id='contact_us' />
})

export const NOTIFY_ME_REQUEST_TYPE_VALUES = Object.freeze({
  private_individual: <IntlMessages id='private_individual' />,
  company: <IntlMessages id='company' />
})

// USED IN BRAND MESSAGES
export const DEVICE_TYPES = Object.freeze({
  1: <IntlMessages id='brandMessage.smartPhone' />,
  2: <IntlMessages id='brandMessage.tablet' />,
  3: <IntlMessages id='brandMessage.desktop' />
})

export const SUBSCRIPTION_TYPES = Object.freeze({
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
})

export const DEFAULT_MESSAGE_FILTER = {
  message: '',
  keyword: '',
  selectedModule: null,
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  ...DEFAULT_PAGE_LIMIT
}

export const INDIVIDUAL_VEHICLE_SHARING_STATUS = Object.freeze({
  1: <IntlMessages id='vehicle_sharing.sharing_status_waiting_approbation' />,
  2: <IntlMessages id='vehicle_sharing.sharing_status_in_progress' />,
  3: <IntlMessages id='vehicle_sharing.sharing_status_closed' />
})

export const INDIVIDUAL_SHARING_EXTENSION_STATUS = Object.freeze({
  1: <IntlMessages id='vehicle_sharing.ext_status_requested_by_beneficiary' />,
  2: <IntlMessages id='vehicle_sharing.ext_status_propose_by_sharer' />,
  3: <IntlMessages id='vehicle_sharing.ext_status_accepted_by_sharer' />,
  4: <IntlMessages id='vehicle_sharing.ext_status_accepted_by_beneficiary' />,
  5: <IntlMessages id='vehicle_sharing.ext_status_force_by_sharer' />,
  6: <IntlMessages id='vehicle_sharing.ext_status_closed' />,
  7: (
    <IntlMessages id='vehicle_sharing.ext_status_request_canceled_by_beneficiary' />
  ),
  8: <IntlMessages id='vehicle_sharing.ext_status_request_refused_by_sharer' />,
  9: (
    <IntlMessages id='vehicle_sharing.ext_status_proposal_canceled_by_sharer' />
  ),
  10: (
    <IntlMessages id='vehicle_sharing.ext_status_proposal_refused_by_beneficiary' />
  ),
  11: <IntlMessages id='vehicle_sharing.ext_status_canceled_by_sharer' />,
  12: <IntlMessages id='vehicle_sharing.ext_status_canceled_by_beneficiary' />
})

export const INDIVIDUAL_VEHICLE_SHARING_TYPE = {
  1: <IntlMessages id='vehicle_sharing.type_temporal_sharing' />,
  2: <IntlMessages id='vehicle_sharing.type_unlimited_sharing' />
}

export const USER_TYPE = Object.freeze({
  SHARE: 1,
  BENEFICIARY: 2
})

export const INDIVIDUAL_VEHICLE_EXTENSION_PRICING_PERIOD_TYPE = {
  1: <IntlMessages id='vehicle_sharing.period_type_day' />
}

export const CURRENCY_FORMAT_DISPLAY = {
  afterAmount: 1,
  beforeAmount: 2
}

export const FORMAT_SORT_DATE = 'MM/DD/YYYY'
export const FORMAT_MEDIUM_DATE = 'MMM DD YYYY'
export const FORMAT_LONG_DATE = 'YYYY-MM-DD HH:mm:ss'

export const DATE_FORMAT = {
  NONE: 'none',
  SHORT: 'dateFormatShort',
  MEDIUM: 'dateFormatMedium',
  LONG: 'dateFormatLong'
}

export const SUBSCRIPTION_PROFILE_PILOT = {
  main_pilot: <IntlMessages id='subscription.profile_main_pilot' />,
  permanent_pilot: <IntlMessages id='subscription.profile_permanent_pilot' />,
  occasional_pilot: <IntlMessages id='subscription.profile_occasional_pilot' />
}

export const SUBSCRIPTION_ALLOWED_PILOT_STATUS = {
  requested: <IntlMessages id='subscription.allowed_pilot_requested' />,
  accepted: <IntlMessages id='subscription.allowed_pilot_accepted' />,
  refused: <IntlMessages id='subscription.allowed_pilot_refused' />,
  canceled: <IntlMessages id='subscription.allowed_pilot_canceled' />
}
