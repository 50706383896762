import VehicleTypes from 'store/action_types/vehicle'

import {
  subscription as subscriptionApi,
  vehicle as vehicleApi,
  vehicleFleet as vehicleFleetApi
} from 'store/api'

import { bindRequestLogic } from './utils'
import {
  mapVehicleSubscriptionsResponse,
  mapVehicleUserResponse
} from '../mapping'

const getVehicleList = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.getVehicles,
  type: VehicleTypes.GET_VEHICLES_REQUEST,
  successType: VehicleTypes.GET_VEHICLES_SUCCESS,
  failType: VehicleTypes.GET_VEHICLES_FAILED,
  isQueryParam: true,
  modelName: 'vehicles'
})

const getVehicleDetail = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.getVehicleDetail,
  type: VehicleTypes.GET_VEHICLE_DETAIL_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_DETAIL_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_DETAIL_FAILED,
  modelName: 'vehicle'
})

const deleteVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.deleteVehicle,
  type: VehicleTypes.DELETE_VEHICLE_REQUEST,
  successType: VehicleTypes.DELETE_VEHICLE_SUCCESS,
  failType: VehicleTypes.DELETE_VEHICLE_FAILED
})

const addVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.addVehicle,
  type: VehicleTypes.ADD_VEHICLE_REQUEST,
  successType: VehicleTypes.ADD_VEHICLE_SUCCESS,
  failType: VehicleTypes.ADD_VEHICLE_FAILED
})

const updateVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.updateVehicle,
  type: VehicleTypes.UPDATE_VEHICLE_REQUEST,
  successType: VehicleTypes.UPDATE_VEHICLE_SUCCESS,
  failType: VehicleTypes.UPDATE_VEHICLE_FAILED
})

const deleteUserVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.deleteUserVehicle,
  type: VehicleTypes.DELETE_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.DELETE_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.DELETE_USER_VEHICLE_FAILED
})

const addUserVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.addUserVehicle,
  type: VehicleTypes.ADD_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.ADD_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.ADD_USER_VEHICLE_FAILED
})

const updateUserVehicle = bindRequestLogic({
  clientApi: vehicleApi,
  clientFunc: vehicleApi.updateUserVehicle,
  type: VehicleTypes.UPDATE_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.UPDATE_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.UPDATE_USER_VEHICLE_FAILED
})

const getVehicleSubscriptions = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.getVehicleSubscriptions,
  type: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_FAILED,
  isQueryParam: true,
  mapResponse: mapVehicleSubscriptionsResponse
})

const getVehicleUsers = bindRequestLogic({
  clientApi: vehicleFleetApi,
  clientFunc: vehicleFleetApi.getVehicleUsers,
  type: VehicleTypes.GET_VEHICLE_USERS_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_USERS_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_USERS_FAILED,
  isQueryParam: true,
  mapResponse: mapVehicleUserResponse
})

export default [
  getVehicleList,
  getVehicleDetail,
  deleteVehicle,
  addVehicle,
  updateVehicle,
  deleteUserVehicle,
  addUserVehicle,
  updateUserVehicle,
  getVehicleSubscriptions,
  getVehicleUsers
]
