import {
  DEFAULT_PAGINATION,
  DEFAULT_SHOWCASE_SECTION_FILTER
} from 'constants/common'
import ShowcaseSectionTypes from 'store/action_types/showcaseSection'
import ShowcaseComponentTypes from 'store/action_types/showcaseComponent'

import UserTypes from 'store/action_types/user'

const initShowcaseSection = {
  list: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_SHOWCASE_SECTION_FILTER,
  components: {
    list: [],
    pagination: DEFAULT_PAGINATION
  }
}

const showcaseSection = (state = initShowcaseSection, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }
    case ShowcaseSectionTypes.UPDATE_FILTER_SHOWCASE_SECTION_SUCCESS:
      return { ...state, filter: data }
    case ShowcaseSectionTypes.CLEAR_FILTER_SHOWCASE_SECTION_SUCCESS:
      return { ...state, filter: initShowcaseSection.filter }
    case ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: {
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }

    case UserTypes.LOGOUT_SUCCESS:
      return initShowcaseSection
    default:
      return state
  }
}

export default showcaseSection
