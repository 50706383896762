export const PLATFORM_TECH_KEY = {
  BACKOFFICE: 'BackOffice',
  PILOT_APP_IOS: 'PilotAppIOS',
  PILOT_APP_ANDROID: 'PilotAppAndroid',
  FLEET_MANAGER_WEB: 'FleetManagerWeb'
}

export const OS_TYPES = {
  1: 'IOS',
  2: 'Android',
  3: 'Web'
}
