import { getUniqueItem } from 'helpers/Utils'
import ActivityTypes from 'store/action_types/activity'
import UserTypes from 'store/action_types/user'

const initSubscriptionActivity = {
  list: [],
  current: {},
  activeList: null,
  default: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  }
}

const subscriptionActivity = (state = initSubscriptionActivity, action) => {
  switch (action.type) {
    case ActivityTypes.GET_SUBSCRIPTION_ACTIVITIES_SUCCESS: {
      let list = action.payload?.items

      if (action?.payload?.meta?.currentPage !== 1)
        list = getUniqueItem([...(action.payload?.items || []), ...state.list])

      return {
        ...state,
        list,
        pagination: action.payload?.meta
      }
    }
    case UserTypes.LOGOUT_SUCCESS:
      return initSubscriptionActivity
    default:
      return state
  }
}

export default subscriptionActivity
