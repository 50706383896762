import keyMirror from 'keymirror'

export default keyMirror({
  GET_LABELS_REQUEST: null,
  GET_LABELS_SUCCESS: null,
  GET_LABELS_FAILED: null,

  SAVE_LABELS_CONFIGURATION_REQUEST: null,
  SAVE_LABELS_CONFIGURATION_SUCCESS: null,
  SAVE_LABELS_CONFIGURATION_FAILED: null,

  UPDATE_LABELS_REQUEST: null,
  UPDATE_LABELS_SUCCESS: null,
  UPDATE_LABELS_FAILED: null,

  CREATE_LABEL_REQUEST: null,
  CREATE_LABEL_SUCCESS: null,
  CREATE_LABEL_FAILED: null,

  DELETE_LABEL_REQUEST: null,
  DELETE_LABEL_SUCCESS: null,
  DELETE_LABEL_FAILED: null,

  IMPORT_LABELS_REQUEST: null,
  IMPORT_LABELS_SUCCESS: null,
  IMPORT_LABELS_FAILED: null,

  GET_SYSTEM_LABELS_REQUEST: null,
  GET_SYSTEM_LABELS_SUCCESS: null,
  GET_SYSTEM_LABELS_FAILED: null,

  UPDATE_FILTER_LABEL_REQUEST: null,
  UPDATE_FILTER_LABEL_SUCCESS: null,
  CLEAR_FILTER_LABEL_REQUEST: null,
  CLEAR_FILTER_LABEL_SUCCESS: null
})
