import { combineReducers } from 'redux'
import SubscriptionTypes from 'store/action_types/subscription'
import SubscriptionElectronicKeyTypes from 'store/action_types/subscriptionElectronicKey'

import { createHandleRequest } from './helpers'

const getSubscription = createHandleRequest(
  SubscriptionTypes.GET_SUBSCRIPTIONS_REQUEST,
  SubscriptionTypes.GET_SUBSCRIPTIONS_SUCCESS,
  SubscriptionTypes.GET_SUBSCRIPTIONS_FAILED
)

const getSubscriptionDetail = createHandleRequest(
  SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST,
  SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS,
  SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_FAILED
)

const getSubscriptionElectronicKeys = createHandleRequest(
  SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_REQUEST,
  SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_SUCCESS,
  SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_FAILED
)

const getAllowedPilots = createHandleRequest(
  SubscriptionTypes.GET_ALLOWED_PILOTS_REQUEST,
  SubscriptionTypes.GET_ALLOWED_PILOTS_SUCCESS,
  SubscriptionTypes.GET_ALLOWED_PILOTS_FAILED
)

export default combineReducers({
  getSubscription,
  getSubscriptionElectronicKeys,
  getSubscriptionDetail,
  getAllowedPilots
})
