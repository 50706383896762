import { DEFAULT_CIXI_REFERENT_FILTER } from 'constants/common'
import CixiReferentTypes from 'store/action_types/cixiReferent'
import UserTypes from 'store/action_types/user'

const initCixiReferent = {
  list: [],
  allList: [],
  current: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  filter: DEFAULT_CIXI_REFERENT_FILTER
}

const cixiReferent = (state = initCixiReferent, action) => {
  switch (action.type) {
    case CixiReferentTypes.GET_CIXI_REFERENTS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CixiReferentTypes.GET_ALL_CIXI_REFERENTS_SUCCESS:
      return {
        ...state,
        allList: action.payload?.items
      }
    case CixiReferentTypes.GET_CIXI_REFERENT_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CixiReferentTypes.UPDATE_FILTER_CIXI_REFERENT_SUCCESS:
      return { ...state, filter: action.payload }
    case CixiReferentTypes.CLEAR_FILTER_CIXI_REFERENT_SUCCESS:
      return { ...state, filter: initCixiReferent.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initCixiReferent
    default:
      return state
  }
}

export default cixiReferent
