import TopicTypes from '../action_types/topic'

import { topic as topicApi } from '../api'
import { bindRequestLogic } from './utils'

const getTopics = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.getTopics,
  type: TopicTypes.GET_TOPIC_REQUEST,
  successType: TopicTypes.GET_TOPIC_SUCCESS,
  failType: TopicTypes.GET_TOPIC_FAILED,
  isQueryParam: true
})

const getTopicDetails = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.getTopicDetails,
  type: TopicTypes.GET_TOPIC_DETAIL_REQUEST,
  successType: TopicTypes.GET_TOPIC_DETAIL_SUCCESS,
  failType: TopicTypes.GET_TOPIC_DETAIL_FAILED
})

const addTopic = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.addTopic,
  type: TopicTypes.ADD_TOPIC_REQUEST,
  successType: TopicTypes.ADD_TOPIC_SUCCESS,
  failType: TopicTypes.ADD_TOPIC_FAILED
})

const updateTopic = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.updateTopic,
  type: TopicTypes.UPDATE_TOPIC_REQUEST,
  successType: TopicTypes.UPDATE_TOPIC_SUCCESS,
  failType: TopicTypes.UPDATE_TOPIC_FAILED
})

const deleteTopic = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.deleteTopic,
  type: TopicTypes.DELETE_TOPIC_REQUEST,
  successType: TopicTypes.DELETE_TOPIC_SUCCESS,
  failType: TopicTypes.DELETE_TOPIC_FAILED
})

const addTopicTranslation = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.addTopicTranslation,
  type: TopicTypes.ADD_TOPIC_TRANSLATION_REQUEST,
  successType: TopicTypes.ADD_TOPIC_TRANSLATION_SUCCESS,
  failType: TopicTypes.ADD_TOPIC_TRANSLATION_FAILED
})

const updateTopicTranslation = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.updateTopicTranslation,
  type: TopicTypes.UPDATE_TOPIC_TRANSLATION_REQUEST,
  successType: TopicTypes.UPDATE_TOPIC_TRANSLATION_SUCCESS,
  failType: TopicTypes.UPDATE_TOPIC_TRANSLATION_FAILED
})

const deleteTopicTranslation = bindRequestLogic({
  clientApi: topicApi,
  clientFunc: topicApi.deleteTopicTranslation,
  type: TopicTypes.DELETE_TOPIC_TRANSLATION_REQUEST,
  successType: TopicTypes.DELETE_TOPIC_TRANSLATION_SUCCESS,
  failType: TopicTypes.DELETE_TOPIC_TRANSLATION_FAILED
})

export default [
  getTopics,
  deleteTopic,
  updateTopic,
  addTopic,
  getTopicDetails,
  addTopicTranslation,
  updateTopicTranslation,
  deleteTopicTranslation
]
