import { DEFAULT_LABEL_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import LabelTypes from 'store/action_types/label'
import UserTypes from 'store/action_types/user'
import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'

const initLabel = {
  list: [],
  systemList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_LABEL_FILTER,
  scwFilter: { ...DEFAULT_LABEL_FILTER },
  mobileFilter: { ...DEFAULT_LABEL_FILTER },
  fleetFilter: { ...DEFAULT_LABEL_FILTER }
}

const label = (state = initLabel, action) => {
  switch (action.type) {
    case LabelTypes.GET_LABELS_SUCCESS:
      return {
        ...state,
        list: action?.payload?.items,
        pagination: action.payload?.meta
      }
    case LabelTypes.GET_SYSTEM_LABELS_SUCCESS:
      return { ...state, systemList: action.payload }
    case LabelTypes.UPDATE_FILTER_LABEL_SUCCESS:
      return updateModuleFilter(state, action.payload)
    case LabelTypes.CLEAR_FILTER_LABEL_SUCCESS:
      return clearModuleFilter(state, action.payload, initLabel)
    case UserTypes.LOGOUT_SUCCESS:
      return initLabel
    default:
      return state
  }
}

export default label
