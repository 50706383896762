import {
  DEFAULT_COMPANY_FILTER,
  DEFAULT_COMPANY_SUBSCRIPTIONS_FILTER,
  DEFAULT_COMPANY_VEHICLES_FILTER,
  DEFAULT_PAGINATION,
  DEFAULT_PILOTS_FILTER
} from 'constants/common'
import { getUniqueItem } from 'helpers/Utils'
import CompanyTypes from 'store/action_types/company'
import UserTypes from 'store/action_types/user'

const initCompany = {
  list: [],
  activeList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  members: {
    list: [],
    pagination: DEFAULT_PAGINATION
  },
  childrenCompanies: {
    list: [],
    pagination: DEFAULT_PAGINATION
  },
  pilots: {
    list: [],
    pagination: DEFAULT_PAGINATION,
    filter: DEFAULT_PILOTS_FILTER
  },
  companyMembers: [],
  companyVehicles: {
    list: [],
    filter: DEFAULT_COMPANY_VEHICLES_FILTER
  },
  companySubscriptions: {
    list: [],
    filter: DEFAULT_COMPANY_SUBSCRIPTIONS_FILTER
  },
  filter: DEFAULT_COMPANY_FILTER
}

const company = (state = initCompany, action) => {
  switch (action.type) {
    case CompanyTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }

    case CompanyTypes.GET_COMPANY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }

    // ======== COMPANY MEMBERS =========
    case CompanyTypes.GET_COMPANY_MEMBERS_SUCCESS:
      if (action.payload.meta.currentPage === 1) {
        return {
          ...state,
          members: {
            list: action.payload?.items,
            pagination: action.payload?.meta
          }
        }
      }
      return {
        ...state,
        members: {
          list: getUniqueItem([
            ...state?.members?.list,
            ...action.payload?.items
          ]),
          pagination: action.payload?.meta
        }
      }

    // ======== CHILDREN COMPANIES =========
    case CompanyTypes.GET_CHILDREN_COMPANIES_SUCCESS:
      if (action.payload.meta.currentPage === 1) {
        return {
          ...state,
          childrenCompanies: {
            list: action.payload?.items,
            pagination: action.payload?.meta
          }
        }
      }
      return {
        ...state,
        childrenCompanies: {
          list: getUniqueItem([
            ...state.childrenCompanies.list,
            ...action.payload?.items
          ]),
          pagination: action.payload?.meta
        }
      }
    case CompanyTypes.UPDATE_FILTER_COMPANY_SUCCESS:
      return { ...state, filter: action.payload }
    case CompanyTypes.CLEAR_FILTER_COMPANY_SUCCESS:
      return { ...state, filter: initCompany.filter }

    // ======== COMPANY PILOTS =========
    case CompanyTypes.GET_COMPANY_PILOTS_SUCCESS:
      return {
        ...state,
        pilots: {
          list: getUniqueItem([
            ...state?.pilots?.list,
            ...action.payload?.items
          ]),
          pagination: action.payload?.meta,
          filter: state?.pilots?.filter ?? DEFAULT_PILOTS_FILTER
        }
      }
    case CompanyTypes.UPDATE_FILTER_COMPANY_PILOTS_SUCCESS:
      return {
        ...state,
        pilots: {
          ...state?.pilots,
          list: initCompany.pilots.list,
          filter: action.payload ?? DEFAULT_PILOTS_FILTER
        }
      }
    case CompanyTypes.CLEAR_FILTER_COMPANY_PILOTS_SUCCESS:
      return {
        ...state,
        pilots: {
          ...state?.pilots,
          list: initCompany.pilots.list,
          filter: DEFAULT_PILOTS_FILTER
        }
      }

    // ======== COMPANY VEHICLES =========
    case CompanyTypes.GET_COMPANY_VEHICLES_SUCCESS:
      return {
        ...state,
        companyVehicles: {
          ...state.companyVehicles,
          list: action.payload?.items
        }
      }
    case CompanyTypes.UPDATE_FILTER_COMPANY_VEHICLES_SUCCESS:
      return {
        ...state,
        companyVehicles: { ...state.companyVehicles, filter: action.payload }
      }
    case CompanyTypes.CLEAR_FILTER_COMPANY_VEHICLES_SUCCESS:
      return {
        ...state,
        companyVehicles: {
          ...state.companyVehicles,
          filter: initCompany.companyVehicles.filter
        }
      }

    // ======== COMPANY SUBSCRIPTIONS =========
    case CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        companySubscriptions: {
          ...state.companySubscriptions,
          list: action.payload?.items
        }
      }
    case CompanyTypes.UPDATE_FILTER_COMPANY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        companySubscriptions: {
          ...state.companySubscriptions,
          filter: action.payload
        }
      }
    case CompanyTypes.CLEAR_FILTER_COMPANY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        companySubscriptions: {
          ...state.companySubscriptions,
          filter: initCompany.companySubscriptions.filter
        }
      }

    case UserTypes.LOGOUT_SUCCESS:
      return initCompany
    default:
      return state
  }
}

export default company
