import { combineReducers } from 'redux'
import DateTimeFormatTypes from 'store/action_types/dateTimeFormat'
import { createHandleRequest } from './helpers'

const getDateTimeFormats = createHandleRequest(
  DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_REQUEST,
  DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_SUCCESS,
  DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_FAILED
)

export default combineReducers({
  getDateTimeFormats
})
