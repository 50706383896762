import VehicleBrandTypes from 'store/action_types/vehicleBrand'
import UserTypes from 'store/action_types/user'

const initVehicleBrand = {
  list: [],
  current: {},
  messages: [],
  pagination: {
    currentPage: 1,
    totalPages: 1
  }
}

const vehicleBrand = (state = initVehicleBrand, action) => {
  switch (action.type) {
    case VehicleBrandTypes.GET_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case VehicleBrandTypes.GET_VEHICLE_BRAND_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case VehicleBrandTypes.UPDATE_FILTER_VEHICLE_BRAND_SUCCESS:
      return { ...state, filter: action.payload }
    case VehicleBrandTypes.CLEAR_FILTER_VEHICLE_BRAND_SUCCESS:
      return { ...state, filter: initVehicleBrand.filter }
    case VehicleBrandTypes.GET_VEHICLE_BRAND_MESSAGES_SUCCESS:
      return { ...state, messages: action.payload?.items || [] }
    case UserTypes.LOGOUT_SUCCESS:
      return initVehicleBrand
    default:
      return state
  }
}

export default vehicleBrand
