import keyMirror from 'keymirror'

export default keyMirror({
  GET_VEHICLE_BRANDS_REQUEST: null,
  GET_VEHICLE_BRANDS_SUCCESS: null,
  GET_VEHICLE_BRANDS_FAILED: null,

  ADD_VEHICLE_BRAND_REQUEST: null,
  ADD_VEHICLE_BRAND_SUCCESS: null,
  ADD_VEHICLE_BRAND_FAILED: null,

  GET_VEHICLE_BRAND_DETAIL_REQUEST: null,
  GET_VEHICLE_BRAND_DETAIL_SUCCESS: null,
  GET_VEHICLE_BRAND_DETAIL_FAILED: null,

  UPDATE_VEHICLE_BRAND_REQUEST: null,
  UPDATE_VEHICLE_BRAND_SUCCESS: null,
  UPDATE_VEHICLE_BRAND_FAILED: null,

  DELETE_VEHICLE_BRAND_REQUEST: null,
  DELETE_VEHICLE_BRAND_SUCCESS: null,
  DELETE_VEHICLE_BRAND_FAILED: null,

  UPDATE_FILTER_VEHICLE_BRAND_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_BRAND_SUCCESS: null,

  // ===== VEHICLE BRAND MESSAGE =====
  GET_VEHICLE_BRAND_MESSAGES_REQUEST: null,
  GET_VEHICLE_BRAND_MESSAGES_SUCCESS: null,
  GET_VEHICLE_BRAND_MESSAGES_FAILED: null,

  ADD_VEHICLE_BRAND_MESSAGE_REQUEST: null,
  ADD_VEHICLE_BRAND_MESSAGE_SUCCESS: null,
  ADD_VEHICLE_BRAND_MESSAGE_FAILED: null,

  UPDATE_VEHICLE_BRAND_MESSAGE_REQUEST: null,
  UPDATE_VEHICLE_BRAND_MESSAGE_SUCCESS: null,
  UPDATE_VEHICLE_BRAND_MESSAGE_FAILED: null,

  DELETE_VEHICLE_BRAND_MESSAGE_REQUEST: null,
  DELETE_VEHICLE_BRAND_MESSAGE_SUCCESS: null,
  DELETE_VEHICLE_BRAND_MESSAGE_FAILED: null
})
