import MailTemplateTypes from '../action_types/mailTemplate'

import { mailTemplate as mailTemplateApi } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getMailTemplates = bindRequestLogic({
  clientApi: mailTemplateApi,
  clientFunc: mailTemplateApi.getMailTemplates,
  type: MailTemplateTypes.GET_MAIL_TEMPLATES_REQUEST,
  successType: MailTemplateTypes.GET_MAIL_TEMPLATES_SUCCESS,
  failType: MailTemplateTypes.GET_MAIL_TEMPLATES_FAILED,
  isQueryParam: true,
  modelName: 'mailTemplates'
})

const addMailTemplate = bindRequestLogic({
  clientFunc: mailTemplateApi.addMailTemplate,
  type: MailTemplateTypes.ADD_MAIL_TEMPLATE_REQUEST,
  successType: MailTemplateTypes.ADD_MAIL_TEMPLATE_SUCCESS,
  failType: MailTemplateTypes.ADD_MAIL_TEMPLATE_FAILED
})

const updateMailTemplate = bindRequestLogic({
  clientFunc: mailTemplateApi.updateMailTemplate,
  type: MailTemplateTypes.UPDATE_MAIL_TEMPLATE_REQUEST,
  successType: MailTemplateTypes.UPDATE_MAIL_TEMPLATE_SUCCESS,
  failType: MailTemplateTypes.UPDATE_MAIL_TEMPLATE_FAILED
})

const getMailTemplate = bindRequestLogic({
  clientApi: mailTemplateApi,
  clientFunc: mailTemplateApi.getMailTemplateDetail,
  type: MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_REQUEST,
  successType: MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_SUCCESS,
  failType: MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_FAILED,
  modelName: 'mailTemplate'
})

const deleteMailTemplate = bindRequestLogic({
  clientFunc: mailTemplateApi.deleteMailTemplate,
  type: MailTemplateTypes.DELETE_MAIL_TEMPLATE_REQUEST,
  successType: MailTemplateTypes.DELETE_MAIL_TEMPLATE_SUCCESS,
  failType: MailTemplateTypes.DELETE_MAIL_TEMPLATE_FAILED
})

const upsertMailTemplateTranslation = bindRequestLogic({
  clientFunc: mailTemplateApi.upsertMailTemplateTranslation,
  type: MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_REQUEST,
  successType: MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_SUCCESS,
  failType: MailTemplateTypes.UPSERT_MAIL_TEMPLATE_TRANSLATION_FAILED
})

const getMailTemplateTokens = bindRequestLogic({
  clientApi: mailTemplateApi,
  clientFunc: mailTemplateApi.getMailTemplateTokens,
  type: MailTemplateTypes.GET_MAIL_TEMPLATE_TOKENS_REQUEST,
  successType: MailTemplateTypes.GET_MAIL_TEMPLATE_TOKENS_SUCCESS,
  failType: MailTemplateTypes.GET_MAIL_TEMPLATE_TOKENS_FAILED,
  isQueryParam: true,
  modelName: 'tokens'
})

const updateFilterMailTemplate = bindFilterLogic({
  type: MailTemplateTypes.UPDATE_FILTER_MAIL_TEMPLATE_REQUEST,
  successType: MailTemplateTypes.UPDATE_FILTER_MAIL_TEMPLATE_SUCCESS
})

const clearFilterMailTemplate = bindFilterLogic({
  type: MailTemplateTypes.CLEAR_FILTER_MAIL_TEMPLATE_REQUEST,
  successType: MailTemplateTypes.CLEAR_FILTER_MAIL_TEMPLATE_SUCCESS
})

export default [
  getMailTemplates,
  addMailTemplate,
  updateMailTemplate,
  getMailTemplate,
  deleteMailTemplate,
  upsertMailTemplateTranslation,
  getMailTemplateTokens,
  updateFilterMailTemplate,
  clearFilterMailTemplate
]
