import DateTimeFormatTypes from '../action_types/dateTimeFormat'

import { dateTimeFormat as dateTimeFormatApi } from '../api'
import { bindRequestLogic } from './utils'

const updateDateTimeFormat = bindRequestLogic({
  clientApi: dateTimeFormatApi,
  clientFunc: dateTimeFormatApi.updateDateTimeFormat,
  type: DateTimeFormatTypes.UPDATE_DATETIME_FORMAT_REQUEST,
  successType: DateTimeFormatTypes.UPDATE_DATETIME_FORMAT_SUCCESS,
  failType: DateTimeFormatTypes.UPDATE_DATETIME_FORMAT_FAILED
})

const getDateTimeFormatsByLanguage = bindRequestLogic({
  clientApi: dateTimeFormatApi,
  clientFunc: dateTimeFormatApi.getDateTimeFormatsByLanguage,
  type: DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_REQUEST,
  successType: DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_SUCCESS,
  failType: DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_FAILED,
  isQueryParam: true,
  modelName: 'dateTimeConfigs'
})

export default [getDateTimeFormatsByLanguage, updateDateTimeFormat]
