import PlatformTech from '../action_types/platformTech'

import { platformTech as platformTechApi } from '../api'
import { bindRequestLogic } from './utils'

const getPlatformTechs = bindRequestLogic({
  clientApi: platformTechApi,
  clientFunc: platformTechApi.getPlatformTechs,
  type: PlatformTech.GET_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.GET_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.GET_PLATFORM_TECH_FAILED,
  isQueryParam: true
})

const getPlatformTech = bindRequestLogic({
  clientApi: platformTechApi,
  clientFunc: platformTechApi.getPlatformTech,
  type: PlatformTech.GET_PLATFORM_TECH_DETAIL_REQUEST,
  successType: PlatformTech.GET_PLATFORM_TECH_DETAIL_SUCCESS,
  failType: PlatformTech.GET_PLATFORM_TECH_DETAIL_FAILED
})

const addPlatformTech = bindRequestLogic({
  clientApi: platformTechApi,
  clientFunc: platformTechApi.addPlatformTech,
  type: PlatformTech.ADD_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.ADD_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.ADD_PLATFORM_TECH_FAILED
})

const updatePlatformTech = bindRequestLogic({
  clientApi: platformTechApi,
  clientFunc: platformTechApi.updatePlatformTech,
  type: PlatformTech.UPDATE_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.UPDATE_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.UPDATE_PLATFORM_TECH_FAILED
})

export default [
  getPlatformTechs,
  addPlatformTech,
  updatePlatformTech,
  getPlatformTech
]
