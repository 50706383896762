import { DEFAULT_PAGINATION, DEFAULT_USER_FILTER } from 'constants/common'
import UserTypes from 'store/action_types/user'

const initUser = {
  info: {},
  error: null,
  accessToken: null,
  list: [],
  pagination: DEFAULT_PAGINATION,
  current: {},
  isoCode: 'eng',
  filter: DEFAULT_USER_FILTER
}

const user = (state = initUser, action) => {
  switch (action.type) {
    case UserTypes.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken
      }
    case UserTypes.LOGIN_FAILURE:
      return { ...state, error: action.payload }
    case UserTypes.LOGOUT_FAILURE:
    case UserTypes.LOGOUT_SUCCESS:
      return initUser
    case UserTypes.FETCH_PROFILE_SUCCESS:
      return { ...state, info: action.payload }
    case UserTypes.FETCH_PROFILE_FAILURE:
      return initUser
    case UserTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case UserTypes.GET_USER_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case UserTypes.UPDATE_DEFAULT_LANGUAGE:
      return { ...state, isoCode: action.payload }
    case UserTypes.UPDATE_FILTER_USER_SUCCESS:
      return { ...state, filter: action.payload }
    case UserTypes.CLEAR_FILTER_USER_SUCCESS:
      return { ...state, filter: initUser.filter }
    default:
      return state
  }
}

export default user
