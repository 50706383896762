import user from './user'
import storage from './storage'
import language from './language'
import country from './country'
import appUser from './app-user'
import vehicle from './vehicle'
import timezone from './timezone'
import labels from './label'
import texts from './text'
import systemConfig from './systemConfig'
import dateTimeFormat from './dateTimeFormat'
import logMail from './logMail'
import logRoutine from './logRoutine'
import role from './role'
import modelPer from './modelPer'
import cixiReferent from './cixiReferent'
import vehicleModel from './vehicleModel'
import message from './message'
import electronicKeyModel from './electronicKeyModel'
import company from './company'
import electronicKey from './electronicKey'
import activity from './activity'
import vehicleElectronicKey from './vehicleElectronicKey'
import rolesConfiguration from './rolesConfiguration'
import formattedText from './formattedText'
import subscription from './subscription'
import showcaseSection from './showcaseSection'
import showcaseComponent from './showcaseComponent'
import subscriptionElectronicKey from './subscriptionElectronicKey'
import showcaseStory from './showcaseStory'
import mailTemplate from './mailTemplate'
import vehicleBrand from './vehicleBrand'
import uploadMedia from './uploadMedia'
import showcasePrivacyPolicy from './showcasePrivacyPolicy'
import showcaseContactLog from './showcaseContactLog'
import platformTech from './platformTech'
import portal from './portal'
import appVersions from './appVersions'
import messageAfterConnection from './messageAfterConnection'
import faq from './faq'
import topic from './topic'
import currency from './currency'
import conditionalFeature from './conditionalFeature'
import showcaseAnonymizeVisitor from './showcaseAnonymizeVisitor'

export default [
  ...topic,
  ...user,
  ...storage,
  ...conditionalFeature,
  ...language,
  ...country,
  ...appUser,
  ...vehicle,
  ...timezone,
  ...systemConfig,
  ...dateTimeFormat,
  ...logMail,
  ...logRoutine,
  ...labels,
  ...texts,
  ...role,
  ...modelPer,
  ...cixiReferent,
  ...vehicleModel,
  ...message,
  ...electronicKeyModel,
  ...company,
  ...electronicKey,
  ...activity,
  ...vehicleElectronicKey,
  ...rolesConfiguration,
  ...formattedText,
  ...subscription,
  ...showcaseSection,
  ...showcaseComponent,
  ...subscriptionElectronicKey,
  ...showcaseStory,
  ...mailTemplate,
  ...vehicleBrand,
  ...uploadMedia,
  ...showcasePrivacyPolicy,
  ...showcaseContactLog,
  ...platformTech,
  ...portal,
  ...appVersions,
  ...messageAfterConnection,
  ...faq,
  ...currency,
  ...showcaseAnonymizeVisitor
]
