import LanguageTypes from '../action_types/language'

import { language as languageApi } from '../api'
import { bindRequestLogic } from './utils'

const getLanguages = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.getLanguages,
  type: LanguageTypes.GET_LANGUAGES_REQUEST,
  successType: LanguageTypes.GET_LANGUAGES_SUCCESS,
  failType: LanguageTypes.GET_LANGUAGES_FAILED,
  isQueryParam: true,
  modelName: 'languages'
})

const addLanguage = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.addLanguage,
  type: LanguageTypes.ADD_LANGUAGE_REQUEST,
  successType: LanguageTypes.ADD_LANGUAGE_SUCCESS,
  failType: LanguageTypes.ADD_LANGUAGE_FAILED
})

const getLanguageDetail = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.getLanguageDetail,
  type: LanguageTypes.GET_LANGUAGE_DETAIL_REQUEST,
  successType: LanguageTypes.GET_LANGUAGE_DETAIL_SUCCESS,
  failType: LanguageTypes.GET_LANGUAGE_DETAIL_FAILED,
  modelName: 'language'
})

const updateLanguage = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.updateLanguage,
  type: LanguageTypes.UPDATE_LANGUAGE_REQUEST,
  successType: LanguageTypes.UPDATE_LANGUAGE_SUCCESS,
  failType: LanguageTypes.UPDATE_LANGUAGE_FAILED
})

const deleteLanguage = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.deleteLanguage,
  type: LanguageTypes.DELETE_LANGUAGE_REQUEST,
  successType: LanguageTypes.DELETE_LANGUAGE_SUCCESS,
  failType: LanguageTypes.DELETE_LANGUAGE_FAILED
})

const addLanguageTranslation = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.addLanguageTranslation,
  type: LanguageTypes.ADD_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.ADD_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.ADD_LANGUAGE_TRANSLATION_FAILED
})

const updateLanguageTranslation = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.updateLanguageTranslation,
  type: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_FAILED
})

const deleteLanguageTranslation = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.deleteLanguageTranslation,
  type: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_FAILED
})

const getActiveLanguages = bindRequestLogic({
  clientApi: languageApi,
  clientFunc: languageApi.getActiveLanguages,
  type: LanguageTypes.GET_ACTIVE_LANGUAGES_REQUEST,
  successType: LanguageTypes.GET_ACTIVE_LANGUAGES_SUCCESS,
  failType: LanguageTypes.GET_ACTIVE_LANGUAGES_FAILED,
  isQueryParam: true,
  modelName: 'languages'
})

export default [
  getLanguages,
  addLanguage,
  getLanguageDetail,
  updateLanguage,
  deleteLanguage,
  addLanguageTranslation,
  deleteLanguageTranslation,
  getActiveLanguages,
  updateLanguageTranslation
]
