import keyMirror from 'keymirror'

export default keyMirror({
  GET_SYSTEM_CONFIG_LIST_REQUEST: null,
  GET_SYSTEM_CONFIG_LIST_SUCCESS: null,
  GET_SYSTEM_CONFIG_LIST_FAILED: null,

  UPDATE_SYSTEMS_CONFIG_REQUEST: null,
  UPDATE_SYSTEMS_CONFIG_SUCCESS: null,
  UPDATE_SYSTEMS_CONFIG_FAILED: null,

  GET_MODULES_REQUEST: null,
  GET_MODULES_SUCCESS: null,
  GET_MODULES_FAILED: null,

  GET_ALL_VEHICLE_BRANDS_REQUEST: null,
  GET_ALL_VEHICLE_BRANDS_SUCCESS: null,
  GET_ALL_VEHICLE_BRANDS_FAILED: null,

  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_REQUEST: null,
  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_SUCCESS: null,
  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_FAILED: null,

  UPDATE_CURRENT_MODULES_SUCCESS: null
})
