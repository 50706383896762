import TextTypes from '../action_types/text'
import { text as textApi } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getTexts = bindRequestLogic({
  clientApi: textApi,
  clientFunc: textApi.getTexts,
  type: TextTypes.GET_TEXTS_REQUEST,
  successType: TextTypes.GET_TEXTS_SUCCESS,
  failType: TextTypes.GET_TEXTS_FAILED,
  isQueryParam: true
})

const createText = bindRequestLogic({
  clientApi: textApi,
  clientFunc: textApi.createText,
  type: TextTypes.CREATE_TEXT_REQUEST,
  successType: TextTypes.CREATE_TEXT_SUCCESS,
  failType: TextTypes.CREATE_TEXT_FAILED
})

const deleteText = bindRequestLogic({
  clientApi: textApi,
  clientFunc: textApi.deleteText,
  type: TextTypes.DELETE_TEXT_REQUEST,
  successType: TextTypes.DELETE_TEXT_SUCCESS,
  failType: TextTypes.DELETE_TEXT_FAILED
})

const updateTexts = bindRequestLogic({
  clientApi: textApi,
  clientFunc: textApi.updateTexts,
  type: TextTypes.UPDATE_TEXTS_REQUEST,
  successType: TextTypes.UPDATE_TEXTS_SUCCESS,
  failType: TextTypes.UPDATE_TEXTS_FAILED
})

const updateFilterText = bindFilterLogic({
  type: TextTypes.UPDATE_FILTER_TEXT_REQUEST,
  successType: TextTypes.UPDATE_FILTER_TEXT_SUCCESS
})

const clearFilterText = bindFilterLogic({
  type: TextTypes.CLEAR_FILTER_TEXT_REQUEST,
  successType: TextTypes.CLEAR_FILTER_TEXT_SUCCESS
})

export default [
  getTexts,
  createText,
  deleteText,
  updateTexts,
  updateFilterText,
  clearFilterText
]
