import keyMirror from 'keymirror'

export default keyMirror({
  GET_PLATFORM_TECH_REQUEST: null,
  GET_PLATFORM_TECH_SUCCESS: null,
  GET_PLATFORM_TECH_FAILED: null,

  GET_PLATFORM_TECH_DETAIL_REQUEST: null,
  GET_PLATFORM_TECH_DETAIL_SUCCESS: null,
  GET_PLATFORM_TECH_DETAIL_FAILED: null,

  ADD_PLATFORM_TECH_REQUEST: null,
  ADD_PLATFORM_TECH_SUCCESS: null,
  ADD_PLATFORM_TECH_FAILED: null,

  UPDATE_PLATFORM_TECH_REQUEST: null,
  UPDATE_PLATFORM_TECH_SUCCESS: null,
  UPDATE_PLATFORM_TECH_FAILED: null
})
