import CompanyTypes from '../action_types/company'

import { company as companyApi } from '../api'
import { bindRequestLogic } from './utils'

const getCompanies = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanies,
  type: CompanyTypes.GET_COMPANIES_REQUEST,
  successType: CompanyTypes.GET_COMPANIES_SUCCESS,
  failType: CompanyTypes.GET_COMPANIES_FAILED,
  isQueryParam: true
})

const addCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.addCompany,
  type: CompanyTypes.ADD_COMPANY_REQUEST,
  successType: CompanyTypes.ADD_COMPANY_SUCCESS,
  failType: CompanyTypes.ADD_COMPANY_FAILED
})

const updateCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.updateCompany,
  type: CompanyTypes.UPDATE_COMPANY_REQUEST,
  successType: CompanyTypes.UPDATE_COMPANY_SUCCESS,
  failType: CompanyTypes.UPDATE_COMPANY_FAILED
})

const getCompanyDetail = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanyDetail,
  type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
  successType: CompanyTypes.GET_COMPANY_DETAIL_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_DETAIL_FAILED,
  modelName: 'company'
})

const deleteCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.deleteCompany,
  type: CompanyTypes.DELETE_COMPANY_REQUEST,
  successType: CompanyTypes.DELETE_COMPANY_SUCCESS,
  failType: CompanyTypes.DELETE_COMPANY_FAILED
})

const updateSheetAdditional = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.updateSheetAdditional,
  type: CompanyTypes.UPDATE_SHEET_ADDITIONAL_REQUEST,
  successType: CompanyTypes.UPDATE_SHEET_ADDITIONAL_SUCCESS,
  failType: CompanyTypes.UPDATE_SHEET_ADDITIONAL_FAILED
})

const convertToMotherCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.convertToMotherCompany,
  type: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_REQUEST,
  successType: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_SUCCESS,
  failType: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_FAILED
})

const getCompanyMembers = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanyMembers,
  type: CompanyTypes.GET_COMPANY_MEMBERS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_MEMBERS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_MEMBERS_FAILED,
  isQueryParam: true
})

const addCompanyMember = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.addCompanyMember,
  type: CompanyTypes.ADD_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.ADD_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.ADD_COMPANY_MEMBER_FAILED
})

const updateCompanyMember = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.updateCompanyMember,
  type: CompanyTypes.UPDATE_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.UPDATE_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.UPDATE_COMPANY_MEMBER_FAILED
})

const getCompanyPilots = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanyPilots,
  type: CompanyTypes.GET_COMPANY_PILOTS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_PILOTS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_PILOTS_FAILED,
  isQueryParam: true
})

const deleteCompanyMember = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.deleteCompanyMember,
  type: CompanyTypes.DELETE_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.DELETE_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.DELETE_COMPANY_MEMBER_FAILED
})

const getChildrenCompanies = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getChildrenCompanies,
  type: CompanyTypes.GET_CHILDREN_COMPANIES_REQUEST,
  successType: CompanyTypes.GET_CHILDREN_COMPANIES_SUCCESS,
  failType: CompanyTypes.GET_CHILDREN_COMPANIES_FAILED,
  isQueryParam: true
})

const addChildrenCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.addChildrenCompany,
  type: CompanyTypes.ADD_CHILDREN_COMPANY_REQUEST,
  successType: CompanyTypes.ADD_CHILDREN_COMPANY_SUCCESS,
  failType: CompanyTypes.ADD_CHILDREN_COMPANY_FAILED
})

const deleteChildrenCompany = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.deleteChildrenCompany,
  type: CompanyTypes.DELETE_CHILDREN_COMPANY_REQUEST,
  successType: CompanyTypes.DELETE_CHILDREN_COMPANY_SUCCESS,
  failType: CompanyTypes.DELETE_CHILDREN_COMPANY_FAILED
})

const getCompanySheetVehicle = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanyVehicles,
  type: CompanyTypes.GET_COMPANY_VEHICLES_REQUEST,
  successType: CompanyTypes.GET_COMPANY_VEHICLES_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_VEHICLES_FAILED,
  isQueryParam: true
})

const getCompanySubscriptions = bindRequestLogic({
  clientApi: companyApi,
  clientFunc: companyApi.getCompanySubscription,
  type: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_FAILED,
  isQueryParam: true
})

export default [
  getCompanies,
  addCompany,
  getCompanyDetail,
  deleteCompany,
  updateCompany,
  updateSheetAdditional,
  addCompanyMember,
  deleteCompanyMember,
  updateCompanyMember,
  getCompanyMembers,
  getCompanyPilots,
  convertToMotherCompany,
  getChildrenCompanies,
  addChildrenCompany,
  deleteChildrenCompany,
  getCompanySheetVehicle,
  getCompanySubscriptions
]
