import VehicleTypes from 'store/action_types/vehicle'
import UserTypes from 'store/action_types/user'
import {
  DEFAULT_PAGINATION,
  DEFAULT_VEHICLE_FILTER,
  DEFAULT_VEHICLE_SUBSCRIPTION_FILTER,
  DEFAULT_VEHICLE_USERS_FILTER
} from 'constants/common'
import { getUniqueItem, replaceValueAtPath } from 'helpers/Utils'
import VehicleElectronicKeyTypes from 'store/action_types/vehicleElectronicKey'

const initVehicle = {
  info: {},
  error: null,
  list: [],
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_VEHICLE_FILTER,
  current: {},
  electronicKeys: {
    list: [],
    pagination: DEFAULT_PAGINATION
  },
  subscriptions: {
    list: [],
    filter: DEFAULT_VEHICLE_SUBSCRIPTION_FILTER
  },
  users: {
    list: [],
    filter: DEFAULT_VEHICLE_USERS_FILTER
  }
}

const vehicle = (state = initVehicle, action) => {
  switch (action.type) {
    case VehicleTypes.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case VehicleTypes.GET_VEHICLE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case VehicleTypes.UPDATE_FILTER_VEHICLE_SUCCESS:
      return { ...state, filter: action.payload }
    case VehicleTypes.CLEAR_FILTER_VEHICLE_SUCCESS:
      return { ...state, filter: initVehicle.filter }
    case VehicleElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_SUCCESS:
      if (action.payload.meta.currentPage === 1) {
        return {
          ...state,
          electronicKeys: {
            list: action.payload?.items,
            pagination: action.payload?.meta
          }
        }
      }
      return {
        ...state,
        electronicKeys: {
          list: getUniqueItem([
            ...state.electronicKeys.list,
            ...action.payload?.items
          ]),
          pagination: action.payload?.meta
        }
      }
    //   sheet subscriptions
    case VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_SUCCESS: {
      return replaceValueAtPath(
        state,
        'subscriptions.list',
        action.payload || []
      )
    }
    case VehicleTypes.UPDATE_FILTER_VEHICLE_SUBSCRIPTIONS_SUCCESS: {
      return replaceValueAtPath(state, 'subscriptions.filter', action.payload)
    }
    case VehicleTypes.CLEAR_FILTER_VEHICLE_SUBSCRIPTIONS_SUCCESS: {
      return replaceValueAtPath(
        state,
        'subscriptions.filter',
        DEFAULT_VEHICLE_SUBSCRIPTION_FILTER
      )
    }
    // sheet users
    case VehicleTypes.GET_VEHICLE_USERS_SUCCESS: {
      return replaceValueAtPath(state, 'users.list', action.payload)
    }
    case VehicleTypes.UPDATE_FILTER_VEHICLE_USERS_SUCCESS: {
      return replaceValueAtPath(state, 'users.filter', action.payload)
    }
    case UserTypes.LOGOUT_SUCCESS:
      return initVehicle
    default:
      return state
  }
}

export default vehicle
