import SubscriptionTypes from '../action_types/subscription'
import { subscription as subscriptionApi } from '../api'
import { bindRequestLogic } from './utils'

const getSubscriptions = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.getSubscriptions,
  type: SubscriptionTypes.GET_SUBSCRIPTIONS_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTIONS_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTIONS_FAILED,
  isQueryParam: true
})

const addSubscription = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.addSubscription,
  type: SubscriptionTypes.ADD_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.ADD_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.ADD_SUBSCRIPTION_FAILED
})

const updateSubscription = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.updateSubscription,
  type: SubscriptionTypes.UPDATE_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.UPDATE_SUBSCRIPTION_FAILED
})

const getSubscriptionDetail = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.getSubscriptionDetail,
  type: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_FAILED
})

const deleteSubscription = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.deleteSubscription,
  type: SubscriptionTypes.DELETE_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.DELETE_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.DELETE_SUBSCRIPTION_FAILED
})

const getVehicles = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.getVehicles,
  type: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_FAILED,
  isQueryParam: true
})

const addVehicle = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.addVehicle,
  type: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_FAILED
})

const updateVehicle = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.updateVehicle,
  type: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_FAILED
})

const deleteVehicle = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.deleteVehicle,
  type: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_FAILED
})

// ================ ALLOWED PILOT =============
const mapResponse = (res) => {
  const items = res?.subscriptionAllowPilots?.items

  if (!items) return []

  return items.map((item) => ({
    id: item.id,
    status: item.status,
    profile: item.subscriptionProfilePilot,
    userId: item.userId,
    email: item.emailMember,
    phone: item.user?.phone || '',
    name: item.user?.name || '',
    avatar: item.user?.avatar || ''
  }))
}

const getAllowedPilots = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.getAllowedPilots,
  type: SubscriptionTypes.GET_ALLOWED_PILOTS_REQUEST,
  successType: SubscriptionTypes.GET_ALLOWED_PILOTS_SUCCESS,
  failType: SubscriptionTypes.GET_ALLOWED_PILOTS_FAILED,
  isQueryParam: true,
  mapResponse
})

const addAllowedPilot = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.addAllowedPilot,
  type: SubscriptionTypes.ADD_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.ADD_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.ADD_ALLOWED_PILOT_FAILED
})

const updateAllowedPilot = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.updateAllowedPilot,
  type: SubscriptionTypes.UPDATE_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.UPDATE_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.UPDATE_ALLOWED_PILOT_FAILED
})

const deleteAllowedPilot = bindRequestLogic({
  clientApi: subscriptionApi,
  clientFunc: subscriptionApi.deleteAllowedPilot,
  type: SubscriptionTypes.DELETE_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.DELETE_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.DELETE_ALLOWED_PILOT_FAILED
})

export default [
  getSubscriptions,
  addSubscription,
  getSubscriptionDetail,
  deleteSubscription,
  updateSubscription,
  getVehicles,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  getAllowedPilots,
  addAllowedPilot,
  updateAllowedPilot,
  deleteAllowedPilot
]
