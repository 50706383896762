import React, { useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Tooltip } from 'reactstrap'
import symbolTooltip from 'assets/img/symbol-tooltip.png'
import { PropTypes } from 'prop-types'

const getTranslationBy = (keyword, keyOfObjectTranslation, list = []) => {
  const translation = list.find((item) => item?.keyword === keyword)
  if (translation?.translations[0]?.[keyOfObjectTranslation]) {
    return translation.translations[0]?.[keyOfObjectTranslation]
  }
  return translation?.[keyOfObjectTranslation]
}

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

function InjectMassage(props) {
  const { intl, id, systemLabels, hiddenHelpText, placementHelpText } = props

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const helpText = useMemo(() => {
    return (
      getTranslationBy(id, 'helpText', systemLabels) ||
      intl?.messages[id]?.label
    )
  }, [id, systemLabels, intl?.messages])

  const label = useMemo(() => {
    return (
      getTranslationBy(id, 'originalName', systemLabels) ||
      intl?.messages[id]?.name ||
      id
    )
  }, [id, systemLabels, intl?.messages])

  const tooltipId = useMemo(() => {
    const newId = id.split('.').join('-')
    return `tooltip-${newId}-${uuidv4()}`
  }, [id])

  return (
    <span className='tooltip-help-text'>
      <span color='secondary' className='inline-block'>
        {label}
      </span>
      {(() => {
        if (!helpText || hiddenHelpText) {
          return <></>
        }
        switch (placementHelpText) {
          case 'bottom':
            return (
              <div className='size-regular italic white-space-pre-wrap'>
                {helpText}
              </div>
            )

          default:
            return (
              <>
                &nbsp;
                <img
                  className='align-middle -mt-1'
                  src={symbolTooltip}
                  width={16}
                  height={16}
                  alt=''
                  id={tooltipId}
                />
                <Tooltip
                  container='body'
                  trigger='hover'
                  placement='top'
                  isOpen={tooltipOpen}
                  target={tooltipId}
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                >
                  {helpText}
                </Tooltip>
              </>
            )
        }
      })()}
    </span>
  )
}

InjectMassage.defaultProps = {
  placementHelpText: 'left', // bottom | left
  hiddenHelpText: false,
  systemLabels: [],
  id: ''
}

InjectMassage.propTypes = {
  id: PropTypes.string,
  placementHelpText: PropTypes.string,
  hiddenHelpText: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  intl: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  systemLabels: PropTypes.array
}

export default injectIntl(InjectMassage, { forwardRef: true })
