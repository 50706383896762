import ActivityTypes from '../action_types/activity'

import { activity as activityApi } from '../api'
import { bindRequestLogic } from './utils'

const getElectronicActivities = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_ELECTRONIC_KEY_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_ELECTRONIC_KEY_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_ELECTRONIC_KEY_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const getCompanyActivities = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_COMPANY_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_COMPANY_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_COMPANY_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const getVehicleActivities = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_VEHICLE_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_VEHICLE_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_VEHICLE_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const getAppUserActivities = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_APP_USER_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_APP_USER_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_APP_USER_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const getSubscriptionActivities = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_SUBSCRIPTION_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_SUBSCRIPTION_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_SUBSCRIPTION_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const addActivity = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.addActivity,
  type: ActivityTypes.ADD_ACTIVITY_REQUEST,
  successType: ActivityTypes.ADD_ACTIVITY_SUCCESS,
  failType: ActivityTypes.ADD_ACTIVITY_FAILED
})

const deleteActivity = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.deleteActivity,
  type: ActivityTypes.DELETE_ACTIVITY_REQUEST,
  successType: ActivityTypes.DELETE_ACTIVITY_SUCCESS,
  failType: ActivityTypes.DELETE_ACTIVITY_FAILED
})

const updateActivity = bindRequestLogic({
  clientApi: activityApi,
  clientFunc: activityApi.updateActivity,
  type: ActivityTypes.UPDATE_ACTIVITY_REQUEST,
  successType: ActivityTypes.UPDATE_ACTIVITY_SUCCESS,
  failType: ActivityTypes.UPDATE_ACTIVITY_FAILED
})

export default [
  getAppUserActivities,
  getVehicleActivities,
  getCompanyActivities,
  getSubscriptionActivities,
  getElectronicActivities,
  addActivity,
  updateActivity,
  deleteActivity
]
