import VehicleModelTypes from 'store/action_types/vehicleModel'
import UserTypes from 'store/action_types/user'
import {
  DEFAULT_PAGINATION,
  DEFAULT_VEHICLE_MODEL_FILTER
} from 'constants/common'

const initVehicleModel = {
  list: [],
  activeList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_VEHICLE_MODEL_FILTER
}

const vehicleModel = (state = initVehicleModel, action) => {
  switch (action.type) {
    case VehicleModelTypes.GET_VEHICLE_MODELS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case VehicleModelTypes.GET_VEHICLE_MODEL_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case VehicleModelTypes.UPDATE_FILTER_VEHICLE_MODEL_SUCCESS:
      return { ...state, filter: action.payload }
    case VehicleModelTypes.CLEAR_FILTER_VEHICLE_MODEL_SUCCESS:
    case UserTypes.LOGOUT_SUCCESS:
      return initVehicleModel
    default:
      return state
  }
}

export default vehicleModel
