import LogMailTypes from '../action_types/logMail'

import { logMail as logMailApi } from '../api'
import { bindRequestLogic } from './utils'

const getLogMails = bindRequestLogic({
  clientApi: logMailApi,
  clientFunc: logMailApi.getLogMails,
  type: LogMailTypes.GET_LOG_MAILS_REQUEST,
  successType: LogMailTypes.GET_LOG_MAILS_SUCCESS,
  failType: LogMailTypes.GET_LOG_MAILS_FAILED,
  isQueryParam: true,
  modelName: 'logMails'
})

export default [getLogMails]
