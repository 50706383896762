import MessageTypes from '../action_types/message'

import {
  message as messageApi,
  messageImport as messageImportApi
} from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getMessages = bindRequestLogic({
  clientApi: messageApi,
  clientFunc: messageApi.getMessages,
  type: MessageTypes.GET_MESSAGES_REQUEST,
  successType: MessageTypes.GET_MESSAGES_SUCCESS,
  failType: MessageTypes.GET_MESSAGES_FAILED,
  isQueryParam: true,
  modelName: 'messages'
})

const createMessage = bindRequestLogic({
  clientApi: messageApi,
  clientFunc: messageApi.createMessage,
  type: MessageTypes.CREATE_MESSAGE_REQUEST,
  successType: MessageTypes.CREATE_MESSAGE_SUCCESS,
  failType: MessageTypes.CREATE_MESSAGE_FAILED
})

const deleteMessage = bindRequestLogic({
  clientApi: messageApi,
  clientFunc: messageApi.deleteMessage,
  type: MessageTypes.DELETE_MESSAGE_REQUEST,
  successType: MessageTypes.DELETE_MESSAGE_SUCCESS,
  failType: MessageTypes.DELETE_MESSAGE_FAILED
})

const updateMessages = bindRequestLogic({
  clientApi: messageApi,
  clientFunc: messageApi.updateMessages,
  type: MessageTypes.UPDATE_MESSAGES_REQUEST,
  successType: MessageTypes.UPDATE_MESSAGES_SUCCESS,
  failType: MessageTypes.UPDATE_MESSAGES_FAILED
})

const importMessages = bindRequestLogic({
  clientApi: messageImportApi,
  clientFunc: messageImportApi.importMessages,
  type: MessageTypes.IMPORT_MESSAGES_REQUEST,
  successType: MessageTypes.IMPORT_MESSAGES_SUCCESS,
  failType: MessageTypes.IMPORT_MESSAGES_FAILED
})

const updateFilterMessage = bindFilterLogic({
  type: MessageTypes.UPDATE_FILTER_MESSAGE_REQUEST,
  successType: MessageTypes.UPDATE_FILTER_MESSAGE_SUCCESS
})

const clearFilterMessage = bindFilterLogic({
  type: MessageTypes.CLEAR_FILTER_MESSAGE_REQUEST,
  successType: MessageTypes.CLEAR_FILTER_MESSAGE_SUCCESS
})

export default [
  getMessages,
  createMessage,
  deleteMessage,
  updateMessages,
  importMessages,
  updateFilterMessage,
  clearFilterMessage
]
