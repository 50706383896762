import BaseApi from './base'

class ElectricKeyModel extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.vehicleURL}/backoffice`
  }

  getElectronicKeyModels = (query) =>
    this.client.get(`electronic-key-model?${query}`)

  getElectronicKeyModelDetail = (id) =>
    this.client.get(`electronic-key-model/${id}`)

  addElectronicKeyModel = (data) =>
    this.client.post('electronic-key-model', data)

  updateElectronicKeyModel = (id, data) =>
    this.client.patch(`electronic-key-model/${id}`, data)

  deleteElectronicKeyModel = (id) =>
    this.client.delete(`electronic-key-model/${id}`)
}

export default new ElectricKeyModel()
