import BaseApi from './base'

class AppUser extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_FLEET_API_URL
  }

  getAppUsers = (query) => this.client.get(`users?${query}`)

  addUser = (data) => this.client.post(`users`, data)

  updateUser = (id, data) => this.client.patch(`users/${id}`, data)

  deleteUser = (id) => this.client.delete(`users/${id}`)

  getAppUserDetail = (id) => this.client.get(`users/${id}`)

  updateRoleUser = (id, data) =>
    this.client.post(`admin/user-app/${id}/roles`, data, {
      baseURL: this.baseURL
    })

  getRoleUser = (id) =>
    this.client.get(`admin/user-app/${id}/roles`, { baseURL: this.baseURL })

  getUserSubscription = (id, query) =>
    this.client.get(`admin/subscriptions/subscriber/${id}?${query}`)

  getSubscriptionAllowedPilots = (id) =>
    this.client.get(`/users/${id}/allowed-pilots`)

  getUserVehicles = (id, query) =>
    this.client.get(`/users/${id}/vehicles?${query}`)

  getListIndividualVehicleSharingExtension = (params) =>
    this.client.get(`/vehicle-sharing/extensions`, { params })

  getDetailIndividualVehicleSharing = (params) => {
    return this.client.get(`/vehicle-sharing/extensions/${params.sharingId}`, {
      params
    })
  }
}

export default new AppUser()
