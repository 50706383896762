import RequestStatus from 'constants/request_status'

export const getAccessToken = (state) => state.store?.user?.accessToken || null
export const requestingLogin = (state) =>
  state.requests?.loginUser?.status === RequestStatus.STARTED
export const getErrorMessage = (state) => state.store?.user.error || null
export const getUserInfo = (state) => state.store?.user.info || null
export const getUserEmail = (state) => state.store?.user?.info?.email || null
export const getUserList = (state) => state.store?.user.list || []
export const getUserPagination = (state) => state.store?.user?.pagination || {}

export const getCurrentUser = (state) => state.store?.user?.current || null
export const getUserLogged = (state) => state.store?.user?.info || null
export const getRolePermissions = (state) => {
  return state?.store?.user?.info?.role?.rolePermissions || []
}
export const getFilter = (state) => state.store?.user?.filter || {}
export const getRefreshToken = (state) =>
  state.store?.user?.refreshToken || null

export const isLoading = (state) =>
  state.requests?.user?.getUsers.status === RequestStatus.STARTED
