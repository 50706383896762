import RequestStatus from 'constants/request_status'
import { getModuleFilter } from 'helpers/filter'
import { DEFAULT_LABEL_FILTER } from 'constants/common'

export const getLabelList = (state) => state?.store?.label.list || []
export const getPagination = (state) => state?.store?.label.pagination || {}
export const getSystemLabelList = (state) =>
  state?.store?.label.systemList || []
export const getFilter = (state) =>
  getModuleFilter(state, 'label', DEFAULT_LABEL_FILTER)

export const isLoading = (state) =>
  state.requests?.label?.getLabels.status === RequestStatus.STARTED
