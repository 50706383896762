import { STORAGE_KEYS } from 'constants/common'
import { getDefaultLanguageId } from 'helpers/Utils'
import { createLogic } from 'redux-logic'
import qs from 'qs'
import StorageTypes from '../action_types/storage'
import UserTypes from '../action_types/user'
import LabelTypes from '../action_types/label'
import MessageTypes from '../action_types/message'
import SystemConfigTypes from '../action_types/systemConfig'

import {
  label as labelApi,
  language as languageApi,
  message as messageApi,
  systemConfig as systemConfigApi,
  user as userApi
} from '../api'

const loadLocalData = createLogic({
  type: StorageTypes.LOAD_LOCAL_STORAGE_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    const { onSuccess, onError } = action.meta
    try {
      const accessToken = await localStorage.getItem(STORAGE_KEYS.accessToken)
      const res = await userApi.fetchProfile(accessToken)
      if (res?.data?.user?.id) {
        userApi.setAccessToken(accessToken)
        localStorage.setItem(STORAGE_KEYS.accessToken, accessToken)
        const { user } = res.data

        // get all labels, messages, system-config with default language
        const activeLanguageRes = await languageApi.getSystemLanguages()
        const activeLanguages = activeLanguageRes?.data?.languages?.items
        const defaultLanguageId = getDefaultLanguageId(activeLanguages)
        const params = {
          languageId: defaultLanguageId,
          page: 1,
          limit: 0
        }
        const query = qs.stringify(params, { arrayFormat: 'brackets' })

        const labelRes = await labelApi.getSystemLabels(query)
        const messageRes = await messageApi.getSystemMessages(query)
        const systemConfigRes = await systemConfigApi.getAllSystemConfigs()
        dispatch({
          type: LabelTypes.GET_SYSTEM_LABELS_SUCCESS,
          payload: labelRes?.data?.items,
          meta: {}
        })

        dispatch({
          type: MessageTypes.GET_SYSTEM_MESSAGES_SUCCESS,
          payload: messageRes?.data?.messages?.items,
          meta: {}
        })

        dispatch({
          type: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS,
          payload: systemConfigRes?.data?.systemConfig,
          meta: {}
        })

        dispatch({
          type: UserTypes.FETCH_PROFILE_SUCCESS,
          payload: user,
          meta: {}
        })

        dispatch({
          type: StorageTypes.LOAD_LOCAL_STORAGE_SUCCESS,
          payload: accessToken,
          meta: {}
        })
        onSuccess(accessToken)
      } else {
        dispatch({
          type: UserTypes.FETCH_PROFILE_FAILURE,
          payload: {},
          meta: {}
        })
        onError('')
      }
    } catch (error) {
      onError(error)
      dispatch({
        type: StorageTypes.LOAD_LOCAL_STORAGE_FAILURE,
        payload: error.response,
        error: true
      })
    }

    done()
  }
})

export default [loadLocalData]
