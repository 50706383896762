import BaseApi from './base'

class RefreshUser extends BaseApi {
  constructor() {
    super()
    this.setSyncAccessToken()
    this.setSyncRefreshToken()
  }

  logoutUser = () => this.client.post('/logout')

  refreshToken = () => this.client.post('/refresh-token')
}

export default new RefreshUser()
