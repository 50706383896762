import { combineReducers } from 'redux'
import TimezoneTypes from 'store/action_types/timezone'
import { createHandleRequest } from './helpers'

const getTimezones = createHandleRequest(
  TimezoneTypes.GET_TIMEZONES_REQUEST,
  TimezoneTypes.GET_TIMEZONES_SUCCESS,
  TimezoneTypes.GET_TIMEZONES_FAILED
)

export default combineReducers({
  getTimezones
})
