import BaseApi from './base'

class VehicleFleet extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_FLEET_API_URL
  }

  getVehicleUsers = (id, query = '') =>
    this.client.get(`vehicle-users/${id}/users?${query}`)
}

export default new VehicleFleet()
