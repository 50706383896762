import keyMirror from 'keymirror'

export default keyMirror({
  GET_ELECTRONIC_KEYS_REQUEST: null,
  GET_ELECTRONIC_KEYS_SUCCESS: null,
  GET_ELECTRONIC_KEYS_FAILED: null,

  ADD_ELECTRONIC_KEY_REQUEST: null,
  ADD_ELECTRONIC_KEY_SUCCESS: null,
  ADD_ELECTRONIC_KEY_FAILED: null,

  GET_ELECTRONIC_KEY_DETAIL_REQUEST: null,
  GET_ELECTRONIC_KEY_DETAIL_SUCCESS: null,
  GET_ELECTRONIC_KEY_DETAIL_FAILED: null,

  UPDATE_ELECTRONIC_KEY_REQUEST: null,
  UPDATE_ELECTRONIC_KEY_SUCCESS: null,
  UPDATE_ELECTRONIC_KEY_FAILED: null,

  DELETE_ELECTRONIC_KEY_REQUEST: null,
  DELETE_ELECTRONIC_KEY_SUCCESS: null,
  DELETE_ELECTRONIC_KEY_FAILED: null,

  UPDATE_FILTER_ELECTRONIC_KEY_SUCCESS: null,
  CLEAR_FILTER_ELECTRONIC_KEY_SUCCESS: null,

  GET_VEHICLE_ELECTRONIC_KEYS_REQUEST: null,
  GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_SUCCESS: null,
  GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_FAILED: null,

  UPDATE_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS: null
})
