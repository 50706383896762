import PlatformTechTypes from 'store/action_types/platformTech'
import UserTypes from 'store/action_types/user'

const initPlatformTech = {
  list: [],
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  current: {}
}

const platformTech = (state = initPlatformTech, action) => {
  switch (action.type) {
    case PlatformTechTypes.GET_PLATFORM_TECH_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.meta
      }
    case PlatformTechTypes.GET_PLATFORM_TECH_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case UserTypes.LOGOUT_SUCCESS:
      return initPlatformTech
    default:
      return state
  }
}

export default platformTech
