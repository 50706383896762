import { combineReducers } from 'redux'
import ShowcaseComponentTypes from 'store/action_types/showcaseComponent'
import { createHandleRequest } from './helpers'

const getShowcaseComponents = createHandleRequest(
  ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_REQUEST,
  ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_SUCCESS,
  ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_FAILED
)

export default combineReducers({
  getShowcaseComponents
})
