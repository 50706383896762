import { combineReducers } from 'redux'
import AppUserTypes from 'store/action_types/app-user'
import { createHandleRequest } from './helpers'

const getAppUsers = createHandleRequest(
  AppUserTypes.GET_APP_USERS_REQUEST,
  AppUserTypes.GET_APP_USERS_SUCCESS,
  AppUserTypes.GET_APP_USERS_FAILED
)

const getUserSubscription = createHandleRequest(
  AppUserTypes.GET_USER_SUBSCRIPTION_REQUEST,
  AppUserTypes.GET_USER_SUBSCRIPTION_SUCCESS,
  AppUserTypes.GET_USER_SUBSCRIPTION_FAILED
)

const getUserVehicles = createHandleRequest(
  AppUserTypes.GET_USER_VEHICLES_REQUEST,
  AppUserTypes.GET_USER_VEHICLES_SUCCESS,
  AppUserTypes.GET_USER_VEHICLES_FAILED
)

const getListIndividualVehicleSharingExtension = createHandleRequest(
  AppUserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_REQUEST,
  AppUserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_SUCCESS,
  AppUserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_FAILED
)

const getDetailIndividualVehicleSharing = createHandleRequest(
  AppUserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_REQUEST,
  AppUserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_SUCCESS,
  AppUserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_FAILED
)

const getSubscriptionAllowedPilots = createHandleRequest(
  AppUserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_REQUEST,
  AppUserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_SUCCESS,
  AppUserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_FAILED
)

export default combineReducers({
  getAppUsers,
  getUserSubscription,
  getUserVehicles,
  getListIndividualVehicleSharingExtension,
  getDetailIndividualVehicleSharing,
  getSubscriptionAllowedPilots
})
