import RequestStatus from 'constants/request_status'
import { DEFAULT_MESSAGE_FILTER } from 'constants/common'
import { getModuleFilter } from 'helpers/filter'

export const getMessageList = (state) => state?.store?.message.list || []
export const getPagination = (state) => state?.store?.message.pagination || {}
export const getSystemMessageList = (state) =>
  state?.store?.message.systemList || []
export const getFilter = (state) =>
  getModuleFilter(state, 'message', DEFAULT_MESSAGE_FILTER)

export const isLoading = (state) =>
  state.requests?.message?.getMessages.status === RequestStatus.STARTED
