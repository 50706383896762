import { combineReducers } from 'redux'
import ShowcaseSectionTypes from 'store/action_types/showcaseSection'
import { createHandleRequest } from './helpers'

const getShowcaseSections = createHandleRequest(
  ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_REQUEST,
  ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_SUCCESS,
  ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_FAILED
)

export default combineReducers({
  getShowcaseSections
})
