import { getValueAtPath } from 'helpers/Utils'
import {
  GROUP_ROLE,
  MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY,
  MAP_PERMISSION_TO_CATEGORY
} from 'constants/common'

const getCode = (permission) => {
  if (typeof permission !== 'string') return permission

  return permission.split(/_(.*)/s)[1].toLowerCase()
}

const categorizePermissions = (permissions) => {
  const categoryPermissions = {}

  permissions.forEach((permission) => {
    let category = ''
    let subCategory = ''

    // permission object structure is different when get from role detail api or get all permissions api
    if (permission.permissions) {
      category = getValueAtPath(permission, 'permissions.parentCategory')
      subCategory = MAP_PERMISSION_TO_CATEGORY[getCode(permission.permission)]
    } else {
      category = permission.parentCategory
      subCategory = MAP_PERMISSION_TO_CATEGORY[getCode(permission.code)]
    }

    if (!subCategory) return

    if (categoryPermissions[category]) {
      if (categoryPermissions[category][subCategory]) {
        categoryPermissions[category][subCategory].push(permission)
      } else {
        categoryPermissions[category][subCategory] = [permission]
      }
      return
    }

    categoryPermissions[category] = { [subCategory]: [permission] }
  })

  return categoryPermissions
}

const categorizeFleetManagerPermissions = (permissions) => {
  const categoryPermissions = {}

  permissions.forEach((permission) => {
    let category = ''

    // permission object structure is different when get from role detail api or get all permissions api
    if (permission.permissions) {
      category =
        MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY[
          getCode(permission.permission)
        ]
    } else {
      category =
        MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY[getCode(permission.code)]
    }

    if (!category) return

    if (categoryPermissions[category]) {
      categoryPermissions[category].push(permission)
    } else {
      categoryPermissions[category] = [permission]
    }
  })

  return categoryPermissions
}

export const mapRoleDetail = (role) => {
  if (role.groupCode === GROUP_ROLE.backOffice) {
    // categorize permission based on parent category key (1st level)
    const categoryPermissions = categorizePermissions(role.rolePermissions)

    return { ...role, categoryPermissions }
  }

  if (role.groupCode === GROUP_ROLE.fleetManagerMember) {
    // categorize permission based MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY object
    const categoryPermissions = categorizeFleetManagerPermissions(
      role.rolePermissions
    )

    return { ...role, categoryPermissions }
  }

  // in case of group: pilot platform & fleet manager portal
  return role
}

export const mapPermissions = (permissions, payload) => {
  const { items } = permissions
  const groupCode = payload?.groupCode || ''

  // categorize permission based on parent category key (1st level)
  if (groupCode === GROUP_ROLE.backOffice)
    return categorizePermissions(permissions.items)

  // categorize permission based MAP_FLEET_MANAGER_PERMISSIONS_TO_CATEGORY object
  if (groupCode === GROUP_ROLE.fleetManagerMember)
    return categorizeFleetManagerPermissions(items)

  // in case of group: pilot platform & fleet manager portal
  return items
  // return categorizePermissions(permissions.items);
}
