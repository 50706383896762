import ShowcasePrivacyPolicyTypes from 'store/action_types/showcasePrivacyPolicy'

import UserTypes from 'store/action_types/user'

const initShowcasePrivacyPolicy = {
  current: {}
}

const showcasePrivacyPolicy = (state = initShowcasePrivacyPolicy, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        current: data?.items.length > 0 ? data?.items[0] : {}
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initShowcasePrivacyPolicy
    default:
      return state
  }
}

export default showcasePrivacyPolicy
