import LabelTypes from '../action_types/label'

import { label as labelApi, labelImport as labelImportApi } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getLabels = bindRequestLogic({
  clientApi: labelApi,
  clientFunc: labelApi.getLabels,
  type: LabelTypes.GET_LABELS_REQUEST,
  successType: LabelTypes.GET_LABELS_SUCCESS,
  failType: LabelTypes.GET_LABELS_FAILED,
  isQueryParam: true
})

const createLabel = bindRequestLogic({
  clientApi: labelApi,
  clientFunc: labelApi.createLabel,
  type: LabelTypes.CREATE_LABEL_REQUEST,
  successType: LabelTypes.CREATE_LABEL_SUCCESS,
  failType: LabelTypes.CREATE_LABEL_FAILED
})

const deleteLabel = bindRequestLogic({
  clientApi: labelApi,
  clientFunc: labelApi.deleteLabel,
  type: LabelTypes.DELETE_LABEL_REQUEST,
  successType: LabelTypes.DELETE_LABEL_SUCCESS,
  failType: LabelTypes.DELETE_LABEL_FAILED
})

const updateLabels = bindRequestLogic({
  clientApi: labelApi,
  clientFunc: labelApi.updateLabels,
  type: LabelTypes.UPDATE_LABELS_REQUEST,
  successType: LabelTypes.UPDATE_LABELS_SUCCESS,
  failType: LabelTypes.UPDATE_LABELS_FAILED
})

const importLabels = bindRequestLogic({
  clientApi: labelImportApi,
  clientFunc: labelImportApi.importLabels,
  type: LabelTypes.IMPORT_LABELS_REQUEST,
  successType: LabelTypes.IMPORT_LABELS_SUCCESS,
  failType: LabelTypes.IMPORT_LABELS_FAILED
})

const updateFilterLabel = bindFilterLogic({
  type: LabelTypes.UPDATE_FILTER_LABEL_REQUEST,
  successType: LabelTypes.UPDATE_FILTER_LABEL_SUCCESS
})

const clearFilterLabel = bindFilterLogic({
  type: LabelTypes.CLEAR_FILTER_LABEL_REQUEST,
  successType: LabelTypes.CLEAR_FILTER_LABEL_SUCCESS
})

export default [
  getLabels,
  createLabel,
  deleteLabel,
  updateLabels,
  importLabels,
  updateFilterLabel,
  clearFilterLabel
]
