import { DEFAULT_PAGINATION, DEFAULT_ROLE_FILTER } from 'constants/common'
import RoleTypes from 'store/action_types/role'
import UserTypes from 'store/action_types/user'

const initRole = {
  list: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  permissions: [],
  groups: [],
  filter: DEFAULT_ROLE_FILTER
}

const role = (state = initRole, action) => {
  switch (action.type) {
    case RoleTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case RoleTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload?.items
      }
    case RoleTypes.GET_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.payload }
    case RoleTypes.GET_ROLE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case RoleTypes.UPDATE_FILTER_ROLE_SUCCESS:
      return { ...state, filter: action.payload }
    case RoleTypes.CLEAR_FILTER_ROLE_SUCCESS:
      return { ...state, filter: initRole.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initRole
    default:
      return state
  }
}

export default role
