import ModelPerTypes from '../action_types/modelPer'

import { modelPer as modelPerApi } from '../api'
import { bindRequestLogic } from './utils'

const getModelPers = bindRequestLogic({
  clientApi: modelPerApi,
  clientFunc: modelPerApi.getModelPers,
  type: ModelPerTypes.GET_MODEL_PERS_REQUEST,
  successType: ModelPerTypes.GET_MODEL_PERS_SUCCESS,
  failType: ModelPerTypes.GET_MODEL_PERS_FAILED,
  isQueryParam: true,
  modelName: 'modelPers'
})

const addModelPer = bindRequestLogic({
  clientApi: modelPerApi,
  clientFunc: modelPerApi.addModelPer,
  type: ModelPerTypes.ADD_MODEL_PER_REQUEST,
  successType: ModelPerTypes.ADD_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.ADD_MODEL_PER_FAILED
})

const getModelPerDetail = bindRequestLogic({
  clientApi: modelPerApi,
  clientFunc: modelPerApi.getModelPerDetail,
  type: ModelPerTypes.GET_MODEL_PER_DETAIL_REQUEST,
  successType: ModelPerTypes.GET_MODEL_PER_DETAIL_SUCCESS,
  failType: ModelPerTypes.GET_MODEL_PER_DETAIL_FAILED,
  modelName: 'modelPERS'
})

const deleteModelPer = bindRequestLogic({
  clientApi: modelPerApi,
  clientFunc: modelPerApi.deleteModelPer,
  type: ModelPerTypes.DELETE_MODEL_PER_REQUEST,
  successType: ModelPerTypes.DELETE_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.DELETE_MODEL_PER_FAILED
})

const updateModelPer = bindRequestLogic({
  clientApi: modelPerApi,
  clientFunc: modelPerApi.updateModelPer,
  type: ModelPerTypes.UPDATE_MODEL_PER_REQUEST,
  successType: ModelPerTypes.UPDATE_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.UPDATE_MODEL_PER_FAILED
})

export default [
  getModelPers,
  addModelPer,
  getModelPerDetail,
  deleteModelPer,
  updateModelPer
]
