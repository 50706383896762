import LogRoutineTypes from '../action_types/logRoutine'

import { logRoutine as logRoutineApi } from '../api'
import { bindRequestLogic } from './utils'

const getLogRoutines = bindRequestLogic({
  clientApi: logRoutineApi,
  clientFunc: logRoutineApi.getLogRoutines,
  type: LogRoutineTypes.GET_LOG_ROUTINES_REQUEST,
  successType: LogRoutineTypes.GET_LOG_ROUTINES_SUCCESS,
  failType: LogRoutineTypes.GET_LOG_ROUTINES_FAILED,
  isQueryParam: true,
  modelName: 'logRoutines'
})

const getCodeRoutines = bindRequestLogic({
  clientApi: logRoutineApi,
  clientFunc: logRoutineApi.getCodeRoutines,
  type: LogRoutineTypes.GET_CODE_ROUTINES_REQUEST,
  successType: LogRoutineTypes.GET_CODE_ROUTINES_SUCCESS,
  failType: LogRoutineTypes.GET_CODE_ROUTINES_FAILED,
  modelName: 'codeRoutines'
})

export default [getLogRoutines, getCodeRoutines]
