import UserTypes from 'store/action_types/app-user'

import { appUser as userApi } from 'store/api'
import { bindRequestLogic } from './utils'
import { mapUserSubscriptions, mapUserVehicles } from '../mapping'

export const STORAGE_KEYS = Object.freeze({
  accessToken: '@accessToken'
})

const getAppUserList = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getAppUsers,
  type: UserTypes.GET_APP_USERS_REQUEST,
  successType: UserTypes.GET_APP_USERS_SUCCESS,
  failType: UserTypes.GET_APP_USERS_FAILED,
  isQueryParam: true,
  modelName: 'users'
})

const deleteAppUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.deleteUser,
  type: UserTypes.DELETE_APP_USER_REQUEST,
  successType: UserTypes.DELETE_APP_USER_SUCCESS,
  failType: UserTypes.DELETE_APP_USER_FAILED
})

const getAppUserDetail = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getAppUserDetail,
  type: UserTypes.GET_APP_USER_DETAIL_REQUEST,
  successType: UserTypes.GET_APP_USER_DETAIL_SUCCESS,
  failType: UserTypes.GET_APP_USER_DETAIL_FAILED,
  modelName: 'user'
})

const addUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.addUser,
  type: UserTypes.ADD_APP_USER_REQUEST,
  successType: UserTypes.ADD_APP_USER_SUCCESS,
  failType: UserTypes.ADD_APP_USER_FAILED
})

const updateAppUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.updateUser,
  type: UserTypes.UPDATE_APP_USER_REQUEST,
  successType: UserTypes.UPDATE_APP_USER_SUCCESS,
  failType: UserTypes.UPDATE_APP_USER_FAILED
})

const updateRoleUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.updateRoleUser,
  type: UserTypes.UPDATE_ROLE_USER_REQUEST,
  successType: UserTypes.UPDATE_ROLE_USER_SUCCESS,
  failType: UserTypes.UPDATE_ROLE_USER_FAILED
})

const getRoleUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getRoleUser,
  type: UserTypes.GET_ROLE_USERS_REQUEST,
  successType: UserTypes.GET_ROLE_USERS_SUCCESS,
  failType: UserTypes.GET_ROLE_USERS_FAILED
})

const getUserSubscription = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getUserSubscription,
  type: UserTypes.GET_USER_SUBSCRIPTION_REQUEST,
  successType: UserTypes.GET_USER_SUBSCRIPTION_SUCCESS,
  failType: UserTypes.GET_USER_SUBSCRIPTION_FAILED,
  isQueryParam: true,
  mapResponse: mapUserSubscriptions
})

const getSubscriptionAllowedPilots = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getSubscriptionAllowedPilots,
  type: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_REQUEST,
  successType: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_SUCCESS,
  failType: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_FAILED,
  isQueryParam: false
})

const getUserVehicles = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getUserVehicles,
  type: UserTypes.GET_USER_VEHICLES_REQUEST,
  successType: UserTypes.GET_USER_VEHICLES_SUCCESS,
  failType: UserTypes.GET_USER_VEHICLES_FAILED,
  isQueryParam: true,
  mapResponse: mapUserVehicles
})

const getListIndividualVehicleSharingExtension = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getListIndividualVehicleSharingExtension,
  type: UserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_REQUEST,
  successType: UserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_SUCCESS,
  failType: UserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_FAILED,
  isQueryParam: false
})

const getDetailIndividualVehicleSharing = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getDetailIndividualVehicleSharing,
  type: UserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_REQUEST,
  successType: UserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_SUCCESS,
  failType: UserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_FAILED,
  isQueryParam: false
})

export default [
  getAppUserList,
  deleteAppUser,
  getAppUserDetail,
  getRoleUser,
  addUser,
  updateAppUser,
  updateRoleUser,
  getUserSubscription,
  getUserVehicles,
  getListIndividualVehicleSharingExtension,
  getDetailIndividualVehicleSharing,
  getSubscriptionAllowedPilots
]
