import { combineReducers } from 'redux'
import VehicleTypes from 'store/action_types/vehicle'
import { createHandleRequest } from './helpers'

const getVehicles = createHandleRequest(
  VehicleTypes.GET_VEHICLES_REQUEST,
  VehicleTypes.GET_VEHICLES_SUCCESS,
  VehicleTypes.GET_VEHICLES_FAILED
)

const getVehicleUsers = createHandleRequest(
  VehicleTypes.GET_VEHICLE_USERS_REQUEST,
  VehicleTypes.GET_VEHICLE_USERS_SUCCESS,
  VehicleTypes.GET_VEHICLE_USERS_FAILED
)

export default combineReducers({
  getVehicles,
  getVehicleUsers
})
