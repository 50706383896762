import FormattedTextTypes from '../action_types/formattedText'

import { formattedText as formattedTextApi } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getFormattedTexts = bindRequestLogic({
  clientApi: formattedTextApi,
  clientFunc: formattedTextApi.getFormattedTexts,
  type: FormattedTextTypes.GET_FORMATTED_TEXTS_REQUEST,
  successType: FormattedTextTypes.GET_FORMATTED_TEXTS_SUCCESS,
  failType: FormattedTextTypes.GET_FORMATTED_TEXTS_FAILED,
  isQueryParam: true
})

const addFormattedText = bindRequestLogic({
  clientApi: formattedTextApi,
  clientFunc: formattedTextApi.addFormattedText,
  type: FormattedTextTypes.ADD_FORMATTED_TEXT_REQUEST,
  successType: FormattedTextTypes.ADD_FORMATTED_TEXT_SUCCESS,
  failType: FormattedTextTypes.ADD_FORMATTED_TEXT_FAILED
})

const deleteFormattedText = bindRequestLogic({
  clientApi: formattedTextApi,
  clientFunc: formattedTextApi.deleteFormattedText,
  type: FormattedTextTypes.DELETE_FORMATTED_TEXT_REQUEST,
  successType: FormattedTextTypes.DELETE_FORMATTED_TEXT_SUCCESS,
  failType: FormattedTextTypes.DELETE_FORMATTED_TEXT_FAILED
})

const updateFormattedText = bindRequestLogic({
  clientApi: formattedTextApi,
  clientFunc: formattedTextApi.updateFormattedText,
  type: FormattedTextTypes.UPDATE_FORMATTED_TEXT_REQUEST,
  successType: FormattedTextTypes.UPDATE_FORMATTED_TEXT_SUCCESS,
  failType: FormattedTextTypes.UPDATE_FORMATTED_TEXT_FAILED
})

const updateFilterFormattedText = bindFilterLogic({
  type: FormattedTextTypes.UPDATE_FILTER_FORMATTED_TEXT_REQUEST,
  successType: FormattedTextTypes.UPDATE_FILTER_FORMATTED_TEXT_SUCCESS
})

const clearFilterFormattedText = bindFilterLogic({
  type: FormattedTextTypes.CLEAR_FILTER_FORMATTED_TEXT_REQUEST,
  successType: FormattedTextTypes.CLEAR_FILTER_FORMATTED_TEXT_SUCCESS
})

export default [
  getFormattedTexts,
  addFormattedText,
  deleteFormattedText,
  updateFormattedText,
  updateFilterFormattedText,
  clearFilterFormattedText
]
