import VehicleBrandTypes from 'store/action_types/vehicleBrand'

import { vehicleBrand as vehicleBrandApi } from 'store/api'
import { bindRequestLogic } from './utils'

const getVehicleBrands = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.getVehicleBrands,
  type: VehicleBrandTypes.GET_VEHICLE_BRANDS_REQUEST,
  successType: VehicleBrandTypes.GET_VEHICLE_BRANDS_SUCCESS,
  failType: VehicleBrandTypes.GET_VEHICLE_BRANDS_FAILED,
  isQueryParam: true
})

const getVehicleBrandDetail = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.getVehicleBrandDetail,
  type: VehicleBrandTypes.GET_VEHICLE_BRAND_DETAIL_REQUEST,
  successType: VehicleBrandTypes.GET_VEHICLE_BRAND_DETAIL_SUCCESS,
  failType: VehicleBrandTypes.GET_VEHICLE_BRAND_DETAIL_FAILED,
  modelName: 'vehicleBrand'
})

const addVehicleBrand = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.addVehicleBrand,
  type: VehicleBrandTypes.ADD_VEHICLE_BRAND_REQUEST,
  successType: VehicleBrandTypes.ADD_VEHICLE_BRAND_SUCCESS,
  failType: VehicleBrandTypes.ADD_VEHICLE_BRAND_FAILED
})

const updateVehicleBrand = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.updateVehicleBrand,
  type: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_REQUEST,
  successType: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_SUCCESS,
  failType: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_FAILED
})

const deleteVehicleBrand = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.deleteVehicleBrand,
  type: VehicleBrandTypes.DELETE_VEHICLE_BRAND_REQUEST,
  successType: VehicleBrandTypes.DELETE_VEHICLE_BRAND_SUCCESS,
  failType: VehicleBrandTypes.DELETE_VEHICLE_BRAND_FAILED
})

// VEHICLE BRAND MESSAGE =====

const getVehicleBrandMessages = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.getVehicleBrandMessages,
  type: VehicleBrandTypes.GET_VEHICLE_BRAND_MESSAGES_REQUEST,
  successType: VehicleBrandTypes.GET_VEHICLE_BRAND_MESSAGES_SUCCESS,
  failType: VehicleBrandTypes.GET_VEHICLE_BRAND_MESSAGES_FAILED,
  isQueryParam: true
})

const addVehicleBrandMessage = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.addVehicleBrandMessage,
  type: VehicleBrandTypes.ADD_VEHICLE_BRAND_MESSAGE_REQUEST,
  successType: VehicleBrandTypes.ADD_VEHICLE_BRAND_MESSAGE_SUCCESS,
  failType: VehicleBrandTypes.ADD_VEHICLE_BRAND_MESSAGE_FAILED,
  isQueryParam: true
})

const updateVehicleBrandMessage = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.updateVehicleBrandMessage,
  type: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_MESSAGE_REQUEST,
  successType: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_MESSAGE_SUCCESS,
  failType: VehicleBrandTypes.UPDATE_VEHICLE_BRAND_MESSAGE_FAILED,
  isQueryParam: true
})

const deleteVehicleBrandMessage = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.deleteVehicleBrandMessage,
  type: VehicleBrandTypes.DELETE_VEHICLE_BRAND_MESSAGE_REQUEST,
  successType: VehicleBrandTypes.DELETE_VEHICLE_BRAND_MESSAGE_SUCCESS,
  failType: VehicleBrandTypes.DELETE_VEHICLE_BRAND_MESSAGE_FAILED
})
export default [
  getVehicleBrands,
  addVehicleBrand,
  updateVehicleBrand,
  deleteVehicleBrand,
  getVehicleBrandDetail,
  getVehicleBrandMessages,
  addVehicleBrandMessage,
  updateVehicleBrandMessage,
  deleteVehicleBrandMessage
]
