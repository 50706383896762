import Portal from '../action_types/portal'

import { portal as portalApi } from '../api'
import { bindRequestLogic } from './utils'

const getPortals = bindRequestLogic({
  clientApi: portalApi,
  clientFunc: portalApi.getPlatformTechs,
  type: Portal.GET_PORTALS_REQUEST,
  successType: Portal.GET_PORTALS_SUCCESS,
  failedType: Portal.GET_PORTALS_FAILED
})

export default [getPortals]
