import { combineReducers } from 'redux'
import FormattedTextTypes from 'store/action_types/formattedText'
import { createHandleRequest } from './helpers'

const getFormattedTexts = createHandleRequest(
  FormattedTextTypes.GET_FORMATTED_TEXTS_REQUEST,
  FormattedTextTypes.GET_FORMATTED_TEXTS_SUCCESS,
  FormattedTextTypes.GET_FORMATTED_TEXTS_FAILED
)

export default combineReducers({
  getFormattedTexts
})
