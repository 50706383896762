import { combineReducers } from 'redux'
import ShowcaseStoryTypes from 'store/action_types/showcaseStory'
import { createHandleRequest } from './helpers'

const getShowcaseStories = createHandleRequest(
  ShowcaseStoryTypes.GET_SHOWCASE_STORIES_REQUEST,
  ShowcaseStoryTypes.GET_SHOWCASE_STORIES_SUCCESS,
  ShowcaseStoryTypes.GET_SHOWCASE_STORIES_FAILED
)

export default combineReducers({
  getShowcaseStories
})
