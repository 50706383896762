import BaseApi from './base'

class Activity extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_FLEET_API_URL
  }

  getActivities = (objectId, query) =>
    this.client.get(`admin/sheet-activity/${objectId}?${query}`)

  addActivity = (data) => this.client.post('admin/sheet-activity', data)

  updateActivity = (id, data) =>
    this.client.patch(`admin/sheet-activity/${id}`, data)

  deleteActivity = (id) => this.client.delete(`admin/sheet-activity/${id}`)
}

export default new Activity()
