import { filterOutValues } from '../../helpers/Utils'

// eslint-disable-next-line import/prefer-default-export
export const mapFAQDetail = (res) => {
  const data = {
    ...filterOutValues(res, null, [
      'contents',
      'updatedAt',
      'createdAt',
      'deletedAt',
      'success'
    ]),
    // because we develop UI first, so we use contents as content
    content: res.contents
  }

  return data
}
