/* eslint-disable import/no-cycle */
import { connect } from 'react-redux'
import { getSystemLabelList } from 'store/selector/label'
import IntlMessages from './IntlMessages'

const mapStateToProps = (state) => ({
  systemLabels: getSystemLabelList(state)
})

export default connect(mapStateToProps, null)(IntlMessages)
