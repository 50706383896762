import { combineReducers } from 'redux'
import UserTypes from 'store/action_types/user'
import { createHandleRequest } from './helpers'

const loginUser = createHandleRequest(
  UserTypes.LOGIN_REQUEST,
  UserTypes.LOGIN_SUCCESS,
  UserTypes.LOGIN_FAILURE
)

const getUsers = createHandleRequest(
  UserTypes.GET_USERS_REQUEST,
  UserTypes.GET_USERS_SUCCESS,
  UserTypes.GET_USERS_FAILED
)

export default combineReducers({
  loginUser,
  getUsers
})
