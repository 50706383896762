import keyMirror from 'keymirror'

export default keyMirror({
  GET_VEHICLES_REQUEST: null,
  GET_VEHICLES_SUCCESS: null,
  GET_VEHICLES_FAILED: null,

  GET_VEHICLE_DETAIL_REQUEST: null,
  GET_VEHICLE_DETAIL_SUCCESS: null,
  GET_VEHICLE_DETAIL_FAILED: null,

  DELETE_VEHICLE_REQUEST: null,
  DELETE_VEHICLE_SUCCESS: null,
  DELETE_VEHICLE_FAILED: null,

  ADD_VEHICLE_REQUEST: null,
  ADD_VEHICLE_SUCCESS: null,
  ADD_VEHICLE_FAILED: null,

  UPDATE_VEHICLE_REQUEST: null,
  UPDATE_VEHICLE_SUCCESS: null,
  UPDATE_VEHICLE_FAILED: null,

  DELETE_USER_VEHICLE_REQUEST: null,
  DELETE_USER_VEHICLE_SUCCESS: null,
  DELETE_USER_VEHICLE_FAILED: null,

  ADD_USER_VEHICLE_REQUEST: null,
  ADD_USER_VEHICLE_SUCCESS: null,
  ADD_USER_VEHICLE_FAILED: null,

  UPDATE_USER_VEHICLE_REQUEST: null,
  UPDATE_USER_VEHICLE_SUCCESS: null,
  UPDATE_USER_VEHICLE_FAILED: null,

  UPDATE_FILTER_VEHICLE_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_SUCCESS: null,

  GET_VEHICLE_SUBSCRIPTIONS_REQUEST: null,
  GET_VEHICLE_SUBSCRIPTIONS_SUCCESS: null,
  GET_VEHICLE_SUBSCRIPTIONS_FAILED: null,
  UPDATE_FILTER_VEHICLE_SUBSCRIPTIONS_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_SUBSCRIPTIONS_SUCCESS: null,

  // get vehicle user and filter
  GET_VEHICLE_USERS_REQUEST: null,
  GET_VEHICLE_USERS_SUCCESS: null,
  GET_VEHICLE_USERS_FAILED: null,
  UPDATE_FILTER_VEHICLE_USERS_SUCCESS: null
})
