import { changeKey, filterOutValues } from 'helpers/Utils'

export const mapCurrencies = (response) => {
  const { items, links, meta } = response

  const currencies = items.map((i) => ({
    ...filterOutValues(i, null, [
      'label',
      'createdAt',
      'deletedAt',
      'updatedAt'
    ]),
    name: i.label
  }))

  return { items: currencies, meta, links }
}

export const mapCurrencyDetail = (response) => {
  const translations = response.translations || []

  return {
    ...filterOutValues(response, null, [
      'label',
      'createdAt',
      'deletedAt',
      'updatedAt'
    ]),
    name: response.label,
    translations: translations.map((t) =>
      changeKey(t, [{ oldKey: 'label', newKey: 'name' }])
    )
  }
}
