import BaseApi from './base'

class CixiReferent extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_FLEET_API_URL
  }

  getCixiReferents = (query) =>
    this.client.get(`company-cixi-referent?${query}`)

  getCixiReferentDetail = (id) => this.client.get(`company-cixi-referent/${id}`)

  addCixiReferent = (data) => this.client.post('company-cixi-referent', data)

  updateCixiReferent = (id, data) =>
    this.client.patch(`company-cixi-referent/${id}`, data)

  deleteCixiReferent = (id) => this.client.delete(`company-cixi-referent/${id}`)

  getAllCixiReferents = (query) =>
    this.client.get(`company-cixi-referent?${query}`)
}

export default new CixiReferent()
