import AppVersionsTypes from 'store/action_types/appVersions'
import UserTypes from '../action_types/user'
import { replaceValueAtPath } from '../../helpers/Utils'
import { DEFAULT_APP_VERSIONS_FILTER } from '../../constants/common'

const initAppVersions = {
  list: [],
  current: {},
  filter: DEFAULT_APP_VERSIONS_FILTER,
  pagination: {
    currentPage: 1,
    totalPages: 1
  }
}

const appVersions = (state = initAppVersions, action) => {
  switch (action.type) {
    case AppVersionsTypes.GET_APP_VERSIONS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case AppVersionsTypes.GET_APP_VERSIONS_DETAIL_SUCCESS:
      return replaceValueAtPath(state, 'current', action.payload)
    case AppVersionsTypes.UPDATE_APP_VERSIONS_FILTER_SUCCESS:
      return replaceValueAtPath(state, 'filter', action.payload)
    case UserTypes.LOGOUT_SUCCESS:
      return initAppVersions
    default:
      return state
  }
}

export default appVersions
