import { combineReducers } from 'redux'
import ElectronicKeyModelTypes from 'store/action_types/electronicKeyModel'
import { createHandleRequest } from './helpers'

const getElectronicKeyModels = createHandleRequest(
  ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_REQUEST,
  ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_SUCCESS,
  ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_FAILED
)

export default combineReducers({
  getElectronicKeyModels
})
