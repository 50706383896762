import VehicleModels from '../action_types/vehicleModel'

import { vehicleModel as vehicleModelPerApi } from '../api'
import { bindRequestLogic } from './utils'

const getVehicleModels = bindRequestLogic({
  clientApi: vehicleModelPerApi,
  clientFunc: vehicleModelPerApi.getVehicleModels,
  type: VehicleModels.GET_VEHICLE_MODELS_REQUEST,
  successType: VehicleModels.GET_VEHICLE_MODELS_SUCCESS,
  failType: VehicleModels.GET_VEHICLE_MODELS_FAILED,
  isQueryParam: true,
  modelName: 'vehicleModels'
})

const addVehicleModel = bindRequestLogic({
  clientApi: vehicleModelPerApi,
  clientFunc: vehicleModelPerApi.addVehicleModel,
  type: VehicleModels.ADD_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.ADD_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.ADD_VEHICLE_MODEL_FAILED
})

const getVehicleModelDetail = bindRequestLogic({
  clientApi: vehicleModelPerApi,
  clientFunc: vehicleModelPerApi.getVehicleModelDetail,
  type: VehicleModels.GET_VEHICLE_MODEL_DETAIL_REQUEST,
  successType: VehicleModels.GET_VEHICLE_MODEL_DETAIL_SUCCESS,
  failType: VehicleModels.GET_VEHICLE_MODEL_DETAIL_FAILED,
  modelName: 'vehicleModel'
})

const updateVehicleModel = bindRequestLogic({
  clientApi: vehicleModelPerApi,
  clientFunc: vehicleModelPerApi.updateVehicleModel,
  type: VehicleModels.UPDATE_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.UPDATE_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.UPDATE_VEHICLE_MODEL_FAILED
})

const deleteVehicleModel = bindRequestLogic({
  clientApi: vehicleModelPerApi,
  clientFunc: vehicleModelPerApi.deleteVehicleModel,
  type: VehicleModels.DELETE_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.DELETE_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.DELETE_VEHICLE_MODEL_FAILED
})

export default [
  getVehicleModels,
  addVehicleModel,
  getVehicleModelDetail,
  deleteVehicleModel,
  updateVehicleModel
]
