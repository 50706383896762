import BaseApi from './base'

class Label extends BaseApi {
  getLabels = (query) => this.client.get(`admin/label-interface?${query}`)

  createLabel = (data) => this.client.post('admin/label-interface', data)

  deleteLabel = (id) => this.client.delete(`admin/label-interface/${id}`)

  updateLabels = (languageId, data) =>
    this.client.post(
      `admin/label-interface/translation/bulk-upsert/${languageId}`,
      data
    )

  getSystemLabels = (query) =>
    this.client.get(`admin/system/label-interface?${query}`)
}

export default new Label()
