import TimezoneTypes from '../action_types/timezone'

import { timezone as timezoneApi } from '../api'
import { bindRequestLogic } from './utils'

const getActiveTimezones = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.getActiveTimezones,
  type: TimezoneTypes.GET_ACTIVE_TIMEZONES_REQUEST,
  successType: TimezoneTypes.GET_ACTIVE_TIMEZONES_SUCCESS,
  failType: TimezoneTypes.GET_ACTIVE_TIMEZONES_FAILED,
  isQueryParam: true,
  modelName: 'timezones'
})

const getTimezones = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.getTimezones,
  type: TimezoneTypes.GET_TIMEZONES_REQUEST,
  successType: TimezoneTypes.GET_TIMEZONES_SUCCESS,
  failType: TimezoneTypes.GET_TIMEZONES_FAILED,
  isQueryParam: true,
  modelName: 'timezones'
})

const addTimezone = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.addTimezone,
  type: TimezoneTypes.ADD_TIMEZONE_REQUEST,
  successType: TimezoneTypes.ADD_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.ADD_TIMEZONE_FAILED
})

const getTimezoneDetail = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.getTimezoneDetail,
  type: TimezoneTypes.GET_TIMEZONE_DETAIL_REQUEST,
  successType: TimezoneTypes.GET_TIMEZONE_DETAIL_SUCCESS,
  failType: TimezoneTypes.GET_TIMEZONE_DETAIL_FAILED,
  modelName: 'timezone'
})

const deleteTimezone = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.deleteTimezone,
  type: TimezoneTypes.DELETE_TIMEZONE_REQUEST,
  successType: TimezoneTypes.DELETE_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.DELETE_TIMEZONE_FAILED
})

const updateTimezone = bindRequestLogic({
  clientApi: timezoneApi,
  clientFunc: timezoneApi.updateTimezone,
  type: TimezoneTypes.UPDATE_TIMEZONE_REQUEST,
  successType: TimezoneTypes.UPDATE_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.UPDATE_TIMEZONE_FAILED
})

export default [
  getActiveTimezones,
  getTimezones,
  addTimezone,
  getTimezoneDetail,
  deleteTimezone,
  updateTimezone
]
