import Axios from 'axios'
import { REQUEST_TIMEOUT, STORAGE_KEYS } from 'constants/common'

export default class Api {
  client = null

  token = null

  baseURL = process.env.REACT_APP_API_URL

  vehicleURL = process.env.REACT_APP_VEHICLE_API_BO_URL

  fleetURL = process.env.REACT_APP_FLEET_API_URL

  // baseURL = process.env;

  constructor() {
    const client = Axios.create({
      baseURL: this.baseURL,
      headers: {
        Accept: 'application/json'
      },
      timeout: REQUEST_TIMEOUT
    })
    this.client = client
    this.setSyncAccessToken()
  }

  setSyncAccessToken = async () => {
    const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken)
    this.setAccessToken(accessToken)
  }

  setSyncRefreshToken = async () => {
    const refreshToken = await localStorage.getItem(STORAGE_KEYS.refreshToken)
    this.setRefreshToken(refreshToken)
  }

  setAccessToken = (accessToken) => {
    this.token = accessToken
    this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

  setRefreshToken = (refreshToken) => {
    this.client.defaults.headers.common.refreshToken = `Bearer ${refreshToken}`
  }
}
