import ShowcasePrivacyPolicyTypes from '../action_types/showcasePrivacyPolicy'

import { showcasePrivacyPolicy as showcasePrivacyPolicyApi } from '../api'
import { bindRequestLogic } from './utils'

const updateShowcasePrivacyPolicy = bindRequestLogic({
  clientApi: showcasePrivacyPolicyApi,
  clientFunc: showcasePrivacyPolicyApi.updateShowcasePrivacyPolicy,
  type: ShowcasePrivacyPolicyTypes.UPDATE_SHOWCASE_PRIVACY_POLICY_REQUEST,
  successType:
    ShowcasePrivacyPolicyTypes.UPDATE_SHOWCASE_PRIVACY_POLICY_SUCCESS,
  failType: ShowcasePrivacyPolicyTypes.UPDATE_SHOWCASE_PRIVACY_POLICY_FAILED
})

const getShowcasePrivacyPolicy = bindRequestLogic({
  clientApi: showcasePrivacyPolicyApi,
  clientFunc: showcasePrivacyPolicyApi.getShowcasePrivacyPolicy,
  type: ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_REQUEST,
  successType: ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_SUCCESS,
  failType: ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_FAILED
})

export default [updateShowcasePrivacyPolicy, getShowcasePrivacyPolicy]
