import RequestStatus from 'constants/request_status'
import { getValueAtPath } from 'helpers/Utils'

export const getSystemConfigList = (state) =>
  state?.store?.systemConfig?.list || []

export const getPagination = (state) =>
  state.store?.systemConfig?.pagination || {}

export const getModuleList = (state) => {
  const modules = state?.store?.systemConfig.modules || []
  return modules.filter((module) => module.name !== 'portal')
}

export const getDefaultTimezone = (state) => {
  const configs = state?.store?.systemConfig?.list || []
  return configs.find((conf) => conf.code === 'default_timezone')?.value
}

export const getVehicleBrandList = (state) =>
  state?.store?.systemConfig?.vehicleBrands || []

export const getVehicleModelList = (state) =>
  state?.store?.systemConfig?.vehicleModels || []

export const isLoading = (state) =>
  state.requests?.systemConfig?.getSystemConfigs.status ===
  RequestStatus.STARTED

export const currentModule = (state) =>
  getValueAtPath(state, 'store.systemConfig.currentModule', '')
