import { DEFAULT_CURRENCY_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import CurrencyTypes from 'store/action_types/currency'
import UserTypes from 'store/action_types/user'

const initCurrency = {
  list: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_CURRENCY_FILTER
}

const currency = (state = initCurrency, action) => {
  switch (action.type) {
    case CurrencyTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CurrencyTypes.GET_CURRENCY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CurrencyTypes.UPDATE_FILTER_CURRENCY_SUCCESS:
      return { ...state, filter: action.payload }
    case CurrencyTypes.CLEAR_FILTER_CURRENCY_SUCCESS:
      return { ...state, filter: initCurrency.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initCurrency
    default:
      return state
  }
}

export default currency
