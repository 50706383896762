import { DEFAULT_ELECTRONIC_KEY_MODEL_FILTER } from 'constants/common'
import ElectronicKeyModelTypes from 'store/action_types/electronicKeyModel'
import UserTypes from 'store/action_types/user'

const initElectronicKeyModel = {
  list: [],
  activeList: [],
  current: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  filter: DEFAULT_ELECTRONIC_KEY_MODEL_FILTER
}

const electronicKeyModel = (state = initElectronicKeyModel, action) => {
  switch (action.type) {
    case ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODEL_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case ElectronicKeyModelTypes.UPDATE_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS:
      return { ...state, filter: action.payload }
    case ElectronicKeyModelTypes.CLEAR_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS:
      return { ...state, filter: initElectronicKeyModel.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initElectronicKeyModel
    default:
      return state
  }
}

export default electronicKeyModel
