import PortalTypes from 'store/action_types/portal'
import UserTypes from 'store/action_types/user'

const initPortal = {
  list: []
}

const portal = (state = initPortal, action) => {
  switch (action.type) {
    case PortalTypes.GET_PORTALS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initPortal
    default:
      return state
  }
}

export default portal
