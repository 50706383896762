import keyMirror from 'keymirror'

export default keyMirror({
  GET_ELECTRONIC_KEY_ACTIVITIES_REQUEST: null,
  GET_ELECTRONIC_KEY_ACTIVITIES_SUCCESS: null,
  GET_ELECTRONIC_KEY_ACTIVITIES_FAILED: null,

  GET_COMPANY_ACTIVITIES_REQUEST: null,
  GET_COMPANY_ACTIVITIES_SUCCESS: null,
  GET_COMPANY_ACTIVITIES_FAILED: null,

  GET_VEHICLE_ACTIVITIES_REQUEST: null,
  GET_VEHICLE_ACTIVITIES_SUCCESS: null,
  GET_VEHICLE_ACTIVITIES_FAILED: null,

  GET_APP_USER_ACTIVITIES_REQUEST: null,
  GET_APP_USER_ACTIVITIES_SUCCESS: null,
  GET_APP_USER_ACTIVITIES_FAILED: null,

  GET_SUBSCRIPTION_ACTIVITIES_REQUEST: null,
  GET_SUBSCRIPTION_ACTIVITIES_SUCCESS: null,
  GET_SUBSCRIPTION_ACTIVITIES_FAILED: null,

  ADD_ACTIVITY_REQUEST: null,
  ADD_ACTIVITY_SUCCESS: null,
  ADD_ACTIVITY_FAILED: null,

  UPDATE_ACTIVITY_REQUEST: null,
  UPDATE_ACTIVITY_SUCCESS: null,
  UPDATE_ACTIVITY_FAILED: null,

  DELETE_ACTIVITY_REQUEST: null,
  DELETE_ACTIVITY_SUCCESS: null,
  DELETE_ACTIVITY_FAILED: null
})
