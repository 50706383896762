import BaseApi from './base'

class Company extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_FLEET_API_URL
  }

  getCompanies = (query) => this.client.get(`admin/companies?${query}`)

  getCompanyDetail = (id) => this.client.get(`admin/companies/${id}`)

  addCompany = (data) => this.client.post('admin/companies', data)

  updateCompany = (id, data) => this.client.patch(`admin/companies/${id}`, data)

  deleteCompany = (id) => this.client.delete(`admin/companies/${id}`)

  updateSheetAdditional = (id, data) =>
    this.client.post(`admin/companies/${id}/sheet-additional`, data)

  getCompanyMembers = (id, query) =>
    this.client.get(`admin/companies/${id}/users?${query}`)

  addCompanyMember = (id, data) =>
    this.client.post(`admin/companies/${id}/users`, data)

  updateCompanyMember = (id, data) =>
    this.client.patch(`admin/companies/users/${id}`, data)

  deleteCompanyMember = (id) =>
    this.client.delete(`admin/companies/users/${id}`)

  getCompanyPilots = (id, query) =>
    this.client.get(`company-pilots?companyId=${id}&${query}`)

  convertToMotherCompany = (id) =>
    this.client.post(`admin/companies/${id}/mother-company`)

  getChildrenCompanies = (id, query) =>
    this.client.get(`admin/companies/${id}/child-company?${query}`)

  addChildrenCompany = (id, data) =>
    this.client.post(`admin/companies/${id}/child-company`, data)

  deleteChildrenCompany = (id) =>
    this.client.delete(`admin/companies/child-company/${id}`)

  getCompanyVehicles = (id, query) =>
    this.client.get(`admin/companies/${id}/sheet-vehicle?${query}`)

  getCompanySubscription = (id, query) =>
    this.client.get(`admin/subscriptions/subscriber/${id}?${query}`)
}

export default new Company()
