import { combineReducers } from 'redux'
import ConditionalFeatureTypes from 'store/action_types/conditionalFeature'
import { createHandleRequest } from './helpers'

const getConditionalFeatures = createHandleRequest(
  ConditionalFeatureTypes.GET_CONDITIONAL_FEATURE_REQUEST,
  ConditionalFeatureTypes.GET_CONDITIONAL_FEATURE_SUCCESS,
  ConditionalFeatureTypes.GET_CONDITIONAL_FEATURE_FAILED
)

export default combineReducers({
  getConditionalFeatures
})
