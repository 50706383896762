import { DEFAULT_COUNTRY_FILTER } from 'constants/common'
import CountryTypes from 'store/action_types/country'
import UserTypes from 'store/action_types/user'

const initCountry = {
  list: [],
  activeList: [],
  current: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  filter: DEFAULT_COUNTRY_FILTER
}

const country = (state = initCountry, action) => {
  switch (action.type) {
    case CountryTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CountryTypes.GET_COUNTRY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CountryTypes.GET_ACTIVE_COUNTRIES_SUCCESS:
      return { ...state, activeList: action.payload?.items }
    case CountryTypes.UPDATE_FILTER_COUNTRY_SUCCESS:
      return { ...state, filter: action.payload }
    case CountryTypes.CLEAR_FILTER_COUNTRY_SUCCESS:
      return { ...state, filter: initCountry.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initCountry
    default:
      return state
  }
}

export default country
