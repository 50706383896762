import BaseApi from './base'

class AppVersions extends BaseApi {
  getAppVersions = (query) => this.client.get(`admin/app-versions?${query}`)

  getAppVersionsDetail = (id) => this.client.get(`admin/app-versions/${id}`)

  addAppVersions = (data) => this.client.post('admin/app-versions', data)

  updateAppVersions = (id, data) =>
    this.client.patch(`admin/app-versions/${id}`, data)

  deleteAppVersions = (id) => this.client.delete(`admin/app-versions/${id}`)

  appVersionsTranslation = (id, data) =>
    this.client.post(
      `admin/app-versions/${id}/translation/${data.languageId}`,
      data
    )

  deleteAppVersionsTranslation = (id) =>
    this.client?.delete(`admin/app-versions/translation/${id}`)
}

export default new AppVersions()
