import { DEFAULT_PAGINATION, DEFAULT_TIMEZONE_FILTER } from 'constants/common'
import TimezoneTypes from 'store/action_types/timezone'
import UserTypes from 'store/action_types/user'

const initTimezone = {
  list: [],
  activeList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_TIMEZONE_FILTER
}

const timezone = (state = initTimezone, action) => {
  switch (action.type) {
    case TimezoneTypes.GET_ACTIVE_TIMEZONES_SUCCESS:
      return { ...state, activeList: action.payload?.items }
    case TimezoneTypes.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case TimezoneTypes.GET_TIMEZONE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case TimezoneTypes.UPDATE_FILTER_TIMEZONE_SUCCESS:
      return { ...state, filter: action.payload }
    case TimezoneTypes.CLEAR_FILTER_TIMEZONE_SUCCESS:
      return { ...state, filter: initTimezone.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initTimezone
    default:
      return state
  }
}

export default timezone
