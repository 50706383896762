import keyMirror from 'keymirror'

export default keyMirror({
  GET_VEHICLE_ELECTRONIC_KEYS_REQUEST: null,
  GET_VEHICLE_ELECTRONIC_KEYS_SUCCESS: null,
  GET_VEHICLE_ELECTRONIC_KEYS_FAILED: null,

  ADD_VEHICLE_ELECTRONIC_KEY_REQUEST: null,
  ADD_VEHICLE_ELECTRONIC_KEY_SUCCESS: null,
  ADD_VEHICLE_ELECTRONIC_KEY_FAILED: null,

  UPDATE_VEHICLE_ELECTRONIC_KEY_REQUEST: null,
  UPDATE_VEHICLE_ELECTRONIC_KEY_SUCCESS: null,
  UPDATE_VEHICLE_ELECTRONIC_KEY_FAILED: null,

  DELETE_VEHICLE_ELECTRONIC_KEY_REQUEST: null,
  DELETE_VEHICLE_ELECTRONIC_KEY_SUCCESS: null,
  DELETE_VEHICLE_ELECTRONIC_KEY_FAILED: null
})
