import keyMirror from 'keymirror'

export default keyMirror({
  GET_APP_VERSIONS_REQUEST: null,
  GET_APP_VERSIONS_SUCCESS: null,
  GET_APP_VERSIONS_FAILED: null,

  ADD_APP_VERSIONS_REQUEST: null,
  ADD_APP_VERSIONS_SUCCESS: null,
  ADD_APP_VERSIONS_FAILED: null,

  GET_APP_VERSIONS_DETAIL_REQUEST: null,
  GET_APP_VERSIONS_DETAIL_SUCCESS: null,
  GET_APP_VERSIONS_DETAIL_FAILED: null,

  UPDATE_APP_VERSIONS_FILTER_SUCCESS: null,

  UPDATE_APP_VERSIONS_REQUEST: null,
  UPDATE_APP_VERSIONS_SUCCESS: null,
  UPDATE_APP_VERSIONS_FAILED: null,

  DELETE_APP_VERSIONS_REQUEST: null,
  DELETE_APP_VERSIONS_SUCCESS: null,
  DELETE_APP_VERSIONS_FAILED: null,

  DELETE_APP_VERSIONS_TRANSLATION_REQUEST: null,
  DELETE_APP_VERSIONS_TRANSLATION_SUCCESS: null,
  DELETE_APP_VERSIONS_TRANSLATION_FAILED: null,

  TRANSLATE_APP_VERSIONS_REQUEST: null,
  TRANSLATE_APP_VERSIONS_SUCCESS: null,
  TRANSLATE_APP_VERSIONS_FAILED: null
})
