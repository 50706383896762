import keyMirror from 'keymirror'

export default keyMirror({
  GET_LANGUAGES_REQUEST: null,
  GET_LANGUAGES_SUCCESS: null,
  GET_LANGUAGES_FAILED: null,

  ADD_LANGUAGE_REQUEST: null,
  ADD_LANGUAGE_SUCCESS: null,
  ADD_LANGUAGE_FAILED: null,

  GET_LANGUAGE_DETAIL_REQUEST: null,
  GET_LANGUAGE_DETAIL_SUCCESS: null,
  GET_LANGUAGE_DETAIL_FAILED: null,

  UPDATE_LANGUAGE_REQUEST: null,
  UPDATE_LANGUAGE_SUCCESS: null,
  UPDATE_LANGUAGE_FAILED: null,

  DELETE_LANGUAGE_REQUEST: null,
  DELETE_LANGUAGE_SUCCESS: null,
  DELETE_LANGUAGE_FAILED: null,

  ADD_LANGUAGE_TRANSLATION_REQUEST: null,
  ADD_LANGUAGE_TRANSLATION_SUCCESS: null,
  ADD_LANGUAGE_TRANSLATION_FAILED: null,

  UPDATE_LANGUAGE_TRANSLATION_REQUEST: null,
  UPDATE_LANGUAGE_TRANSLATION_SUCCESS: null,
  UPDATE_LANGUAGE_TRANSLATION_FAILED: null,

  DELETE_LANGUAGE_TRANSLATION_REQUEST: null,
  DELETE_LANGUAGE_TRANSLATION_SUCCESS: null,
  DELETE_LANGUAGE_TRANSLATION_FAILED: null,

  GET_ACTIVE_LANGUAGES_REQUEST: null,
  GET_ACTIVE_LANGUAGES_SUCCESS: null,
  GET_ACTIVE_LANGUAGES_FAILED: null,

  UPDATE_FILTER_LANGUAGE_SUCCESS: null,
  CLEAR_FILTER_LANGUAGE_SUCCESS: null
})
