import RolesConfigurationTypes from '../action_types/rolesConfiguration'
import { rolesConfiguration as rolesConfigurationApi } from '../api'
import { bindRequestLogic } from './utils'

const getRolesConfiguration = bindRequestLogic({
  clientApi: rolesConfigurationApi,
  clientFunc: rolesConfigurationApi.getRolesConfiguration,
  type: RolesConfigurationTypes.GET_ROLES_CONFIGURATION_REQUEST,
  successType: RolesConfigurationTypes.GET_ROLES_CONFIGURATION_SUCCESS,
  failType: RolesConfigurationTypes.GET_ROLES_CONFIGURATION_FAILED,
  isQueryParam: true
})

const updateRolesConfiguration = bindRequestLogic({
  clientApi: rolesConfigurationApi,
  clientFunc: rolesConfigurationApi.updateRolesConfiguration,
  type: RolesConfigurationTypes.UPDATE_ROLES_CONFIGURATION_REQUEST,
  successType: RolesConfigurationTypes.UPDATE_ROLES_CONFIGURATION_SUCCESS,
  failType: RolesConfigurationTypes.GET_ROLES_CONFIGURATION_FAILED
})

export default [getRolesConfiguration, updateRolesConfiguration]
