import keyMirror from 'keymirror'

export default keyMirror({
  GET_MESSAGES_REQUEST: null,
  GET_MESSAGES_SUCCESS: null,
  GET_MESSAGES_FAILED: null,

  UPDATE_MESSAGES_REQUEST: null,
  UPDATE_MESSAGES_SUCCESS: null,
  UPDATE_MESSAGES_FAILED: null,

  CREATE_MESSAGE_REQUEST: null,
  CREATE_MESSAGE_SUCCESS: null,
  CREATE_MESSAGE_FAILED: null,

  DELETE_MESSAGE_REQUEST: null,
  DELETE_MESSAGE_SUCCESS: null,
  DELETE_MESSAGE_FAILED: null,

  GET_SYSTEM_MESSAGES_SUCCESS: null,

  IMPORT_MESSAGES_REQUEST: null,
  IMPORT_MESSAGES_SUCCESS: null,
  IMPORT_MESSAGES_FAILED: null,

  GET_SYSTEM_LABELS_SUCCESS: null,

  UPDATE_FILTER_MESSAGE_REQUEST: null,
  UPDATE_FILTER_MESSAGE_SUCCESS: null,
  CLEAR_FILTER_MESSAGE_REQUEST: null,
  CLEAR_FILTER_MESSAGE_SUCCESS: null
})
