import {
  DEFAULT_APP_USER_FILTER,
  DEFAULT_APP_USER_SUBSCRIPTIONS_FILTER,
  DEFAULT_APP_USER_VEHICLES_FILTER,
  USER_TYPE
} from 'constants/common'
import AppUserTypes from 'store/action_types/app-user'
import UserTypes from 'store/action_types/user'
import { replaceValueAtPath } from 'helpers/Utils'

const initUser = {
  info: {},
  error: null,
  list: [],
  roleUser: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  current: {},
  subscriptions: {
    list: [],
    filter: DEFAULT_APP_USER_SUBSCRIPTIONS_FILTER
  },
  vehicles: {
    list: [],
    filter: DEFAULT_APP_USER_VEHICLES_FILTER
  },
  filter: DEFAULT_APP_USER_FILTER,
  detailVehicleSharing: {},
  listVehicleSharingExtensions: {
    [USER_TYPE.SHARE]: [],
    [USER_TYPE.BENEFICIARY]: [],
    undefined: []
  },
  subscriptionAllowedPilots: []
}

const appUser = (state = initUser, action) => {
  switch (action.type) {
    case AppUserTypes.GET_APP_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case AppUserTypes.GET_APP_USER_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case AppUserTypes.UPDATE_FILTER_APP_USER_SUCCESS:
      return { ...state, filter: action.payload }
    case AppUserTypes.CLEAR_FILTER_APP_USER_SUCCESS:
      return { ...state, filter: initUser.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initUser
    case AppUserTypes.GET_ROLE_USERS_SUCCESS:
      return { ...state, roleUser: action.payload?.user }

    // ====== SHEET SUBSCRIPTIONS ======
    case AppUserTypes.GET_USER_SUBSCRIPTION_SUCCESS:
      return replaceValueAtPath(state, 'subscriptions.list', action.payload)
    case AppUserTypes.UPDATE_FILTER_USER_SUBSCRIPTION_SUCCESS:
      return replaceValueAtPath(state, 'subscriptions.filter', action.payload)
    case AppUserTypes.CLEAR_FILTER_USER_SUBSCRIPTION_SUCCESS:
      return replaceValueAtPath(
        state,
        'subscriptions.filter',
        DEFAULT_APP_USER_SUBSCRIPTIONS_FILTER
      )
    case AppUserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_SUCCESS:
      return {
        ...state,
        subscriptionAllowedPilots: action.payload?.subscriptionAllowedPilots
      }

    // ====== SHEET VEHICLES ======
    case AppUserTypes.GET_USER_VEHICLES_SUCCESS:
      return replaceValueAtPath(state, 'vehicles.list', action.payload)
    case AppUserTypes.UPDATE_FILTER_USER_VEHICLES_SUCCESS:
      return replaceValueAtPath(state, 'vehicles.filter', action.payload)
    case AppUserTypes.GET_LIST_INDIVIDUAL_VEHICLES_SHARING_EXTENSION_SUCCESS:
      return {
        ...state,
        listVehicleSharingExtensions: {
          ...state.listVehicleSharingExtensions,
          [action?.meta?.params?.userType]:
            action.payload?.vehicleSharings?.items
        }
      }
    case AppUserTypes.GET_DETAIL_INDIVIDUAL_VEHICLES_SHARING_SUCCESS:
      return { ...state, detailVehicleSharing: action.payload }

    default:
      return state
  }
}

export default appUser
