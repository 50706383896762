import UploadMediaTypes from '../action_types/uploadMedia'

import { uploadMedia as uploadMediaApi } from '../api'

import { bindRequestLogic } from './utils'

const uploadMedia = bindRequestLogic({
  clientApi: uploadMediaApi,
  clientFunc: uploadMediaApi.upload,
  type: UploadMediaTypes.UPLOAD_MEDIA_REQUEST,
  successType: UploadMediaTypes.UPLOAD_MEDIA_SUCCESS,
  failType: UploadMediaTypes.UPLOAD_MEDIA_FAILED
})

export default [uploadMedia]
