import BaseApi from './base'

class Currency extends BaseApi {
  getCurrencies = (query) => this.client.get(`admin/currencies?${query}`)

  addCurrency = (data) => this.client.post('admin/currencies', data)

  updateCurrency = (id, data) =>
    this.client.patch(`admin/currencies/${id}`, data)

  deleteCurrency = (id) => this.client.delete(`admin/currencies/${id}`)

  getCurrencyDetail = (id) => this.client.get(`admin/currencies/${id}`)

  upsertCurrencyTranslation = (id, data) =>
    this.client.post(`admin/currencies/${id}/translation`, data)

  deleteCurrencyTranslation = (id) =>
    this.client.delete(`admin/currencies/translation/${id}`)
}

export default new Currency()
