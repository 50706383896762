import keyMirror from 'keymirror'

export default keyMirror({
  GET_MAIL_TEMPLATES_REQUEST: null,
  GET_MAIL_TEMPLATES_SUCCESS: null,
  GET_MAIL_TEMPLATES_FAILED: null,

  ADD_MAIL_TEMPLATE_REQUEST: null,
  ADD_MAIL_TEMPLATE_SUCCESS: null,
  ADD_MAIL_TEMPLATE_FAILED: null,

  GET_MAIL_TEMPLATE_DETAIL_REQUEST: null,
  GET_MAIL_TEMPLATE_DETAIL_SUCCESS: null,
  GET_MAIL_TEMPLATE_DETAIL_FAILED: null,

  UPDATE_MAIL_TEMPLATE_REQUEST: null,
  UPDATE_MAIL_TEMPLATE_SUCCESS: null,
  UPDATE_MAIL_TEMPLATE_FAILED: null,

  DELETE_MAIL_TEMPLATE_REQUEST: null,
  DELETE_MAIL_TEMPLATE_SUCCESS: null,
  DELETE_MAIL_TEMPLATE_FAILED: null,

  UPSERT_MAIL_TEMPLATE_TRANSLATION_REQUEST: null,
  UPSERT_MAIL_TEMPLATE_TRANSLATION_SUCCESS: null,
  UPSERT_MAIL_TEMPLATE_TRANSLATION_FAILED: null,

  UPDATE_FILTER_MAIL_TEMPLATE_REQUEST: null,
  UPDATE_FILTER_MAIL_TEMPLATE_SUCCESS: null,
  CLEAR_FILTER_MAIL_TEMPLATE_REQUEST: null,
  CLEAR_FILTER_MAIL_TEMPLATE_SUCCESS: null,

  GET_MAIL_TEMPLATE_TOKENS_REQUEST: null,
  GET_MAIL_TEMPLATE_TOKENS_SUCCESS: null,
  GET_MAIL_TEMPLATE_TOKENS_FAILED: null
})
