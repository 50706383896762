import qs from 'qs'
import { getValueAtPath } from './Utils'
import { MAP_MODULE_TO_FILTER } from '../constants/common'

/**
 * Get module filter, used in selector
 * To get filter based on current module
 * @param state
 * @param featureName
 * @param defaultFilter
 * @returns {object} filter object
 */
export const getModuleFilter = (state, featureName, defaultFilter = {}) => {
  const currentModule = getValueAtPath(
    state,
    'store.systemConfig.currentModule'
  )

  return getValueAtPath(
    state,
    `store.${featureName}.${MAP_MODULE_TO_FILTER[currentModule]}`,
    defaultFilter
  )
}

export const stringifyFilterToQuery = (filter) => {
  return qs.stringify(filter, {
    arrayFormat: 'brackets'
  })
}

/** UPDATE STATE */
/**
 * Update module filter
 * Used for feature that has different filter for each module
 * such as: message, label, mail template, etc
 * @param state
 * @param payload
 */
export const updateModuleFilter = (state, payload) => ({
  ...state,
  [payload.key]: payload.value
})

/**
 * Clear module filter
 * Used for feature that has different filter for each module
 * such as: message, label, mail template, etc
 * @param state
 * @param payload
 * @param initData
 */
export const clearModuleFilter = (state, payload, initData) => ({
  ...state,
  [payload.key]: initData[payload.key]
})
