import { createLogic } from 'redux-logic'
import { getSystemMessageList } from 'store/selector/message'
import { getTranslationMessageByKey } from 'helpers/Utils'
import { STATUS_CODE, STORAGE_KEYS } from 'constants/common'
import { refreshUser as refreshUserApi } from 'store/api'
import { getRefreshToken } from 'store/selector/user'
import { stringifyFilterToQuery } from 'helpers/filter'

// import { STORAGE_KEYS } from './device';

// eslint-disable-next-line import/prefer-default-export
export const bindRequestLogic = ({
  clientApi = null,
  clientFunc,
  type,
  successType,
  failType,
  validate,
  isQueryParam = false,
  modelName = '',
  mapResponse
}) =>
  createLogic({
    type,
    latest: true,
    validate,

    async process({ action, getState }, dispatch, done) {
      try {
        if (clientApi) {
          clientApi.setSyncAccessToken()
        }
        const params = action.payload || []
        let res
        const { id, data } = params
        let paramFormatted = data || params
        if (isQueryParam) {
          paramFormatted = stringifyFilterToQuery(paramFormatted)
        }
        if (id) {
          res = await clientFunc(id, paramFormatted, action.meta)
        } else {
          res = await clientFunc(paramFormatted, action.meta)
        }
        const payload = modelName ? res?.data[modelName] : res?.data

        dispatch({
          type: successType,
          payload: mapResponse ? mapResponse(payload, params) : payload,
          meta: { params, ...action.meta },
          responseHeaders: res.headers
        })

        if (action.meta) {
          const { onSuccess } = action.meta

          if (onSuccess) {
            onSuccess({ response: res?.data })
          }
        }
      } catch (error) {
        let errorMessage = ''
        const data = error.response?.data
        if (data?.statusCode === STATUS_CODE.UNAUTHORIZE) {
          const refreshToken = getRefreshToken(getState())
          refreshUserApi.setRefreshToken(refreshToken)
          const refreshTokenResponse = await refreshUserApi.refreshToken()
          const { accessToken } = refreshTokenResponse?.data
          if (accessToken) {
            clientApi.setAccessToken(accessToken)
            localStorage.setItem(STORAGE_KEYS.accessToken, accessToken)
            dispatch({
              type,
              payload: action.payload,
              meta: action.meta
            })
          }
        } else {
          const message = data?.message
          const errorMessageList = getSystemMessageList(getState())
          errorMessage = getTranslationMessageByKey(
            message?.code,
            errorMessageList
          )
          if (!errorMessage) {
            errorMessage = message?.text
          }
          dispatch({ type: failType, payload: errorMessage, error: true })

          if (action.meta) {
            const { onError } = action.meta
            if (onError) {
              onError(errorMessage)
            }
          }
        }
      }
      done()
    }
  })
