import CountryTypes from '../action_types/country'

import { country as countryApi } from '../api'
import { bindRequestLogic } from './utils'

const getCountries = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.getCountries,
  type: CountryTypes.GET_COUNTRIES_REQUEST,
  successType: CountryTypes.GET_COUNTRIES_SUCCESS,
  failType: CountryTypes.GET_COUNTRIES_FAILED,
  isQueryParam: true,
  modelName: 'countries'
})

const addCountry = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.addCountry,
  type: CountryTypes.ADD_COUNTRY_REQUEST,
  successType: CountryTypes.ADD_COUNTRY_SUCCESS,
  failType: CountryTypes.ADD_COUNTRY_FAILED
})

const addCountryTranslation = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.addCountryTranslation,
  type: CountryTypes.ADD_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.ADD_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.ADD_COUNTRY_TRANSLATION_FAILED
})

const getCountryDetail = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.getCountryDetail,
  type: CountryTypes.GET_COUNTRY_DETAIL_REQUEST,
  successType: CountryTypes.GET_COUNTRY_DETAIL_SUCCESS,
  failType: CountryTypes.GET_COUNTRY_DETAIL_FAILED,
  modelName: 'country'
})

const deleteCountry = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.deleteCountry,
  type: CountryTypes.DELETE_COUNTRY_REQUEST,
  successType: CountryTypes.DELETE_COUNTRY_SUCCESS,
  failType: CountryTypes.DELETE_COUNTRY_FAILED
})

const updateCountry = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.updateCountry,
  type: CountryTypes.UPDATE_COUNTRY_REQUEST,
  successType: CountryTypes.UPDATE_COUNTRY_SUCCESS,
  failType: CountryTypes.UPDATE_COUNTRY_FAILED
})

const deleteCountryTranslation = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.deleteCountryTranslation,
  type: CountryTypes.DELETE_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.DELETE_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.DELETE_COUNTRY_TRANSLATION_FAILED
})

const getActiveCountries = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.getActiveCountries,
  type: CountryTypes.GET_ACTIVE_COUNTRIES_REQUEST,
  successType: CountryTypes.GET_ACTIVE_COUNTRIES_SUCCESS,
  failType: CountryTypes.GET_ACTIVE_COUNTRIES_FAILED,
  isQueryParam: true,
  modelName: 'countries'
})

const updateCountryTranslation = bindRequestLogic({
  clientApi: countryApi,
  clientFunc: countryApi.updateCountryTranslation,
  type: CountryTypes.UPDATE_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.UPDATE_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.UPDATE_COUNTRY_TRANSLATION_FAILED
})

export default [
  getCountries,
  addCountry,
  deleteCountry,
  getCountryDetail,
  updateCountry,
  deleteCountryTranslation,
  getActiveCountries,
  addCountryTranslation,
  updateCountryTranslation
]
