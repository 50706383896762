import ElectronicKeyTypes from '../action_types/electronicKey'

import { electronicKey as electronicKeyApi } from '../api'
import { bindRequestLogic } from './utils'

const getElectronicKeys = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.getElectronicKeys,
  type: ElectronicKeyTypes.GET_ELECTRONIC_KEYS_REQUEST,
  successType: ElectronicKeyTypes.GET_ELECTRONIC_KEYS_SUCCESS,
  failType: ElectronicKeyTypes.GET_ELECTRONIC_KEYS_FAILED,
  isQueryParam: true,
  modelName: 'electronicKey'
})

const addElectronicKey = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.addElectronicKey,
  type: ElectronicKeyTypes.ADD_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyTypes.ADD_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyTypes.ADD_ELECTRONIC_KEY_FAILED
})

const getElectronicKeyDetail = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.getElectronicKeyDetail,
  type: ElectronicKeyTypes.GET_ELECTRONIC_KEY_DETAIL_REQUEST,
  successType: ElectronicKeyTypes.GET_ELECTRONIC_KEY_DETAIL_SUCCESS,
  failType: ElectronicKeyTypes.GET_ELECTRONIC_KEY_DETAIL_FAILED,
  modelName: 'electronicKey'
})

const deleteElectronicKey = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.deleteElectronicKey,
  type: ElectronicKeyTypes.DELETE_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyTypes.DELETE_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyTypes.DELETE_ELECTRONIC_KEY_FAILED
})

const updateElectronicKey = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.updateElectronicKey,
  type: ElectronicKeyTypes.UPDATE_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyTypes.UPDATE_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyTypes.UPDATE_ELECTRONIC_KEY_FAILED
})

const getSheetVehicles = bindRequestLogic({
  clientApi: electronicKeyApi,
  clientFunc: electronicKeyApi.getSheetVehicle,
  type: ElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST,
  successType: ElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_SUCCESS,
  failType: ElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_FAILED,
  isQueryParam: true
})

export default [
  getElectronicKeys,
  addElectronicKey,
  getElectronicKeyDetail,
  deleteElectronicKey,
  updateElectronicKey,
  getSheetVehicles
]
