import { DEFAULT_MESSAGE_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import MessageTypes from 'store/action_types/message'
import UserTypes from 'store/action_types/user'
import { clearModuleFilter, updateModuleFilter } from '../../helpers/filter'

const initMessage = {
  list: [],
  // using to translate all label in system
  systemList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_MESSAGE_FILTER,
  scwFilter: { ...DEFAULT_MESSAGE_FILTER },
  mobileFilter: { ...DEFAULT_MESSAGE_FILTER },
  fleetFilter: { ...DEFAULT_MESSAGE_FILTER }
}

const label = (state = initMessage, action) => {
  switch (action.type) {
    case MessageTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        list: action?.payload?.items,
        pagination: action.payload?.meta
      }
    case MessageTypes.GET_SYSTEM_MESSAGES_SUCCESS:
      return { ...state, systemList: action.payload }
    case MessageTypes.UPDATE_FILTER_MESSAGE_SUCCESS:
      return updateModuleFilter(state, action.payload)
    case MessageTypes.CLEAR_FILTER_MESSAGE_SUCCESS:
      return clearModuleFilter(state, action.payload, initMessage)
    case UserTypes.LOGOUT_SUCCESS:
      return initMessage
    default:
      return state
  }
}

export default label
