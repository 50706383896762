import RoleTypes from '../action_types/role'

import { role as roleApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapPermissions, mapRoleDetail } from '../mapping'

const getRoles = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.getRoles,
  type: RoleTypes.GET_ROLES_REQUEST,
  successType: RoleTypes.GET_ROLES_SUCCESS,
  failType: RoleTypes.GET_ROLES_FAILED,
  isQueryParam: true
})

const addRole = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.addRole,
  type: RoleTypes.ADD_ROLE_REQUEST,
  successType: RoleTypes.ADD_ROLE_SUCCESS,
  failType: RoleTypes.ADD_ROLE_FAILED
})

const getRoleDetail = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.getRoleDetail,
  type: RoleTypes.GET_ROLE_DETAIL_REQUEST,
  successType: RoleTypes.GET_ROLE_DETAIL_SUCCESS,
  failType: RoleTypes.GET_ROLE_DETAIL_FAILED,
  modelName: 'role',
  mapResponse: mapRoleDetail
})

const deleteRole = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.deleteRole,
  type: RoleTypes.DELETE_ROLE_REQUEST,
  successType: RoleTypes.DELETE_ROLE_SUCCESS,
  failType: RoleTypes.DELETE_ROLE_FAILED
})

const updateRole = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.updateRole,
  type: RoleTypes.UPDATE_ROLE_REQUEST,
  successType: RoleTypes.UPDATE_ROLE_SUCCESS,
  failType: RoleTypes.UPDATE_ROLE_FAILED
})

const getGroups = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.getGroups,
  type: RoleTypes.GET_GROUPS_REQUEST,
  successType: RoleTypes.GET_GROUPS_SUCCESS,
  failType: RoleTypes.GET_GROUPS_FAILED,
  isQueryParam: true
})

const getPermissions = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.getPermissions,
  type: RoleTypes.GET_PERMISSIONS_REQUEST,
  successType: RoleTypes.GET_PERMISSIONS_SUCCESS,
  failType: RoleTypes.GET_PERMISSIONS_FAILED,
  isQueryParam: true,
  mapResponse: mapPermissions
})

const deleteMultipleRole = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.deleteMultipleRole,
  type: RoleTypes.DELETE_MULTIPLE_ROLE_REQUEST,
  successType: RoleTypes.DELETE_MULTIPLE_ROLE_SUCCESS,
  failType: RoleTypes.DELETE_MULTIPLE_ROLE_FAILED
})

const addRoleTranslation = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.addRoleTranslation,
  type: RoleTypes.ADD_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.ADD_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.ADD_ROLE_TRANSLATION_FAILED
})

const updateRoleTranslation = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.updateRoleTranslation,
  type: RoleTypes.UPDATE_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.UPDATE_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.UPDATE_ROLE_TRANSLATION_FAILED
})

const deleteRoleTranslation = bindRequestLogic({
  clientApi: roleApi,
  clientFunc: roleApi.deleteRoleTranslation,
  type: RoleTypes.DELETE_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.DELETE_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.DELETE_ROLE_TRANSLATION_FAILED
})

export default [
  getRoles,
  addRole,
  getRoleDetail,
  updateRole,
  deleteRole,
  getGroups,
  getPermissions,
  deleteMultipleRole,
  addRoleTranslation,
  updateRoleTranslation,
  deleteRoleTranslation
]
