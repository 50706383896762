import SystemConfigTypes from 'store/action_types/systemConfig'

import UserTypes from 'store/action_types/user'
import { replaceValueAtPath } from '../../helpers/Utils'

const initSystemConfig = {
  list: [],
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  modules: [],
  currentModule: '',
  vehicleBrands: [],
  vehicleModels: []
}

const systemConfig = (state = initSystemConfig, action) => {
  switch (action.type) {
    case SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case SystemConfigTypes.GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload?.items
      }
    case SystemConfigTypes.GET_ALL_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        vehicleBrands: action.payload?.items
      }
    case SystemConfigTypes.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_SUCCESS:
      return {
        ...state,
        vehicleModels: action.payload?.items
      }
    case SystemConfigTypes.UPDATE_CURRENT_MODULES_SUCCESS:
      return replaceValueAtPath(state, 'currentModule', action.payload)
    case UserTypes.LOGOUT_SUCCESS:
      return initSystemConfig
    default:
      return state
  }
}

export default systemConfig
