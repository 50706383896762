import BaseApi from './base'

class SystemConfig extends BaseApi {
  getSystemConfigs = (query) => this.client.get(`admin/system-config?${query}`)

  updateSystemConfig = (id, data) =>
    this.client.patch(`admin/system-config/${id}`, data)

  updateSystemConfigs = (data) =>
    this.client.post(`admin/system-config/bulk-update`, data)

  getAllSystemConfigs = () =>
    this.client.get(`admin/system/system-config?limit=0`)

  getModules = () => this.client.get(`module`)
}

export default new SystemConfig()
