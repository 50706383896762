import { getValueAtPath } from 'helpers/Utils'

export const mapUserSubscriptions = (response) => {
  return response.items.map((item) => {
    const vehicle = getValueAtPath(item, 'subscriptionVehicle.vehicle', {})

    return {
      subscriptionId: item.id,
      subscriptionRef: item.referenceSubscription,
      dtBegin: item.dtBeginPeriod,
      dtEnd: item.dtEndPeriod,
      status: item.status,
      odooId: item.subscriptionOdooId,
      vehicle: {
        name: vehicle.nameVehicle,
        registrationNumber: vehicle.registrationNumber,
        serialNumber: vehicle.serialNumber,
        model: getValueAtPath(vehicle, 'vehicleModel.nameVehicleModel', ''),
        brand: getValueAtPath(vehicle, 'brand.name', ''),
        status: getValueAtPath(item, 'subscriptionVehicle.status', ''),
        odooId: vehicle.odooVehicleId,
        type: vehicle.vehicleType
      }
    }
  })
}

export const mapUserVehicles = (response) => {
  const items = getValueAtPath(response, 'vehicleUsers.items', [])

  return items.map((item) => {
    const vehicle = getValueAtPath(item, 'vehicle', {})
    return {
      id: item.idVehicleUser,
      vehicleAvatar: vehicle.avatar,
      vehicleName: vehicle.nameVehicle,
      vehicleType: vehicle.vehicleType,
      registrationNumber: vehicle.registrationNumber,
      serialNumber: vehicle.serialNumber,
      vehicleModel: getValueAtPath(
        vehicle,
        'vehicleModel.nameVehicleModel',
        ''
      ),
      brand: getValueAtPath(vehicle, 'brand.name', ''),
      vehicleOdooId: vehicle.odooVehicleId,
      tokenVehicleUser: item.tokenVehicleUser,
      dtLastGenerationToken: item.dtLastGenerationToken,
      dtExpirationToken: item.dtExpirationToken,
      dtVehicleLastRefresh: item.dtVehicleLastRefresh,
      dtUserLastRefresh: item.dtUserLastRefresh,
      dtUserLastRefreshPinBLE: item.dtUserLastRefreshPinBLE,
      dtBegin: item.dtBegin,
      dtEnd: item.dtEnd,
      lastUpdated: item.dtLastUpdate
    }
  })
}
