import AppVersionsTypes from '../action_types/appVersions'

import { appVersions as appVersionsApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapAppVersionDetail, mapAppVersions } from '../mapping'

const getAppVersions = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.getAppVersions,
  type: AppVersionsTypes.GET_APP_VERSIONS_REQUEST,
  successType: AppVersionsTypes.GET_APP_VERSIONS_SUCCESS,
  failType: AppVersionsTypes.GET_APP_VERSIONS_FAILED,
  isQueryParam: true,
  modelName: 'appVersions',
  mapResponse: mapAppVersions
})

const getAppVersionsDetail = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.getAppVersionsDetail,
  type: AppVersionsTypes.GET_APP_VERSIONS_DETAIL_REQUEST,
  successType: AppVersionsTypes.GET_APP_VERSIONS_DETAIL_SUCCESS,
  failType: AppVersionsTypes.GET_APP_VERSIONS_DETAIL_FAILED,
  modelName: 'appVersion',
  mapResponse: mapAppVersionDetail
})

const addAppVersions = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.addAppVersions,
  type: AppVersionsTypes.ADD_APP_VERSIONS_REQUEST,
  successType: AppVersionsTypes.ADD_APP_VERSIONS_SUCCESS,
  failType: AppVersionsTypes.ADD_APP_VERSIONS_FAILED
})

const deleteAppVersions = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.deleteAppVersions,
  type: AppVersionsTypes.DELETE_APP_VERSIONS_REQUEST,
  successType: AppVersionsTypes.DELETE_APP_VERSIONS_SUCCESS,
  failType: AppVersionsTypes.DELETE_APP_VERSIONS_FAILED
})

const updateAppVersions = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.updateAppVersions,
  type: AppVersionsTypes.UPDATE_APP_VERSIONS_REQUEST,
  successType: AppVersionsTypes.UPDATE_APP_VERSIONS_SUCCESS,
  failType: AppVersionsTypes.UPDATE_APP_VERSIONS_FAILED
})

const translateAppVersions = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.appVersionsTranslation,
  type: AppVersionsTypes.TRANSLATE_APP_VERSIONS_REQUEST,
  successType: AppVersionsTypes.TRANSLATE_APP_VERSIONS_SUCCESS,
  failType: AppVersionsTypes.TRANSLATE_APP_VERSIONS_FAILED
})

const deleteAppVersionsTranslation = bindRequestLogic({
  clientApi: appVersionsApi,
  clientFunc: appVersionsApi.deleteAppVersionsTranslation,
  type: AppVersionsTypes.DELETE_APP_VERSIONS_TRANSLATION_REQUEST,
  successType: AppVersionsTypes.DELETE_APP_VERSIONS_TRANSLATION_SUCCESS,
  failType: AppVersionsTypes.DELETE_APP_VERSIONS_TRANSLATION_FAILED
})

export default [
  getAppVersions,
  getAppVersionsDetail,
  translateAppVersions,
  addAppVersions,
  updateAppVersions,
  deleteAppVersions,
  deleteAppVersionsTranslation
]
