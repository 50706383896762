import { combineReducers } from 'redux'
import LanguageTypes from 'store/action_types/language'
import { createHandleRequest } from './helpers'

const getLanguages = createHandleRequest(
  LanguageTypes.GET_LANGUAGES_REQUEST,
  LanguageTypes.GET_LANGUAGES_SUCCESS,
  LanguageTypes.GET_LANGUAGES_FAILED
)

const getActiveLanguages = createHandleRequest(
  LanguageTypes.GET_ACTIVE_LANGUAGES_REQUEST,
  LanguageTypes.GET_ACTIVE_LANGUAGES_SUCCESS,
  LanguageTypes.GET_ACTIVE_LANGUAGES_FAILED
)

export default combineReducers({
  getLanguages,
  getActiveLanguages
})
