import keyMirror from 'keymirror'

export default keyMirror({
  GET_MESSAGE_AFTER_CONNECTIONS_REQUEST: null,
  GET_MESSAGE_AFTER_CONNECTIONS_SUCCESS: null,
  GET_MESSAGE_AFTER_CONNECTIONS_FAILED: null,

  GET_MESSAGE_AFTER_CONNECTION_DETAIL_REQUEST: null,
  GET_MESSAGE_AFTER_CONNECTION_DETAIL_SUCCESS: null,
  GET_MESSAGE_AFTER_CONNECTION_DETAIL_FAILED: null,

  ADD_MESSAGE_AFTER_CONNECTION_REQUEST: null,
  ADD_MESSAGE_AFTER_CONNECTION_SUCCESS: null,
  ADD_MESSAGE_AFTER_CONNECTION_FAILED: null,

  UPDATE_MESSAGE_AFTER_CONNECTION_REQUEST: null,
  UPDATE_MESSAGE_AFTER_CONNECTION_SUCCESS: null,
  UPDATE_MESSAGE_AFTER_CONNECTION_FAILED: null,

  DELETE_MESSAGE_AFTER_CONNECTION_REQUEST: null,
  DELETE_MESSAGE_AFTER_CONNECTION_SUCCESS: null,
  DELETE_MESSAGE_AFTER_CONNECTION_FAILED: null,

  UPDATE_MESSAGE_AFTER_CONNECTION_FILTER_SUCCESS: null,

  DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_REQUEST: null,
  DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_SUCCESS: null,
  DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_FAILED: null,

  TRANSLATE_MESSAGE_AFTER_CONNECTION_REQUEST: null,
  TRANSLATE_MESSAGE_AFTER_CONNECTION_SUCCESS: null,
  TRANSLATE_MESSAGE_AFTER_CONNECTION_FAILED: null
})
