import ShowcaseSectionTypes from '../action_types/showcaseSection'

import { showcaseSection as showcaseSectionApi } from '../api'
import { bindRequestLogic } from './utils'

const getShowcaseSections = bindRequestLogic({
  clientApi: showcaseSectionApi,
  clientFunc: showcaseSectionApi.getShowcaseSections,
  type: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_REQUEST,
  successType: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_SUCCESS,
  failType: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_FAILED,
  isQueryParam: true
})

const addShowcaseSection = bindRequestLogic({
  clientApi: showcaseSectionApi,
  clientFunc: showcaseSectionApi.addShowcaseSection,
  type: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_FAILED
})

const deleteShowcaseSection = bindRequestLogic({
  clientApi: showcaseSectionApi,
  clientFunc: showcaseSectionApi.deleteShowcaseSection,
  type: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_FAILED
})

const updateShowcaseSection = bindRequestLogic({
  clientApi: showcaseSectionApi,
  clientFunc: showcaseSectionApi.updateShowcaseSection,
  type: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_FAILED
})

const getShowcaseSection = bindRequestLogic({
  clientApi: showcaseSectionApi,
  clientFunc: showcaseSectionApi.getShowcaseSection,
  type: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_REQUEST,
  successType: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_SUCCESS,
  failType: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_FAILED
})

export default [
  getShowcaseSections,
  addShowcaseSection,
  deleteShowcaseSection,
  updateShowcaseSection,
  getShowcaseSection
]
