import { getValueAtPath } from 'helpers/Utils'
import { SUBSCRIPTION_TYPES } from 'constants/common'

export const mapVehicleSubscriptionsResponse = (response) => {
  const getCompanyMemberName = (item) => {
    const userCompany = getValueAtPath(item, 'userCompany', {})
    return `${userCompany.firstName || ''} ${userCompany.lastName || ''}`
  }

  const items = getValueAtPath(response, 'subscription', [])

  return items.map((item) => {
    const isCompany = item.subscriptionType === SUBSCRIPTION_TYPES.COMPANY
    const company = getValueAtPath(item, 'company', {})
    const userApp = getValueAtPath(item, 'userApp', {})
    const subscriptionVehicle = getValueAtPath(item, 'subscriptionVehicle', {})

    return {
      subscriptionId: item.id,
      isCompany,
      avatar: isCompany ? company.avatar : userApp.avatar,
      userName: isCompany ? getCompanyMemberName(item) : userApp.name,
      companyName: company.companyName,
      companyCity: company.cityAddress,
      companyCountry: company.countryAddressId,
      subscriptionRef: item.referenceSubscription,
      subDtBegin: item.dtBeginPeriod,
      subDtEnd: item.dtEndPeriod,
      odooId: item.subscriptionOdooId,
      subStatus: item.status,
      vehicleSubscriptionsStatus: subscriptionVehicle.status,
      vehicleSubscriptionsDtBegin: subscriptionVehicle.dtBegin,
      vehicleSubscriptionsDtEnd: subscriptionVehicle.dtEnd
    }
  })
}

export const mapVehicleUserResponse = (response) => {
  const items = getValueAtPath(response, 'vehicleUsers.items', [])

  return items.map((item) => {
    const userInfo = {
      avatar: '',
      name: '',
      email: '',
      odoo: ''
    }

    // if there is companyMemberId, it means that the user is a company member
    // so get data from userCompany
    if (item.companyMemberId) {
      const userCompany = getValueAtPath(item, 'userCompany', {})
      userInfo.avatar = getValueAtPath(userCompany, 'userApp.avatar', '')
      userInfo.name = getValueAtPath(userCompany, 'userApp.name', '')
      userInfo.email = getValueAtPath(userCompany, 'email', '')
      userInfo.odoo = getValueAtPath(userCompany, 'userApp.odooUserId', '')
    } else {
      userInfo.avatar = getValueAtPath(item, 'user.avatar', '')
      userInfo.name = getValueAtPath(item, 'user.name', '')
      userInfo.email = getValueAtPath(item, 'user.email', '')
      userInfo.odoo = getValueAtPath(item, 'user.odooUserId', '')
    }
    return {
      id: item.id,
      userAvatar: userInfo.avatar,
      userName: userInfo.name,
      userEmail: userInfo.email,
      userOdooId: userInfo.odoo,
      tokenVehicleUser: item.tokenVehicleUser,
      dtLastGenerationToken: item.dtLastGenerationToken,
      dtExpirationToken: item.dtExpirationToken,
      dtVehicleLastRefresh: item.dtVehicleLastRefresh,
      dtUserLastRefresh: item.dtUserLastRefresh,
      dtUserLastRefreshPinBLE: item.dtUserLastRefreshPinBLE,
      dtBegin: item.dtBegin,
      dtEnd: item.dtEnd,
      lastUpdated: item.updatedAt
    }
  })
}
