import {
  DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER,
  DEFAULT_ELECTRONIC_KEY_FILTER
} from 'constants/common'
import ElectronicKeyTypes from 'store/action_types/electronicKey'
import UserTypes from 'store/action_types/user'

const initElectronicKey = {
  list: [],
  activeList: [],
  current: {},
  pagination: {
    currentPage: 1,
    totalPages: 1
  },
  filter: DEFAULT_ELECTRONIC_KEY_FILTER,
  vehicleList: [],
  vehiclePagination: [],
  vehicleFilter: DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER
}

const electronicKey = (state = initElectronicKey, action) => {
  switch (action.type) {
    case ElectronicKeyTypes.GET_ELECTRONIC_KEYS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case ElectronicKeyTypes.GET_ELECTRONIC_KEY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case ElectronicKeyTypes.UPDATE_FILTER_ELECTRONIC_KEY_SUCCESS:
      return { ...state, filter: action.payload }
    case ElectronicKeyTypes.CLEAR_FILTER_ELECTRONIC_KEY_SUCCESS:
      return { ...state, filter: initElectronicKey.filter }
    case ElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_SUCCESS:
      return {
        ...state,
        vehicleList: action.payload?.sheetVehicle?.items,
        vehiclePagination: action.payload?.sheetVehicle?.meta
      }
    case ElectronicKeyTypes.UPDATE_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS:
      return { ...state, vehicleFilter: action.payload }
    case ElectronicKeyTypes.CLEAR_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS:
      return { ...state, vehicleFilter: initElectronicKey.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initElectronicKey
    default:
      return state
  }
}

export default electronicKey
