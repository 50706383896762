import BaseApi from './base'

class Country extends BaseApi {
  getCountries = (query) => this.client.get(`admin/countries?${query}`)

  addCountry = (data) => this.client.post('admin/countries', data)

  updateCountry = (id, data) => this.client.patch(`admin/countries/${id}`, data)

  deleteCountry = (id) => this.client.delete(`admin/countries/${id}`)

  getCountryDetail = (id) => this.client.get(`admin/countries/${id}`)

  addCountryTranslation = (id, data) =>
    this.client.post(`admin/countries/${id}/translation`, data)

  updateCountryTranslation = (id, data) =>
    this.client.patch(
      `admin/countries/${id}/translation/${data.translationId}`,
      data
    )

  deleteCountryTranslation = (id, translationId) =>
    this.client.delete(`admin/countries/${id}/translation/${translationId}`)

  getActiveCountries = (query) => this.client.get(`/countries?limit=0${query}`)
}
export default new Country()
