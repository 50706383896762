import { createLogic } from 'redux-logic'
import UserTypes from 'store/action_types/user'
import { refreshUser as refreshUserApi, user as userApi } from 'store/api'
import { STORAGE_KEYS } from 'constants/common'
import { getRefreshToken } from 'store/selector/user'
import { bindRequestLogic } from './utils'

const login = createLogic({
  type: UserTypes.LOGIN_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    try {
      const { payload } = action
      const userResponse = await userApi.loginUser(payload)
      const { accessToken, refreshToken, success } = userResponse?.data
      if (success && accessToken) {
        userApi.setAccessToken(accessToken)
        userApi.setRefreshToken(refreshToken)
        localStorage.setItem(STORAGE_KEYS.accessToken, accessToken)
        localStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken)
      }
      dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        payload: { accessToken, refreshToken }
      })
    } catch (error) {
      dispatch({
        type: UserTypes.LOGIN_FAILURE,
        payload: error.response,
        meta: {}
      })
    }
    done()
  }
})

const logout = createLogic({
  type: UserTypes.LOGOUT_REQUEST,
  latest: true,

  // eslint-disable-next-line no-unused-vars
  async process({ action, getState }, dispatch, done) {
    // console.log('🚀 ~ file: user.js ~ line 238 ~ process ~ action', action);
    try {
      const refreshToken = getRefreshToken(getState())
      refreshUserApi.setRefreshToken(refreshToken)
      const userResponse = await refreshUserApi.logoutUser()
      dispatch({
        type: UserTypes.LOGOUT_SUCCESS,
        payload: userResponse
      })
    } catch (error) {
      dispatch({
        type: UserTypes.LOGOUT_FAILURE,
        payload: error.message,
        meta: {}
      })
    }
    done()
  }
})

const getUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getUsers,
  type: UserTypes.GET_USERS_REQUEST,
  successType: UserTypes.GET_USERS_SUCCESS,
  failType: UserTypes.GET_USERS_FAILED,
  isQueryParam: true,
  modelName: 'users'
})

const deleteUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.deleteUser,
  type: UserTypes.DELETE_USER_REQUEST,
  successType: UserTypes.DELETE_USER_SUCCESS,
  failType: UserTypes.DELETE_USER_FAILED
})

const getUserDetail = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.getUserDetail,
  type: UserTypes.GET_USER_DETAIL_REQUEST,
  successType: UserTypes.GET_USER_DETAIL_SUCCESS,
  failType: UserTypes.GET_USER_DETAIL_FAILED,
  modelName: 'user'
})

const addUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.addUser,
  type: UserTypes.ADD_USER_REQUEST,
  successType: UserTypes.ADD_USER_SUCCESS,
  failType: UserTypes.ADD_USER_FAILED
})

const updateUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.updateUser,
  type: UserTypes.UPDATE_USER_REQUEST,
  successType: UserTypes.UPDATE_USER_SUCCESS,
  failType: UserTypes.UPDATE_USER_FAILED
})

const updateStatusUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.updateStatusUser,
  type: UserTypes.UPDATE_STATUS_USER_REQUEST,
  successType: UserTypes.UPDATE_STATUS_USER_SUCCESS,
  failType: UserTypes.UPDATE_STATUS_USER_FAILED
})

const updatePasswordUser = bindRequestLogic({
  clientApi: userApi,
  clientFunc: userApi.updatePasswordUser,
  type: UserTypes.UPDATE_PASSWORD_USER_REQUEST,
  successType: UserTypes.UPDATE_PASSWORD_USER_SUCCESS,
  failType: UserTypes.UPDATE_PASSWORD_USER_FAILED
})

export default [
  login,
  getUser,
  deleteUser,
  getUserDetail,
  addUser,
  updateUser,
  logout,
  updateStatusUser,
  updatePasswordUser
]
