import { mapCurrencies, mapCurrencyDetail } from 'store/mapping'
import CurrencyTypes from '../action_types/currency'

import { currency as currencyApi } from '../api'
import { bindRequestLogic } from './utils'

const getCurrencies = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.getCurrencies,
  type: CurrencyTypes.GET_CURRENCIES_REQUEST,
  successType: CurrencyTypes.GET_CURRENCIES_SUCCESS,
  failType: CurrencyTypes.GET_CURRENCIES_FAILED,
  isQueryParam: true,
  mapResponse: mapCurrencies
})

const addCurrency = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.addCurrency,
  type: CurrencyTypes.ADD_CURRENCY_REQUEST,
  successType: CurrencyTypes.ADD_CURRENCY_SUCCESS,
  failType: CurrencyTypes.ADD_CURRENCY_FAILED
})

const getCurrencyDetail = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.getCurrencyDetail,
  type: CurrencyTypes.GET_CURRENCY_DETAIL_REQUEST,
  successType: CurrencyTypes.GET_CURRENCY_DETAIL_SUCCESS,
  failType: CurrencyTypes.GET_CURRENCY_DETAIL_FAILED,
  mapResponse: mapCurrencyDetail
})

const updateCurrency = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.updateCurrency,
  type: CurrencyTypes.UPDATE_CURRENCY_REQUEST,
  successType: CurrencyTypes.UPDATE_CURRENCY_SUCCESS,
  failType: CurrencyTypes.UPDATE_CURRENCY_FAILED
})

const deleteCurrency = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.deleteCurrency,
  type: CurrencyTypes.DELETE_CURRENCY_REQUEST,
  successType: CurrencyTypes.DELETE_CURRENCY_SUCCESS,
  failType: CurrencyTypes.DELETE_CURRENCY_FAILED
})

const upsertCurrencyTranslation = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.upsertCurrencyTranslation,
  type: CurrencyTypes.ADD_CURRENCY_TRANSLATION_REQUEST,
  successType: CurrencyTypes.ADD_CURRENCY_TRANSLATION_SUCCESS,
  failType: CurrencyTypes.ADD_CURRENCY_TRANSLATION_FAILED
})

const deleteCurrencyTranslation = bindRequestLogic({
  clientApi: currencyApi,
  clientFunc: currencyApi.deleteCurrencyTranslation,
  type: CurrencyTypes.DELETE_CURRENCY_TRANSLATION_REQUEST,
  successType: CurrencyTypes.DELETE_CURRENCY_TRANSLATION_SUCCESS,
  failType: CurrencyTypes.DELETE_CURRENCY_TRANSLATION_FAILED
})

export default [
  getCurrencies,
  addCurrency,
  getCurrencyDetail,
  updateCurrency,
  deleteCurrency,
  upsertCurrencyTranslation,
  deleteCurrencyTranslation
]
