import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogicMiddleware } from 'redux-logic'
import { composeWithDevTools } from 'redux-devtools-extension'

import Axios from 'axios'
import rootReducer from './reducers'
import logics from './logics'

const persistConfig = {
  key: 'store',
  storage,
  whitelist: ['store'] // which reducer want to store
}

const logicMiddleware = createLogicMiddleware(logics, 'root')
const addCancelToken = () => (next) => (action) => {
  if (action.meta?.cancelable) {
    const { CancelToken } = Axios
    // eslint-disable-next-line no-param-reassign
    action.meta.cancelSource = CancelToken.source()
  }

  return next(action)
}

const pReducer = persistReducer(persistConfig, rootReducer)
const middleware = composeWithDevTools(
  applyMiddleware(addCancelToken, thunkMiddleware, logicMiddleware)
)
const store = createStore(pReducer, middleware)
const persistor = persistStore(store)
export { persistor, store }
