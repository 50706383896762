/* eslint-disable import/no-cycle */
import {
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from 'constants/defaultValues'
import moment from 'moment-timezone'
// eslint-disable-next-line import/no-cycle
import {
  COMPANY_DETAIL_BUTTONS,
  COMPANY_STATUSES,
  COMPANY_TYPES,
  DEFAULT_LANGUAGE_ISO_CODE,
  FORMAT_SORT_DATE,
  GENDER_LIST,
  LANGUAGE_LIST,
  MAPPING_LANGUAGE,
  MAPPING_MAIN_PICTURE_FILE_TYPES,
  MAPPING_URL_TO_PERMISSIONS,
  PILOT_STATUSES,
  PREFIX_BASE64,
  PREFIX_IMAGE_BASE64,
  SECTION_PAGE_LIST,
  SECTION_PAGE_VALUES,
  SORT_ASC,
  SUBSCRIBER_TYPES,
  SUBSCRIPTION_STATUSES,
  VEHICLE_TYPE_LIST
} from 'constants/common'
import ascImg from 'assets/img/sort/asc.svg'
import descImg from 'assets/img/sort/desc.svg'
import noneImg from 'assets/img/sort/none.svg'
import defaultUser from 'assets/img/default-user.png'
import defaultCompany from 'assets/img/default-company.png'
import defaultCompanySelection from 'assets/img/default-company-selection.png'
import { hasFlag } from 'country-flag-icons'
import DOMPurify from 'dompurify'
import { toast } from 'react-toastify'

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1
    }
    return -1
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${dd}.${mm}.${yyyy}`
}

export const getCurrentTime = () => {
  const now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}

export const toDateTime = (date) => {
  if (date) {
    return new Date(date)
  }
  return null
}

export const toDateString = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    return moment(toDateTime(date)).format(format)
  }
  return null
}

export const formatDateTime = (date, format = FORMAT_SORT_DATE) => {
  if (date) {
    return moment(new Date(date)).format(format)
  }
  return null
}

export const getLastMonth = (month) => {
  let lastMonth = moment(new Date()).subtract(month, 'month')

  lastMonth = lastMonth.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })
  return lastMonth.toDate()
}

export const getDirection = () => {
  let direction = defaultDirection

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction')
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue
      }
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  }
}
export const getCurrentColor = () => {
  let currentColor = defaultColor
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey)
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor
  }
  return currentColor
}

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color)
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
}

export const getCurrentRadius = () => {
  let currentRadius = 'rounded'
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey)
    }
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
    //   error
    // );
    currentRadius = 'rounded'
  }
  return currentRadius
}
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius)
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
    //   error
    // );
  }
}

export const getCurrentLanguage = () => {
  let language = defaultLocale
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
    //   error
    // );
    language = defaultLocale
  }
  return language
}
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale)
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
    //   error
    // );
  }
}

export const getCurrentUser = () => {
  let user = null
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null
  }
  return user
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('gogo_current_user')
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
}

export const showImageAsBase64 = (flag) => {
  if (flag.includes(PREFIX_BASE64)) {
    return `${flag}`
  }
  return `${PREFIX_IMAGE_BASE64},  ${flag}`
}

export const chunk = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

export const getFlagFromName = (name) => {
  return LANGUAGE_LIST.find((item) => item.label === name)
}

export const convertListToOptions = (data) => {
  return data.map((item) => {
    return {
      isDefault: item.isDefault,
      value: item.isoCode,
      label: item.name,
      id: item.id
    }
  })
}

export const convertListLanguageToOptions = (data) => {
  return data.map((item) => {
    const languageItem = getFlagFromName(item.name)
    return {
      id: item.id,
      value: item.isoCode,
      label: item.name,
      nativeName: item.nativeName,
      flag: languageItem?.flag
    }
  })
}

export const getTranslationNameById = (translations, translationId) => {
  return translations.find((item) => item.id === translationId)
}

export const checkDisableApply = (users) => {
  const user = users.find((item) => item.isChecked === true)
  return !!user
}

export const getGender = (genderId) => {
  const selectedGender = GENDER_LIST.find((v) => +v.value === +genderId)
  if (!selectedGender) {
    return ''
  }
  return selectedGender.label
}

export const getSortIcon = (sortData) => {
  if (!sortData) {
    return noneImg
  }
  return sortData === SORT_ASC ? ascImg : descImg
}

export const getSortClass = (sortType, sortData, sortConstant) => {
  if (sortType !== sortConstant) {
    return ''
  }
  return sortData === sortConstant ? 'sort-asc' : 'sort-desc'
}

export const generateArray = (start, end) => {
  return Array.from({ length: end - start }, (_, i) => start + 1 + i)
}

export const getPageList = (pagination) => {
  if (!pagination) {
    return []
  }
  const { currentPage, totalPages } = pagination
  let pageListArr = generateArray(0, totalPages)
  let pageListSplit = chunk(pageListArr, 5)
  const lastPage = totalPages - 1
  if (currentPage >= 4) {
    if (currentPage === lastPage) {
      const start = currentPage - 2
      pageListArr = generateArray(start, totalPages)
      pageListSplit = chunk(pageListArr, 3)
    } else {
      const start = currentPage - 3
      pageListArr = generateArray(start, totalPages)
      pageListSplit = chunk(pageListArr, 5)
    }
  }
  return pageListSplit.find((item) => item.includes(currentPage))
}

export const getPageListForSection = (pagination) => {
  if (!pagination) {
    return []
  }
  const { currentPage: offset, totalPages: total } = pagination
  const totalPages = total - 1
  const currentPage = offset / 10
  let pageListArr = generateArray(-1, totalPages)
  let pageListSplit = chunk(pageListArr, 5)
  const lastPage = totalPages
  if (currentPage >= 4) {
    if (currentPage === lastPage) {
      const start = currentPage - 2
      pageListArr = generateArray(start, totalPages)
      pageListSplit = chunk(pageListArr, 3)
    } else {
      const start = currentPage - 3
      pageListArr = generateArray(start, totalPages)
      pageListSplit = chunk(pageListArr, 5)
    }
  }
  return pageListSplit.find((item) => item.includes(currentPage))
}

export const getSelectedItemInOptions = (id, list) => {
  return list.find((item) => item.id === id)
}

export const convertGroupsToOptions = (data) => {
  return data.map((item) => {
    return {
      value: item.code,
      label: item.name,
      id: item.code
    }
  })
}

export const addItemToArray = (value, items) => {
  const checkedItems = [...items]
  const index = checkedItems.indexOf(value)
  if (index === -1) {
    checkedItems.push(value)
  } else {
    checkedItems.splice(index, 1)
  }
  return checkedItems
}

export const loadNameByLanguage = (languageId, roleTranslations) => {
  const translation = roleTranslations.find(
    (item) => item.language === languageId
  )
  return translation?.translationName
}

export const getLanguageAndNameById = (languageId, roleTranslations) => {
  const roleTranslation = roleTranslations.find(
    (translation) => translation.language === languageId
  )
  if (roleTranslation) {
    const { id, isoCode, name } = roleTranslation.languages
    const defaultLanguage = { id, value: isoCode, label: name }
    return {
      defaultLanguage,
      translationName: roleTranslation.translationName
    }
  }
  return {}
}

export const getLastItem = (value, splitCharacter) => {
  const arr = value.split(splitCharacter)
  const lastIndex = arr.length - 1
  return arr[lastIndex].toLowerCase()
}

export const getPermissionForModule = (
  value,
  rolePermissions,
  isChildModule = false
) => {
  const lastItem = getLastItem(value, '/')
  const module = isChildModule
    ? value.toLowerCase()
    : MAPPING_URL_TO_PERMISSIONS[lastItem] ?? value.toLowerCase()

  const permission = {
    isViewList: false,
    isManage: false,
    isViewDetail: false,
    isViewSheet: false
  }

  const permissionValues = []
  rolePermissions.forEach((item) => {
    const rolePermissionArr = item.permission.split(/_(.*)/s)
    const permissionFormatted = rolePermissionArr[1].toLowerCase()
    if (permissionFormatted === module) {
      permissionValues.push(item.permission.toLowerCase())
    }
  })
  const permissionCheckValues = {
    isManage: `manage_${module}`,
    isViewList: `viewlist_${module}`,
    isViewDetail: `view_${module}`,
    isViewSheet: `sheet_${module}`
  }

  Object.keys(permission).forEach((key) => {
    const valueCheck = permissionCheckValues[key]
    if (permissionValues.includes(valueCheck)) {
      permission[key] = true
    }
  })

  return permission
}

/**
 * Get permission for tabs
 * @param tabs {array} : array of tabs eg: [{id: 'user', permission: 'userPortal'}]
 * @param rolePermissions
 * @returns {object} : object of permission for tabs eg: {userPortal: {isViewList: true, isManage: true, isViewDetail: true, isViewSheet: true}}
 */
export const getTabPermissions = (tabs, rolePermissions) => {
  if (!tabs || !rolePermissions) return {}

  return tabs.reduce((acc, curr) => {
    const { permission } = curr
    if (!permission) {
      return acc
    }
    return {
      ...acc,
      [permission]: getPermissionForModule(permission, rolePermissions, true)
    }
  }, {})
}

export const getSelectedLanguageInOptions = (id, list) => {
  return list.find((item) => item.id === id)
}

export const getLanguageOptions = (languages) => {
  return languages.map((language) => {
    return {
      value: language.isoCode,
      label: language.name,
      id: language.id
    }
  })
}

export const getSystemParameterByKey = (code, list) => {
  const systemParameter = list.find((item) => item.code === code)
  return systemParameter || null
}

export const getDefaultAvatar = (key) => {
  switch (key) {
    case 'default_avatar_company':
      return defaultCompany
    case 'default_image_company_selection':
      return defaultCompanySelection
    default:
      return defaultUser
  }
}

export const convertSystemConfigData = (data, list) => {
  const values = []
  Object.keys(data).forEach((key) => {
    const systemParameter = getSystemParameterByKey(key, list)
    const { id } = systemParameter
    values.push({ id, value: data[key] })
  })
  return { items: values }
}

export const getDefaultLanguageId = (activeLanguages) => {
  const defaultLanguage = activeLanguages.find((item) => item.isDefault)
  return defaultLanguage?.id
}

export const getTranslationMessageByKey = (key, list) => {
  const translation = list.find((item) => item.keyword === key)
  if (translation?.translations[0]?.translationMessage) {
    return translation.translations[0]?.translationMessage
  }
  return translation?.originalMessage
}

export const getDefaultGender = (genderId) => {
  return GENDER_LIST.find((v) => v.value === genderId)
}

export const convertOrderBy = (key, value) => {
  return `${key}:${value}`
}

export const convertListLanguageConstantToOptions = (data) => {
  const options = []
  data.forEach((item) => {
    const index = MAPPING_LANGUAGE[item[1]] || item[1]
    if (hasFlag(index.toUpperCase())) {
      options.push({
        key: index,
        value: item[3],
        label: item.name,
        nativeName: item.local
      })
    }
  })
  return options
}

export const getApprovalCompanyStatus = (status) => {
  return status ? COMPANY_STATUSES[status] : COMPANY_STATUSES.to_approve
}

export const getApprovalPilotStatus = (status) => {
  return status ? PILOT_STATUSES[status] : PILOT_STATUSES.requested
}

export const convertCountryToDefaultOptions = (object) => {
  const { id, isoCode, name } = object
  return { id, value: isoCode, label: name }
}

export const convertSubscriberTypesToDefaultOptions = (
  companySubscriberTypes
) => {
  const defaultOptions = []
  companySubscriberTypes.forEach((companySubscriberType) => {
    defaultOptions.push({
      value: companySubscriberType.subscriberType,
      label: SUBSCRIBER_TYPES[companySubscriberType.subscriberType]
    })
  })
  return defaultOptions
}

export const renderCompanyButtonByStatus = (status) => {
  switch (status) {
    case 'to_approve':
      return [
        {
          value: 'approved',
          label: COMPANY_DETAIL_BUTTONS.approve
        },
        {
          value: 'refused',
          label: COMPANY_DETAIL_BUTTONS.refuse
        }
      ]
    case 'approved':
      return [
        {
          value: 'refused',
          label: COMPANY_DETAIL_BUTTONS.refuse
        },
        {
          value: 'canceled',
          label: COMPANY_DETAIL_BUTTONS.cancel
        }
      ]
    case 'refused':
      return [
        {
          value: 'approved',
          label: COMPANY_DETAIL_BUTTONS.approve
        }
      ]
    default:
      return []
  }
}

export const convertCustomListToOptions = (key, list) => {
  if (!list) {
    return []
  }
  return list.map((item) => {
    return {
      value: item.id,
      label: item[key]
    }
  })
}

export const convertObjectToDefaultItem = (key, object) => {
  if (!object) {
    return {}
  }
  return {
    value: object.id,
    label: object[key]
  }
}

export const getUniqueItem = (list) => {
  const uniqueIds = []
  const uniqueList = list.filter((item) => {
    const isDuplicate = uniqueIds.includes(item.id)

    if (!isDuplicate) {
      uniqueIds.push(item.id)
      return true
    }
    return false
  })
  return uniqueList
}

/**
 *  Capitalize sentence
 */
export const capitalizeSentence = (sentence = '') => {
  return sentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  )
}

/**
 * Returns true if the passed object is a JavaScript array, otherwise false.
 *
 * @param {Object} value The object to test
 * @return {Boolean}
 */
export const isArray = (value) => {
  return toString.apply(value) === '[object Array]'
}

/**
 * Returns true if the passed object is a JavaScript Object, otherwise false.
 *
 * @param {Object} value The object to test
 * @return {Boolean}
 */
export const isObject = (value) => {
  return value && typeof value === 'object'
}

/**
 * Return true if passed object is empty.
 * @param {Mixed} value The value to test
 * @returns {boolean}
 */
export const isEmptyObject = (value) => {
  if (!isObject(value)) {
    return false
  }
  if (value instanceof Date) {
    return false
  }
  if (Object.keys(value).length) {
    return false
  }
  return true
}

/**
 * Returns true if the passed value is empty. The value is deemed to be
 * empty if it is * null * undefined * an empty array * a zero length * an empty object
 * string (Unless the allowBlank parameter is true)
 *
 * @param {Mixed} value The value to test
 * @param {Boolean} allowBlank (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 */
export const isEmpty = (value, allowBlank = false) => {
  return (
    value === null ||
    value === undefined ||
    (isArray(value) && !value.length) ||
    (!allowBlank ? value === '' : false) ||
    isEmptyObject(value)
  )
}

export const convertListCixiReferentToOptions = (data) => {
  return data.map((item) => {
    return {
      value: item.id,
      label: `${item.firstName} ${item.lastName}`
    }
  })
}

export const convertAppUserFilter = (filterData) => {
  const { selectedCountry, ...newFilterData } = filterData
  return {
    ...newFilterData,
    countryId: selectedCountry?.id
  }
}

export const convertCompanyFilter = (filterData) => {
  const {
    selectedCompanyType,
    selectedCountry,
    selectedStatus,
    selectedSubscriberType,
    selectedOrderBy,
    ...newFilterData
  } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    companyType: selectedCompanyType?.value?.toLowerCase(),
    country: selectedCountry?.id,
    status: selectedStatus?.value,
    subscriberType: selectedSubscriberType?.value
  }
  if (selectedOrderBy?.value) {
    filterDataFormatted.sortBy = `${selectedOrderBy.value}:asc`
  }
  return filterDataFormatted
}

export const convertUserFilter = (filterData) => {
  const { selectedStatus, ...newFilterData } = filterData
  return {
    ...newFilterData,
    status: selectedStatus?.value
  }
}

export const convertVehicleFilter = (filterData) => {
  const { selectedSortBy, selectedVehicleType, ...newFilterData } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    vehicleType: selectedVehicleType?.value
  }
  if (selectedSortBy?.value) {
    filterDataFormatted.sortBy = `${selectedSortBy.value}:asc`
  }
  return filterDataFormatted
}

export const convertElectronicKeyFilter = (filterData) => {
  const { selectElectronicKeyModel, ...newFilterData } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    electronicKeyModel: selectElectronicKeyModel?.value
  }
  return filterDataFormatted
}

export const convertTextFilter = (filterData) => {
  const {
    selectedLanguage,
    selectedModule,
    selectedSearchScope,
    ...newFilterData
  } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    languageTranslationId: selectedLanguage?.id,
    moduleId: selectedModule?.id,
    searchScope: selectedSearchScope?.value
  }
  return filterDataFormatted
}

export const convertRoleFilter = (filterData) => {
  const { selectedGroupCode, ...newFilterData } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    groupCode: selectedGroupCode?.value
  }
  return filterDataFormatted
}

export const showSpecificDataCompanyFilter = (key, value) => {
  switch (key) {
    case 'selectedCompanyType':
      return COMPANY_TYPES[value]
    case 'selectedSubscriberType':
      return SUBSCRIBER_TYPES[value]
    default:
      return COMPANY_STATUSES[value]
  }
}

export const getAddress = (data = {}) => {
  let result = data.zipCode ?? data.zipCodeAddress ?? ''
  const city = data.city ?? data.cityAddress ?? ''
  const country = data.country ?? data.countryAddress ?? ''
  if (city) {
    result = result ? `${result} - ${city}` : city
  }
  if (country) {
    result = result ? `${result} (${country.name})` : country.name
  }
  return result
}

export const getTranslationBy = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation.language?.id === language.id
    ) ?? {}
  )
}

export const getTranslationForComponent = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation.languageId === language.id
    ) ?? {}
  )
}

export const getTranslationForText = (item = {}, language = {}) => {
  if (language.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { translationText: item.originalText }
  }
  return getTranslationBy(item, language)
}

export const getTranslationForMessage = (item = {}, language = {}) => {
  if (language?.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { translationMessage: item.originalMessage }
  }
  return getTranslationBy(item, language)
}

export const getTranslationForLabel = (item = {}, language = {}) => {
  if (language.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { originalName: item.originalName, helpText: item.helpText }
  }
  return getTranslationBy(item, language)
}

export const rearrangeTranslates = (translations = []) => {
  translations.sort((firstEl) => {
    if (firstEl.language?.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
      return -1
    }
    return 0
  })
  return translations
}

export const normalSanitizedData = (data) => ({
  __html: data
})

export const sanitizedData = (data) => ({
  __html: DOMPurify.sanitize(data)
})

export const convertMailTemplateFilter = (filterData) => {
  const filterDataFormatted = { ...filterData }
  if (!filterDataFormatted?.moduleId) {
    delete filterDataFormatted?.moduleId
  }
  return filterDataFormatted
}

export const getExtension = (filename) => {
  if (!filename) {
    return ''
  }
  const extensionValue = filename.split('.').pop()
  if (!extensionValue) {
    return ''
  }
  const extensionArr = extensionValue.split('?')
  return extensionArr[0]
}

export const getType = (type) => {
  if (!type) {
    return ''
  }
  const arrType = type.split('/')
  return arrType[1]
}

export const formatSectionObject = (values) => {
  const newValues = { ...values }
  if (!values.createdOn) {
    delete newValues.createdOn
  }
  if (!values.timeToRead) {
    delete newValues.timeToRead
  }
  if (!values.timeToRead) {
    delete newValues.timeToRead
  }
  return newValues
}

export const convertSectionObject = (values, media) => {
  const { mainPicture } = values
  const type = getType(mainPicture?.type)
  let newValues = {
    ...values,
    urlStaticPicture: '',
    urlAnimatedPicture: '',
    urlVideo: ''
  }
  if (!type) {
    return values
  }

  if (MAPPING_MAIN_PICTURE_FILE_TYPES.STATIC_PICTURE.includes(type)) {
    newValues = { ...newValues, urlStaticPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.ANIMATED_PICTURE.includes(type)) {
    newValues = { ...newValues, urlAnimatedPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.VIDEO.includes(type)) {
    newValues = { ...newValues, urlVideo: media }
  }
  return formatSectionObject(newValues)
}

export const convertSectionObjectMobile = (values, media) => {
  const { mainPictureMobile } = values
  const type = getType(mainPictureMobile?.type)
  let newValues = {
    ...values,
    urlMobileStaticPicture: '',
    urlMobileAnimatedPicture: '',
    urlMobileVideo: ''
  }
  if (!type) {
    return values
  }

  if (MAPPING_MAIN_PICTURE_FILE_TYPES.STATIC_PICTURE.includes(type)) {
    newValues = { ...newValues, urlMobileStaticPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.ANIMATED_PICTURE.includes(type)) {
    newValues = { ...newValues, urlMobileAnimatedPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.VIDEO.includes(type)) {
    newValues = { ...newValues, urlMobileVideo: media }
  }
  return formatSectionObject(newValues)
}

export const getDateFormat = (dateFormats, type) => {
  const data = dateFormats.find((dateFormat) => dateFormat.code === type)
  return { id: data?.id ?? '', value: data?.translationValue || data?.value }
}

export const getTranslationForDatetimeFormat = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation?.language?.id === language.id
    ) ?? {}
  )
}

export const getPages = (sectionPages) => {
  if (!sectionPages) {
    return ''
  }
  return sectionPages
    .map((sectionPage) => SECTION_PAGE_VALUES[sectionPage?.page])
    .join(', ')
}

export const getSelectedPages = (sectionPages) => {
  const values = sectionPages.map((sectionPage) => sectionPage.page)
  if (values.length > 0) {
    return SECTION_PAGE_LIST.filter((item) => values.includes(item.value))
  }
  return []
}

export const getTranslationItem = (translation, selectedLanguage, item) => {
  const {
    thumbnailTitle,
    baliseTitle,
    baliseTitleThumbnail,
    thumbnailDescription,
    baliseMetaDescriptionThumbnail,
    baliseMetaDescription,
    headerTitle,
    headerDescription,
    content1,
    content2,
    content3,
    content4,
    content5,
    url
  } = translation
  const formInitValue = {
    thumbnailTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.thumbnailTitle
        : thumbnailTitle,

    baliseTitleThumbnailTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseTitleThumbnail
        : baliseTitleThumbnail,

    baliseTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseTitle
        : baliseTitle,

    thumbnailDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.thumbnailDescription
        : thumbnailDescription,

    baliseMetaDescriptionThumbnailTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseMetaDescriptionThumbnail
        : baliseMetaDescriptionThumbnail,

    baliseMetaDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseMetaDescription
        : baliseMetaDescription,

    headerTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.headerTitle
        : headerTitle,

    headerDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.headerDescription
        : headerDescription,

    urlTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE ? item?.url : url,

    contentTranslate1:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content1
        : content1,

    contentTranslate2:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content2
        : content2,

    contentTranslate3:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content3
        : content3,

    contentTranslate4:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content4
        : content4,

    contentTranslate5:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content5
        : content5
  }

  Object.keys(formInitValue).forEach((key) => {
    formInitValue[key] = formInitValue[key] || ''
  })

  return formInitValue
}

export const checkIsVideo = (type) => {
  if (!type) {
    return false
  }
  const arrType = type.split('/')
  if (arrType[0] === 'video') {
    return true
  }
  return false
}

// export const checkVideoByUrl = (url) => {
//   const ext = getExtension(url);
//   console.log('🚀 ~ file: Utils.js:1069 ~ checkVideoByUrl ~ ext', ext);
// };

export const getFileInResponse = (response, fileName) => {
  const file = response.find((item) => item.originalFileName === fileName)
  return file?.fileKey
}

export const getDataFromS3 = (response, values) => {
  let newValues = { ...values }
  const tempMainPicture = getFileInResponse(response, values?.mainPicture?.name)
  const tempMainPictureMobile = getFileInResponse(
    response,
    values?.mainPictureMobile?.name
  )
  const tempAuthorAvatar = getFileInResponse(
    response,
    values?.authorAvatar?.name
  )
  if (tempMainPicture) {
    newValues = convertSectionObject(newValues, tempMainPicture)
  }
  if (tempMainPictureMobile) {
    newValues = convertSectionObjectMobile(newValues, tempMainPictureMobile)
  }
  if (tempAuthorAvatar) {
    newValues = { ...newValues, authorAvatar: tempAuthorAvatar }
  }
  return newValues
}

export const getImageKeyFromS3 = (response) => {
  return response?.s3Uploads ? response?.s3Uploads[0]?.fileKey : ''
}

export const commonUploadMedia = (
  uploadMedia,
  values,
  callback,
  data,
  fieldKey = 'avatar',
  id = null,
  isMediaKit = false
) => {
  const formData = new FormData()
  formData.append('files', data)
  formData.append('isMediaKit', isMediaKit)
  uploadMedia(formData, {
    onSuccess: (res) => {
      const media = getImageKeyFromS3(res?.response)
      const newValues = { ...values }
      newValues[fieldKey] = media
      if (id) {
        callback(id, newValues)
      } else {
        callback(newValues)
      }
    },
    onError: (error) => {
      toast.error(error)
    }
  })
}
export const currencyFormat = (num) => {
  return num?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Filter out values from an object
 * @param obj
 * @param removeValues - array of values to filter out
 * @param removeKeys - array of keys to filter out
 *
 * Example:
 * filterOutNullValues({ a: 1, b: null, c: undefined, d: 0, e: '' }, [null, undefined, ''])
 * { a: 1, d: 0, e: '' }
 *
 * filterOutNullValues({ a: 1, b: 2, d: 0, e: '' }, [], ['b'])
 * { a: 1, d: 0, e: '' }
 *
 */
export const filterOutValues = (obj, removeValues = [], removeKeys = []) => {
  if (!obj) return obj

  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (removeKeys?.includes(key)) return

    if (!removeValues?.includes(obj[key])) newObj[key] = obj[key]
  })

  return newObj
}

/**
 * Replace a value in an object at a given path, and return a new object
 * Note: if the parent path does not exist, the parent will be created as an empty object
 * @param obj - object to replace value in
 * @param path - path to value, e.g. 'user.name'
 * @param value - value to replace with
 */
export const replaceValueAtPath = (obj, path, value) => {
  const newObj = { ...obj }
  const pathArray = path.split('.')
  let currentObj = newObj
  try {
    pathArray.forEach((key, index) => {
      if (index === pathArray.length - 1) {
        currentObj[key] = value
        return
      }

      if (!currentObj[key]) currentObj[key] = {}

      currentObj = currentObj[key]
    })
    return newObj
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      'Could not update store, please try to clear `persist:store` in local storage'
    )
    // eslint-disable-next-line no-console
    console.warn(e)
    return obj
  }
}

/**
 * Get a value from an object at a given path
 * @param obj - object to get value from
 * @param path - path to value, e.g. 'user.name'
 * @param defaultValue - default value to return if path is not found
 * @returns {*}
 */
export const getValueAtPath = (obj, path, defaultValue) => {
  if (!obj) return defaultValue
  if (!path) return obj

  try {
    const pathArray = path.split('.')
    let currentObj = obj
    pathArray.forEach((key) => {
      currentObj = currentObj[key]
    })
    return currentObj || defaultValue
  } catch (e) {
    return defaultValue
  }
}

/**
 * Get label of subscription status by status value
 * @param status - status value e.g. 'requested_by_cixi'
 * @returns {string}
 */
export const getSubscriptionStatus = (status) =>
  SUBSCRIPTION_STATUSES.find((s) => s.value === status)?.label || ''

/**
 * Get label of vehicle type by type value
 * @param type - type value e.g. '1', '2'
 * @returns {*|string}
 */
export const getVehicleType = (type) =>
  VEHICLE_TYPE_LIST.find((s) => s.value === type)?.label || ''

/**
 * Replace a key in an object with a new key, and return a new object
 * @param obj - object to replace key in
 * @param keyPairs - array with old key and new key, e.g. [{ oldKey: 'name', newKey: 'fullName' }]
 * @returns {*}
 */
export const changeKey = (obj, keyPairs) => {
  if (!obj || !keyPairs) return obj

  const newObj = { ...obj }
  keyPairs.forEach((keyPair) => {
    const { oldKey, newKey } = keyPair
    newObj[newKey] = newObj[oldKey]
    delete newObj[oldKey]
  })
  return newObj
}

/**
 * Convert object to option for select
 * @param obj - object to convert e.g. { 1: 'Option 1', 2: 'Option 2' }
 * @returns {{label: *, value: *}[]|*[]}
 */
export const convertObjectToOptions = (obj) => {
  if (!obj) return []

  return Object.keys(obj).map((key) => ({ label: obj[key], value: key }))
}

export const downloadFile = (data, fileName) => {
  const blob = new Blob([data])
  const downloadURI = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', downloadURI)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
