import SystemConfigTypes from '../action_types/systemConfig'

import {
  systemConfig as systemConfigApi,
  vehicleBrand as vehicleBrandApi,
  vehicleModel as vehicleModelApi
} from '../api'

import { bindRequestLogic } from './utils'

const getSystemConfigList = bindRequestLogic({
  clientApi: systemConfigApi,
  clientFunc: systemConfigApi.getSystemConfigs,
  type: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_REQUEST,
  successType: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS,
  failType: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_FAILED,
  isQueryParam: true,
  modelName: 'systemConfig'
})

const updateSystemConfigs = bindRequestLogic({
  clientApi: systemConfigApi,
  clientFunc: systemConfigApi.updateSystemConfigs,
  type: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_REQUEST,
  successType: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_SUCCESS,
  failType: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_FAILED
})

const getModules = bindRequestLogic({
  clientApi: systemConfigApi,
  clientFunc: systemConfigApi.getModules,
  type: SystemConfigTypes.GET_MODULES_REQUEST,
  successType: SystemConfigTypes.GET_MODULES_SUCCESS,
  failType: SystemConfigTypes.GET_MODULES_FAILED,
  modelName: 'modules'
})

const getAllVehicleBrands = bindRequestLogic({
  clientApi: vehicleBrandApi,
  clientFunc: vehicleBrandApi.getAllVehicleBrands,
  type: SystemConfigTypes.GET_ALL_VEHICLE_BRANDS_REQUEST,
  successType: SystemConfigTypes.GET_ALL_VEHICLE_BRANDS_SUCCESS,
  failType: SystemConfigTypes.GET_ALL_VEHICLE_BRANDS_FAILED
})

const getVehicleModelsByVehicleBrand = bindRequestLogic({
  clientApi: vehicleModelApi,
  clientFunc: vehicleModelApi.getVehicleModelsByVehicleBrand,
  type: SystemConfigTypes.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_REQUEST,
  successType: SystemConfigTypes.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_SUCCESS,
  failType: SystemConfigTypes.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_FAILED
})

export default [
  getSystemConfigList,
  updateSystemConfigs,
  getModules,
  getAllVehicleBrands,
  getVehicleModelsByVehicleBrand
]
