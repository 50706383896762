import {
  DEFAULT_PAGINATION,
  DEFAULT_SUBSCRIPTION_FILTER
} from 'constants/common'
import SubscriptionTypes from 'store/action_types/subscription'

const initSubscription = {
  list: [],
  activeList: [],
  current: {},
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_SUBSCRIPTION_FILTER,
  vehicle: {},
  allowedPilots: []
}

const subscription = (state = initSubscription, action) => {
  switch (action.type) {
    case SubscriptionTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        list: action.payload?.subscriptions?.items,
        pagination: action.payload?.subscriptions?.meta
      }

    case SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS:
      return { ...state, current: action.payload?.subscription }
    case SubscriptionTypes.UPDATE_FILTER_SUBSCRIPTION_SUCCESS:
      return { ...state, filter: action.payload }
    case SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: action.payload?.subscriptionVehicle || {}
      }
    case SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_FAILED:
      return {
        ...state,
        vehicle: {}
      }
    case SubscriptionTypes.CLEAR_FILTER_SUBSCRIPTION_SUCCESS:
      return { ...state, filter: initSubscription.filter }

    case SubscriptionTypes.GET_ALLOWED_PILOTS_SUCCESS:
      return {
        ...state,
        allowedPilots: action.payload
      }
    default:
      return state
  }
}

export default subscription
