import CixiReferentTypes from '../action_types/cixiReferent'

import { cixiReferent as cixiReferentApi } from '../api'
import { bindRequestLogic } from './utils'

const getCixiReferents = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.getCixiReferents,
  type: CixiReferentTypes.GET_CIXI_REFERENTS_REQUEST,
  successType: CixiReferentTypes.GET_CIXI_REFERENTS_SUCCESS,
  failType: CixiReferentTypes.GET_CIXI_REFERENTS_FAILED,
  isQueryParam: true
})

const addCixiReferent = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.addCixiReferent,
  type: CixiReferentTypes.ADD_CIXI_REFERENT_REQUEST,
  successType: CixiReferentTypes.ADD_CIXI_REFERENT_SUCCESS,
  failType: CixiReferentTypes.ADD_CIXI_REFERENT_FAILED
})

const getCixiReferentDetail = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.getCixiReferentDetail,
  type: CixiReferentTypes.GET_CIXI_REFERENT_DETAIL_REQUEST,
  successType: CixiReferentTypes.GET_CIXI_REFERENT_DETAIL_SUCCESS,
  failType: CixiReferentTypes.GET_CIXI_REFERENT_DETAIL_FAILED,
  modelName: 'companyCIXIReferent'
})

const deleteCixiReferent = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.deleteCixiReferent,
  type: CixiReferentTypes.DELETE_CIXI_REFERENT_REQUEST,
  successType: CixiReferentTypes.DELETE_CIXI_REFERENT_SUCCESS,
  failType: CixiReferentTypes.DELETE_CIXI_REFERENT_FAILED
})

const updateCixiReferent = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.updateCixiReferent,
  type: CixiReferentTypes.UPDATE_CIXI_REFERENT_REQUEST,
  successType: CixiReferentTypes.UPDATE_CIXI_REFERENT_SUCCESS,
  failType: CixiReferentTypes.UPDATE_CIXI_REFERENT_FAILED
})

const getAllCixiReferents = bindRequestLogic({
  clientApi: cixiReferentApi,
  clientFunc: cixiReferentApi.getAllCixiReferents,
  type: CixiReferentTypes.GET_ALL_CIXI_REFERENTS_REQUEST,
  successType: CixiReferentTypes.GET_ALL_CIXI_REFERENTS_SUCCESS,
  failType: CixiReferentTypes.GET_ALL_CIXI_REFERENTS_FAILED
})

export default [
  getCixiReferents,
  addCixiReferent,
  getCixiReferentDetail,
  deleteCixiReferent,
  updateCixiReferent,
  getAllCixiReferents
]
