import { DEFAULT_LANGUAGE_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import LanguageTypes from 'store/action_types/language'
import UserTypes from 'store/action_types/user'

const initLanguage = {
  list: [],
  current: {},
  activeList: null,
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_LANGUAGE_FILTER
}

const language = (state = initLanguage, action) => {
  switch (action.type) {
    case LanguageTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case LanguageTypes.GET_LANGUAGE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case LanguageTypes.GET_ACTIVE_LANGUAGES_SUCCESS:
      return { ...state, activeList: action.payload?.items }
    case LanguageTypes.UPDATE_FILTER_LANGUAGE_SUCCESS:
      return { ...state, filter: action.payload }
    case LanguageTypes.CLEAR_FILTER_LANGUAGE_SUCCESS:
      return { ...state, filter: initLanguage.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initLanguage
    default:
      return state
  }
}

export default language
