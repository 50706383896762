import { DEFAULT_LOG_MAIL_FILTER, DEFAULT_PAGINATION } from 'constants/common'
import LogMailTypes from 'store/action_types/logMail'
import UserTypes from 'store/action_types/user'

const initLogMail = {
  list: [],
  pagination: DEFAULT_PAGINATION,
  filter: DEFAULT_LOG_MAIL_FILTER,
  modules: []
}

const logMail = (state = initLogMail, action) => {
  switch (action.type) {
    case LogMailTypes.GET_LOG_MAILS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case LogMailTypes.GET_LOG_MAILS_FAILED:
      return { ...state, current: action.payload }
    case LogMailTypes.UPDATE_FILTER_LOG_MAIL_SUCCESS:
      return { ...state, filter: action.payload }
    case LogMailTypes.CLEAR_FILTER_LOG_MAIL_SUCCESS:
      return { ...state, filter: initLogMail.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initLogMail
    default:
      return state
  }
}
export default logMail
