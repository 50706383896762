// eslint-disable-next-line import/no-cycle
import { getTranslationMessageByKey } from 'helpers/Utils'

const SystemMessages = (props) => {
  const { id, systemMessages, param } = props
  const translationValue = getTranslationMessageByKey(id, systemMessages)
  if (translationValue) {
    if (param?.key && param?.value) {
      return translationValue.replace(param.key, param.value)
    }
    return translationValue
  }
  return id
}

export default SystemMessages
