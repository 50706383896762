import { STORAGE_KEYS } from 'constants/common'
import BaseApi from './base'

class VehicleTest extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.REACT_APP_VEHICLE_API_BO_URL
    this.setCurrentToken()
  }

  setCurrentToken = () => {
    const vehicleToken = localStorage.getItem(STORAGE_KEYS.vehicleToken)
    if (vehicleToken) {
      this.token = vehicleToken
      this.client.defaults.headers.common.Authorization = `Bearer ${vehicleToken}`
    }
  }

  getAuthVehicle = (data) => this.client.post(`/vehicle/get-auth-vehicle`, data)

  encryptData = (data) => this.client.post(`/vehicle/private-encrypt`, data)

  decryptData = (data) => this.client.post(`/vehicle/private-decrypt`, data)

  getWhatToDo = (data) => this.client.post(`/vehicle/get-what-to-do`, data)

  getNewPinBleVehicle = (data) =>
    this.client.post(`/vehicle/get-new-pin-ble-vehicle`, data)

  getNewHardToken = (data) =>
    this.client.post(`/vehicle/get-new-hard-token-vehicle`, data)

  getVehicleListUsers = (data) =>
    this.client.post(`/vehicle/get-vehicle-list-users`, data)

  getNewAuthVehicle = (data) =>
    this.client.post(`/vehicle/get-new-auth-vehicle`, data)
}

export default new VehicleTest()
